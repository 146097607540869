import React, { useState } from 'react';

const Sidebar = ({ selectedSidebarTab, setSelectedSidebarTab }) => {
    const [sidebarTabs, setSidebarTabs] = useState([
        { title: 'Dashboard', blackIcon: './dashboard-tab-icon-black.svg', greyIcon: './dashboard-tab-icon-grey.svg' },
        { title: 'Subscribers', blackIcon: './subscribers-tab-icon-black.svg', greyIcon: './subscribers-tab-icon-grey.svg' },
        { title: 'EZ Earnings', blackIcon: './earnings-tab-icon-black.svg', greyIcon: './earnings-tab-icon-grey.svg' },
        { title: 'Product Catalogue', blackIcon: './products-tab-icon-black.svg', greyIcon: './products-tab-icon-grey.svg' },
        { title: 'Support', blackIcon: './support-tab-icon-black.svg', greyIcon: './support-tab-icon-grey.svg' },
    ]);
    const [totalSubscribers, setTotalSubscribers] = useState(3);

    return (
        <div className='fixed left-0 top-0 h-full w-[23%] p-[12px] bg-[#FFFFFF] z-40 flex flex-col items-start gap-[23px]'>
            <img src="./tittle-logo.svg" alt="EZ-Mobile Logo" className='w-full max-w-[170px] mt-[5px] relative left-[12px]' />

            <div className='flex flex-col gap-[12px] w-full mt-[10px]'>
                {sidebarTabs.map((item, index) => (
                    <div className={`h-[48px] w-full p-[12px] flex justify-start items-center gap-[8px] rounded-[12px] cursor-pointer hover:bg-[#E7F6FC] ${selectedSidebarTab === index ? 'bg-[#E7F6FC] text-[#1D2224] font-bold' : 'bg-[#FFFFFF] text-[#585B5F] font-normal'}`} key={index} onClick={() => setSelectedSidebarTab(index)}>
                        <img src={selectedSidebarTab === index ? item.blackIcon : item.greyIcon} alt={`${item.title} Icon`} className='h-[24px] w-[24px]' />
                        <p className='text-[16px]'>{item.title}</p>
                        {(item.title === "Subscribers" && totalSubscribers > 0) && <span className='h-[24px] w-[25px] bg-[#F83A5D] rounded-full flex justify-center items-center text-[#FEEBEF] text-[14px] font-medium'>{totalSubscribers}</span>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Sidebar;