export function generateDummySubscribers(count) {
  const names = ['Jean Renel', 'John Doe', 'Jane Smith', 'Alice Johnson', 'Bob Brown'];
  const emails = [
    'example1@gmail.com',
    'example2@gmail.com',
    'example3@gmail.com',
    'example4@gmail.com',
    'example5@gmail.com',
  ];

  function getRandomElement(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  function generateRandomNumber() {
    return '561' + Math.floor(1000000 + Math.random() * 9000000).toString();
  }

  function generateRandomICCID() {
    return '89014' + Math.random().toString().slice(2, 12);
  }

  function generateRandomIMEI() {
    return '351' + Math.floor(1000000000 + Math.random() * 9000000000).toString();
  }

  const subscribers = [];

  for (let i = 0; i < count; i++) {
    subscribers.push({
      name: getRandomElement(names),
      number: generateRandomNumber(),
      iccid: generateRandomICCID(),
      imei: generateRandomIMEI(),
      email: getRandomElement(emails),
    });
  }

  return subscribers.sort((a, b) => a.name.localeCompare(b.name));
}

export function generateUniqueSubscriber(existingSubscribers) {
  const names = ['Jean Renel', 'John Doe', 'Jane Smith', 'Alice Johnson', 'Bob Brown'];
  const emails = [
    'example1@gmail.com',
    'example2@gmail.com',
    'example3@gmail.com',
    'example4@gmail.com',
    'example5@gmail.com',
  ];

  function getRandomElement(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  function generateRandomNumber() {
    let number;
    do {
      number = '561' + Math.floor(1000000 + Math.random() * 9000000).toString();
    } while (existingSubscribers.some((sub) => sub.number === number));
    return number;
  }

  function generateRandomICCID() {
    let iccid;
    do {
      iccid = '89014' + Math.random().toString().slice(2, 12);
    } while (existingSubscribers.some((sub) => sub.iccid === iccid));
    return iccid;
  }

  function generateRandomIMEI() {
    let imei;
    do {
      imei = '351' + Math.floor(1000000000 + Math.random() * 9000000000).toString();
    } while (existingSubscribers.some((sub) => sub.imei === imei));
    return imei;
  }

  return {
    name: getRandomElement(names),
    number: generateRandomNumber(),
    iccid: generateRandomICCID(),
    imei: generateRandomIMEI(),
    email: getRandomElement(emails),
  };
}
