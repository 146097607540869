import React, { useState } from 'react';
import WithdrawalMethod from './withdrawalMethod';
import WithdrawalAmount from './withdrawalAmount';
import { useNavigate } from 'react-router-dom';
import WithdrawalBankDetails from './withdrawalBankDetails';
import { withdrawalMoneyFormData } from '../constants';
import WithdrawalSuccessModal from './withdrawalSuccessModal';
import { useSelector } from 'react-redux';

const WithdrawMoneyComponent = ({ setSelectedAdminSubmenu }) => {
  const [tabDetails, setTabDetails] = useState(['', '', '']);
  const [selectedTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState(withdrawalMoneyFormData);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [withdrawalSuccessModalOpen, setWithdrawalSuccessModalOpen] = useState(false);

  const handleCloseWithdrawalModal = () => {
    setWithdrawalSuccessModalOpen(false);
    setSelectedAdminSubmenu(
      user?.roleId?.name === 'Master Distributor' ? 'AD Management' : 'Subscriber Management'
    );
    navigate('/dashboard');
  };

  const handleCancel = () => {
    navigate('/dashboard');
    setSelectedAdminSubmenu(
      user?.roleId?.name === 'Master Distributor' ? 'AD Management' : 'Subscriber Management'
    );
  };

  return (
    <div className="flex flex-col items-center gap-[30px] bg-[#F1F2F2] min-h-screen py-[30px] relative font-jakarta">
      <div className="fixed top-0 left-0 right-0 flex justify-center items-center pt-[30px] bg-[#F1F2F2] z-50">
        <img
          src="./tittle-logo.svg"
          alt="Logo"
          className="absolute left-[50px] top-[45px] w-[170px]"
        />

        <div className="flex flex-col gap-[10px] min-w-[400px] w-[35%] py-[20px] box-border">
          <h3 className="text-[25px] text-[#2F3436] font-extrabold">Withdraw Money</h3>

          <div className="min-w-[400px] w-full flex items-center gap-[8px]">
            {tabDetails.map((item, index) => (
              <div
                className={`h-[4px] min-w-[90px] w-[33%] ${selectedTab === index ? 'bg-[#1D2224]' : 'bg-[#DDDEDE]'}`}
                key={index}
              ></div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full mt-[130px] flex justify-center items-center">
        {selectedTab === 0 && (
          <WithdrawalMethod
            formData={formData}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
            handleCancel={handleCancel}
          />
        )}
        {selectedTab === 1 && (
          <WithdrawalBankDetails
            formData={formData}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === 2 && (
          <WithdrawalAmount
            formData={formData}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
            setWithdrawalSuccessModalOpen={setWithdrawalSuccessModalOpen}
          />
        )}

        {withdrawalSuccessModalOpen && (
          <WithdrawalSuccessModal
            formData={formData}
            handleCloseWithdrawalModal={handleCloseWithdrawalModal}
          />
        )}
      </div>
    </div>
  );
};

export default WithdrawMoneyComponent;
