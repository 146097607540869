import React from 'react';

function OnboardingFooter() {
  return (
    <footer className="w-full mt-auto font-jakarta flex justify-center items-center p-2 bg-[#DFE0E2]">
      <p className="text-[#80848A] text-sm">© EZMOBILE Limited 2024</p>
    </footer>
  );
}

export default OnboardingFooter;
