import React, { useEffect, useRef, useState } from 'react';

const Pagination = ({ paginationQuery, setPaginationQuery }) => {
    const paginationRef = useRef(null);
    const paginationChildRef = useRef(null);
    const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);

    useEffect(() => {
        let handler = (e) => {
            if (paginationRef.current && paginationChildRef.current && !paginationRef.current.contains(e.target) && !paginationChildRef.current.contains(e.target)) {
                setPaginationDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, []);

    const handlePagination = (selectedItem) => {
        setPaginationQuery({ ...paginationQuery, rowsPerPage: selectedItem });
        setPaginationDropdownOpen(false);
    }

    return (
        <div className='h-[50px] w-full py-[5px] px-[16px] flex justify-end items-center gap-[26px]'>
            <div className='flex items-center gap-[8px] relative'>
                <p className='text-[14px] text-[#6C6F75] font-normal'>Rows per page:</p>
                <div className='flex items-center gap-[5px] cursor-pointer hover:opacity-90' onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)} ref={paginationRef}>
                    <p className='text-[14px] text-[#6C6F75] font-normal'>{paginationQuery.rowsPerPage}</p>
                    <img src="./pagination-arrow-down-icon.svg" alt="Arrow-Down Icon" className={`h-[5px] w-[9px] transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`} />
                </div>

                {paginationDropdownOpen && <div className='absolute top-[-130px] left-[90px] h-[120px] w-[60px] bg-[#E7F6FC] border-1 rounded-[5px] overflow-hidden z-20 flex flex-col items-center shadow-2xl' ref={paginationChildRef}>
                    <p className={`w-full py-2 text-center cursor-pointer hover:bg-[#FFFFFF] ${paginationQuery.rowsPerPage === 10 ? 'bg-[#FFFFFF]' : ''}`} onClick={() => handlePagination(10)}>10</p>
                    <p className={`w-full py-2 text-center cursor-pointer hover:bg-[#FFFFFF] ${paginationQuery.rowsPerPage === 25 ? 'bg-[#FFFFFF]' : ''}`} onClick={() => handlePagination(25)}>25</p>
                    <p className={`w-full py-2 text-center cursor-pointer hover:bg-[#FFFFFF] ${paginationQuery.rowsPerPage === 50 ? 'bg-[#FFFFFF]' : ''}`} onClick={() => handlePagination(50)}>50</p>
                </div>}
            </div>

            <p className='text-[14px] text-[#6C6F75] font-normal'>
                {paginationQuery.totalRows === 0 ? 0 : (paginationQuery.currentPage - 1) * paginationQuery.rowsPerPage + 1}-{paginationQuery.currentPage * paginationQuery.rowsPerPage > paginationQuery.totalRows ? paginationQuery.totalRows : paginationQuery.currentPage * paginationQuery.rowsPerPage} of {paginationQuery.totalRows}
            </p>

            <div className='flex items-center gap-[24px]'>
                <img src="./chevron-left-icon.svg" alt="Chevron-Left Icon" className='h-[24px] w-[24px] cursor-pointer hover:opacity-80' />
                <img src="./chevron-right-icon.svg" alt="Chevron-Left Icon" className='h-[24px] w-[24px] cursor-pointer hover:opacity-80' />
            </div>
        </div>
    )
}

export default Pagination;