import HTTPService from '../utility/httpService';
import { apiEndPointConstants } from '../constants';
import Cookies from '../managers/sessionManager';

const {
  ORDERS,
  CREATE_CHECKOUT_SESSION,
  ADMINS,
  WALLET,
  WEEKLY_SUBSCRIBERS_SUMMARY,
  WEEKLY_ORDERS_SUMMARY,
  REGISTER_SUBSCRIBER,
  AVAILABLE_PLANS,
  PLANS,
  START_SUBSCRIBER_ACTIVATION,
  SUBSCRIBER_PLAN_ACTIVATION,
  PORT_IN_MAKE_START,
  PORT_IN_MAKE,
  CRM_PRODUCTS,
  PORT_IN_CHECK,
  TRANSACTIONS,
  DISTRIBUTORS,
  SUBSCRIBERS,
  ACCOUNTS,
  SUMMARY,
} = apiEndPointConstants;

export default class WalletService {
  constructor(token) {
    this.httpService = new HTTPService(process.env.REACT_APP_WALLET_MICROSERVICE);
    this.config = {
      headers: {
        Authorization: `Bearer ${token !== undefined ? token : new Cookies().get('ACCESS_TOKEN')}`,
      },
    };
  }

  getWeeklySubscribersSummary() {
    return this.httpService.get(`${ADMINS + WEEKLY_SUBSCRIBERS_SUMMARY}`, {}, this.config);
  }

  getWeeklyOrdersSummary() {
    return this.httpService.get(`${ADMINS + SUBSCRIBERS + WEEKLY_ORDERS_SUMMARY}`, {}, this.config);
  }

  getOrders() {
    return this.httpService.get(ORDERS, {}, this.config);
  }
  createCheckoutSession(payload) {
    return this.httpService.post(CREATE_CHECKOUT_SESSION, payload, this.config);
  }
  getUserWallet(userID) {
    return this.httpService.get(`${WALLET}/${userID}`, {}, this.config);
  }
  registerSubscriber(requestData) {
    return this.httpService.post(`${SUBSCRIBERS + REGISTER_SUBSCRIBER}`, requestData, this.config);
  }
  getAvailablePlans(requestData) {
    return this.httpService.post(AVAILABLE_PLANS, requestData, this.config);
  }

  getPlans() {
    return this.httpService.get(PLANS, {}, this.config);
  }

  startPlanActivation(requestData) {
    return this.httpService.post(
      `${SUBSCRIBERS + START_SUBSCRIBER_ACTIVATION}`,
      requestData,
      this.config
    );
  }
  activateSubscriberPlan(requestData) {
    return this.httpService.post(
      `${SUBSCRIBERS + SUBSCRIBER_PLAN_ACTIVATION}`,
      requestData,
      this.config
    );
  }

  getProducts() {
    return this.httpService.get(CRM_PRODUCTS, {}, this.config);
  }
  portInCheck(requestData) {
    return this.httpService.post(`${ACCOUNTS + PORT_IN_CHECK}`, requestData, this.config);
  }
  portInStart(requestData) {
    return this.httpService.post(`${SUBSCRIBERS + PORT_IN_MAKE_START}`, requestData, this.config);
  }
  portInMake(requestData) {
    return this.httpService.post(`${SUBSCRIBERS + PORT_IN_MAKE}`, requestData, this.config);
  }

  getDistributorTransactionsList(userId) {
    return this.httpService.get(`${ADMINS + TRANSACTIONS}/${userId}`, {}, this.config);
  }

  fetchDistributorTransactionsList(userId) {
    return this.httpService.get(`${TRANSACTIONS}/${userId}`, {}, this.config);
  }

  getDistributorSummary(userId) {
    return this.httpService.get(`${ADMINS + DISTRIBUTORS + SUMMARY}/${userId}`, {}, this.config);
  }

  fetchDistributorSummary(userID) {
    return this.httpService.get(`${DISTRIBUTORS + SUMMARY}/${userID}`, {}, this.config);
  }

  getSubscribersManagementData(userID) {
    return this.httpService.get(`${DISTRIBUTORS + SUMMARY}/${userID}`, {}, this.config);
  }

  getActiveSubscribersData(userID, queryParams) {
    return this.httpService.get(`${SUBSCRIBERS}/${userID}${queryParams}`, {}, this.config);
  }

  getProductsData() {
    return this.httpService.get(PLANS, {}, this.config);
  }

  getTransactions(endpoint) {
    return this.httpService.get(endpoint, {}, this.config);
  }

  getEarningStats(endpoint) {
    return this.httpService.get(endpoint, {}, this.config);
  }

  getEarningList(endpoint) {
    return this.httpService.get(endpoint, {}, this.config);
  }

  getSubscriberAccountDetails(mid) {
    return this.httpService.get(`/accounts/by-account/${mid}`, {}, this.config);
  }

  getWithdrawalRequestsNewData() {
    return this.httpService.get(`/admin/withdrawals`, {}, this.config);
  }

  getWithdrawalRequestsHistory() {
    return this.httpService.get(`/admin/withdrawals/history`, {}, this.config);
  }

  withdrawMoney(payload) {
    return this.httpService.post(`/withdrawal`, payload, this.config);
  }

  reviewWithdrawMoneyRequest(userId, payload) {
    return this.httpService.put(`/admin/withdrawal/${userId}`, payload, this.config);
  }
}
