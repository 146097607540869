import React, { useCallback, useEffect, useRef, useState } from 'react';
import CenterWrapper from '../common/CenterWrapper';
import { MdKeyboardArrowDown, MdOutlineCalendarToday } from 'react-icons/md';
import { IoMdRadioButtonOn, IoMdRadioButtonOff } from 'react-icons/io';
import { GenderOptions, ReferOptions } from '../constants';
import { parseDate, formatDate, isValidDate } from '../utils/dateUtils';
import Calendar from '../utils/Calender';
import { debounce } from '../utils/Debounce';
import UserService from '../services/user';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUser } from '../slices/userSlice';

function PersonalDetails({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [genderDropdown, setGenderDropdown] = useState(false);
  const [referDropdown, setReferDropdown] = useState(false);
  const [isCustomGender, setIsCustomGender] = useState(false);
  const [customGenderInput, setCustomGenderInput] = useState(''); // Track custom input

  const [selectedPronoun, setSelectedPronoun] = useState('');

  const genderDropdownRef = useRef(null);
  const genderDropdownChildRef = useRef(null);
  const referDropdownrRef = useRef(null);
  const referDropdownrChildRef = useRef(null);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const calendarRef = useRef(null);
  const [dobInput, setDobInput] = useState(formData.dob || '');

  useEffect(() => {
    const handler = (e) => {
      // Close gender dropdown if click is outside
      if (
        genderDropdownChildRef.current &&
        genderDropdownRef.current &&
        !genderDropdownRef.current.contains(e.target)
      ) {
        setGenderDropdown(false);
      }
      if (
        referDropdownrChildRef.current &&
        referDropdownrRef.current &&
        !referDropdownrRef.current.contains(e.target)
      ) {
        setReferDropdown(false);
      }
      // Close calendar if click is outside
      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);
  const handleCustomGenderInput = (e) => {
    const value = e.target.value;
    setCustomGenderInput(value);
    setFormData({ ...formData, gender: value }); // Update gender with custom input
    setSelectedPronoun('');
  };

  const handlePronounSelection = (pronoun) => {
    setSelectedPronoun(pronoun);
    setFormData({ ...formData, gender: pronoun }); // Update gender with pronoun
    setCustomGenderInput(''); // Clear custom input
    setReferDropdown(false);
  };

  const handleDateInputChange = (e) => {
    const value = e.target.value;
    setDobInput(value);
    debouncedFormatDate(value);
  };

  const handleGender = (selectedGender) => {
    if (selectedGender === 'Custom') {
      setFormData({ ...formData, gender: '' });
      setIsCustomGender(true); // Show custom gender inputs
    } else {
      setIsCustomGender(false); // Hide custom gender inputs
      setFormData({ ...formData, gender: selectedGender }); // Directly set the gender
    }
    setGenderDropdown(false);
  };

  const debouncedFormatDate = useCallback(
    debounce((value) => {
      // Clean the input to remove non-numeric characters except dashes
      const sanitizedDate = value.replace(/[^0-9-]/g, '');

      // Validate and format the sanitized date
      if (isValidDate(sanitizedDate)) {
        const parsedDate = parseDate(sanitizedDate, 'dd-MM-yyyy');
        if (parsedDate) {
          const formattedDate = formatDate(parsedDate, 'dd-MM-yyyy');
          setFormData({ ...formData, dob: formattedDate });
          setDobInput(formattedDate);
        }
      } else {
        // Handle invalid date (e.g., clear the date or show an error)
        setFormData({ ...formData, dob: '' });
        setDobInput('');
      }
    }, 2000), // 2000 ms delay
    []
  );

  const handleDateSelection = (selectedDate) => {
    const formattedDate = formatDate(selectedDate, 'dd-MM-yyyy');
    setFormData({ ...formData, dob: formattedDate });
    setDobInput(formattedDate); // Update local input state
    setCalendarOpen(false);
  };

  const handleSubmit = async () => {
    console.log('formdata', formData);
    
    const parsedDate = parseDate(formData.dob, 'dd-MM-yyyy');
    const formattedDate = formatDate(parsedDate, 'yyyy-MM-dd');
    const updatedFormData = {
      userId: user._id,
      name: formData.name,
      gender: formData.gender,
      dob: formattedDate,
      postalCode: formData.postalCode,
    };
    try {
      const response = await new UserService().updateProfile(updatedFormData);
      console.log('response', response);

      const updatedUserData = { ...updatedFormData, ...response };
      if (response) {
        dispatch(updateUser(updatedUserData));
        toast.success('Details Submitted Successfully', 4000);
        setSelectedTab((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    }
  };

  const dateObject = parseDate(formData.dob, 'dd-MM-yyyy') || new Date(); // Fallback to current date

  return (
    <>
      <CenterWrapper>
        <div className=" w-full flex flex-col gap-8">
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-extrabold text-[39px]  leading-[50.7px] -tracking-[0.5%] text-[#1D2224]">
              Provide personal details
            </h3>
            <p className="text-base leading-6 font-normal text-[#6C6F71]">
              Please provide your personal information to operate ezmobile features. Tap to
              continue.
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <label htmlFor="fullName" className="text-[#3F4345] text-base leading-6 ">
                Name
              </label>
              <input
                type="text"
                id="fullName"
                placeholder={formData.fullName}
                value={formData.fullName}
                onChange={handleChange}
                className="h-[48px] w-full bg-white focus:bg-white  px-[18px] py-4 rounded-xl border border-[#999C9C]  outline-none  text-[#1D2224]  placeholder:text-[#999C9C]  focus:border-2  focus:border-[#1D2224] focus:ring-0"
              />
            </div>
            <div className="flex flex-col gap-2 relative cursor-default" ref={genderDropdownRef}>
              <label htmlFor="gender" className="text-[#3F4345] text-base leading-6">
                Gender
              </label>
              <div
                className={` h-[48px]  w-full  px-[18px] py-4 rounded-xl 
                      ${genderDropdown ? 'border-2 border-[#1D2224]' : 'border border-[#999C9C]'}  flex justify-between  items-center  cursor-pointer`}
                onClick={() => setGenderDropdown(!genderDropdown)}>
                <p className="text-[#1D2224] text-[16px] capitalize">
                  {isCustomGender ? 'Custom' : formData.gender}
                </p>
                <MdKeyboardArrowDown
                  className={`h-[24px] w-[24px] transition-all duration-200 ${genderDropdown ? 'rotate-180' : ''}`}
                />
              </div>

              {genderDropdown && (
                <div
                  className="absolute top-[80px] w-full p-[4px] box-border bg-[#FDFDFD] rounded-xl shadow-lg z-10 overflow-y-auto"
                  ref={genderDropdownChildRef}>
                  {GenderOptions.length > 0 &&
                    GenderOptions.map((gender) => (
                      <div
                        key={gender}
                        className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                        onClick={() => handleGender(gender)}>
                        <div
                          key={gender}
                          className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2] cursor-pointer"
                          onClick={() => handleGender(gender)}>
                          {isCustomGender && gender === 'Custom' ? (
                            <IoMdRadioButtonOn className="text-[#1D2224] w-6 h-6" />
                          ) : !isCustomGender && formData.gender === gender ? (
                            <IoMdRadioButtonOn className="text-[#1D2224] w-6 h-6" />
                          ) : (
                            <IoMdRadioButtonOff className="text-[#999C9C] w-6 h-6" />
                          )}
                          <label className="text-[#1D2224] font-bold text-base leading-6">
                            {gender}
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
            {/* Render custom input and pronoun dropdown if "Custom" is selected */}
            {isCustomGender && (
              <>
                {/* Custom Gender Input */}
                <div className="flex flex-col gap-2">
                  <label htmlFor="customGender" className="text-[#3F4345] text-base leading-6">
                    What's your gender?
                  </label>
                  <input
                    type="text"
                    id="customGender"
                    placeholder="Type your gender"
                    value={customGenderInput}
                    onChange={handleCustomGenderInput}
                    className="h-[48px] w-full bg-white px-[18px] py-4 rounded-xl border border-[#999C9C] outline-none text-[#1D2224] placeholder:text-[#999C9C] focus:border-2 focus:border-[#1D2224] focus:ring-0"
                  />
                </div>

                {/* Pronoun Selection Dropdown */}
                <div
                  className="flex flex-col gap-2 relative cursor-default"
                  ref={referDropdownrRef}>
                  <label htmlFor="pronoun" className="text-[#3F4345] text-base leading-6">
                    Please refer me as
                  </label>
                  <div
                    className={`h-[48px] w-full px-[18px] py-4 rounded-xl 
          ${referDropdown ? 'border-2 border-[#1D2224]' : 'border border-[#999C9C]'} flex justify-between items-center cursor-pointer`}
                    onClick={() => setReferDropdown(!referDropdown)}>
                    <p className="text-[#1D2224] text-[16px] capitalize">{selectedPronoun}</p>
                    <MdKeyboardArrowDown
                      className={`h-[24px] w-[24px] transition-all duration-200 ${referDropdown ? 'rotate-180' : ''}`}
                    />
                  </div>

                  {referDropdown && (
                    <div
                      className="absolute top-[80px] w-full p-[4px] box-border bg-[#FDFDFD] rounded-xl shadow-lg z-10 overflow-y-auto"
                      ref={referDropdownrChildRef}>
                      {ReferOptions.map((pronoun) => (
                        <div
                          key={pronoun}
                          className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2] cursor-pointer"
                          onClick={() => handlePronounSelection(pronoun)}>
                          {selectedPronoun === pronoun ? (
                            <IoMdRadioButtonOn className="text-[#1D2224] w-6 h-6" />
                          ) : (
                            <IoMdRadioButtonOff className="text-[#999C9C] w-6 h-6" />
                          )}
                          <label className="text-[#1D2224] font-bold text-base leading-6">
                            {pronoun}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="flex flex-col gap-2 relative" ref={calendarRef}>
              <label htmlFor="dob" className="text-[#3F4345] text-base leading-6">
                Date of Birth
              </label>
              <div className="flex items-center h-[48px] w-full px-[18px] py-4 rounded-xl border border-[#999C9C]">
                <input
                  type="text"
                  id="dob"
                  placeholder="DD-MM-YYYY"
                  value={dobInput}
                  onChange={handleDateInputChange}
                  className="flex-1 outline-none text-[#1D2224] placeholder:text-[#999C9C] focus:ring-0"
                />
                <MdOutlineCalendarToday
                  className="h-[24px] w-[24px] ml-2 cursor-pointer"
                  onClick={() => setCalendarOpen(!calendarOpen)}
                />
              </div>

              {calendarOpen && (
                <div className="absolute bottom-[10px] right-0 z-10 mt-2">
                  <Calendar
                    currentDate={dateObject} // Pass the native Date object to the Calendar
                    setCurrentDate={handleDateSelection}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="postalCode" className="text-[#3F4345] text-base leading-6">
                Postal code
              </label>
              <input
                type="text"
                id="postalCode"
                placeholder={formData.postalCode}
                value={formData.postalCode}
                onChange={handleChange}
                className="h-[48px] w-full bg-white focus:bg-white px-[18px] py-4 rounded-xl border border-[#999C9C]  outline-none  text-[#1D2224]  placeholder:text-[#999C9C]  focus:border-2  focus:border-[#1D2224] focus:ring-0"
              />
            </div>
          </div>
          <button
            disabled={tabValidation < 1}
            onClick={handleSubmit}
            className="flex justify-center items-center rounded-3xl py-3 bg-[#39B8E4]">
            <p className="font-bold text-base leading-6">Continue</p>
          </button>
        </div>
      </CenterWrapper>
    </>
  );
}

export default PersonalDetails;
