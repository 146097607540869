import React from 'react';
import { NavLink } from 'react-router-dom';

const ForgotPassword = () => {
    return (
        <div className="min-h-screen p-10 flex justify-center items-center bg-[#F1F2F2] font-jakarta relative">
            <img src="/tittle-logo.svg" alt="EZ Mobile Logo" className="w-[170px] h-[48px] absolute top-[30px] left-[50px]" />

            <div className='flex flex-col gap-[10px]'>
                <h2 className="text-[#5A5E5F] text-[39px] font-bold">Forgot Password</h2>
                <p>A link has been sent to your resitered email address to reset your password</p>

                <NavLink to='/admin' className="bg-[#0EA8DE] text-white text-[14px] font-semibold h-[50px] max-w-[229px] mt-[5px] rounded-[12px] cursor-pointer flex justify-center items-center hover:opacity-90" >
                    Back to Login
                </NavLink>
            </div>
        </div>
    )
}

export default ForgotPassword;