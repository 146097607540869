import React, { useState } from 'react';
import { TbLoader3 } from 'react-icons/tb';
import WalletService from '../services/wallet';
import { toast } from 'react-toastify';

const WithdrawalConfirmationModal = ({ selectedWithdrawalRequest, setConfirmationModalOpen, setApproveModalOpen }) => {
  const [loading, setLoading] = useState(false);

  const handleApprove = async () => {
    try {
      const payload = {
        withdrawalId: selectedWithdrawalRequest?._id,
        status: "approved"
      };

      setLoading(true);
      const res = await new WalletService().reviewWithdrawMoneyRequest(selectedWithdrawalRequest?._id, payload);
      setLoading(false);
      if (res?.status === "Success") {
        setConfirmationModalOpen(false);
        setApproveModalOpen(true);
        toast.success('Request Approved Successfully');
      }
      else {
        toast.error('Unable to Submit');
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit');
      setLoading(false);
    }
  };

  return (
    <div className="fixed left-0 top-0 h-full w-full bg-[#0000005f] flex justify-center items-center z-50">
      <div className="h-[520px] w-[431px] p-[24px] box-border bg-[#F1F2F2] rounded-[24px] flex flex-col gap-[32px] relative font-jakarta">
        <img
          src="./close.svg"
          alt="Close-Icon"
          className="absolute top-[24px] right-[24px] h-[24px] w-[24px] cursor-pointer hover:opacity-60"
          onClick={() => setConfirmationModalOpen(false)}
        />

        <h6 className="text-[#69696F] text-[16px] text-center font-semibold">Withdrawal request</h6>

        <div className="flex flex-col gap-[8px]">
          <h3 className="text-[#1D2224] text-[39px] font-extrabold leading-none">${selectedWithdrawalRequest?.amount}</h3>
          <p className="text-[#B3161D] text-[16px] font-normal">
            Make sure you pay before tapping approve.
          </p>
        </div>

        <div className="flex flex-col gap-[16px]">
          <div className="flex flex-col gap-[2px]">
            <p className="text-[#1D2224] text-[14px]">User email</p>
            <p className="text-[#1D2224] text-[16px] font-semibold">{selectedWithdrawalRequest?.user?.email}</p>
          </div>

          <div className="flex flex-col gap-[2px]">
            <p className="text-[#1D2224] text-[14px]">Payment method</p>
            <p className="text-[#1D2224] text-[16px] font-semibold">{selectedWithdrawalRequest?.paymentMethod}</p>
          </div>

          <div className="flex flex-col gap-[2px]">
            <p className="text-[#1D2224] text-[14px]">Bank details</p>
            <p className="text-[#1D2224] text-[16px] font-semibold">Account name: Example</p>
            <p className="text-[#1D2224] text-[16px] font-semibold">Account name: 12345</p>
            <p className="text-[#1D2224] text-[16px] font-semibold">Routing number: 123456789</p>
          </div>
        </div>

        <button
          className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-full text-white rounded-xl"
          onClick={handleApprove}
        >
          {loading ? <TbLoader3 className="animate-spin w-7 h-7 text-white" /> : "Approve"}
        </button>
      </div>
    </div>
  );
};

export default WithdrawalConfirmationModal;
