import React, { useState, useEffect } from 'react';
import { TbLoader3 } from 'react-icons/tb';
import { useSelector, useDispatch } from 'react-redux';
import UserService from '../services/user';
import { updateUser } from '../slices/userSlice';
import Utils from '../utility';
import { toast } from 'react-toastify';

const EditUserModal = ({ setShowEditModal }) => {
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    name: user?.name,
    email: user?.email,
  });
  const [changePassword, setChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);
  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    if (id === 'currentPassword') {
      setCurrentPassword(value);
    } else if (id === 'newPassword') {
      setNewPassword(value);
    } else if (id === 'confirmPassword') {
      setConfirmPassword(value);
    }

    if (id === 'confirmPassword') {
      if (newPassword !== value) {
        setErrorPassword(true);
      } else {
        setErrorPassword(false);
      }
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async () => {
    // const encryptedPassword = Utils.encryptPassword(formData.password);
    // setFormData({ ...formData, password: encryptedPassword });
    setFormData({ ...formData });
    try {
      setLoading(true);
      const response =
        user?.roleId?.name === 'Admin'
          ? await new UserService().updateAdminProfile(user._id, formData)
          : await new UserService().updateDistirbutorProfile(user._id, formData);
      setLoading(false);
      if (response) {
        if (user?.roleId?.name === 'Admin') {
          dispatch(updateUser({ ...user, name: formData.name, email: formData.email }));
        } else {
          dispatch(updateUser({ ...user, name: formData.name }));
        }
        setShowEditModal(false);
      } else {
        const errorData = await response.json();
        console.error('Error updating distributor profile', errorData);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error updating distributor profile', error);
    }
  };
  const handleClick = () => {
    setChangePassword((prev) => !prev);
  };

  const updatePasswordChange = async () => {
    if (changePassword) {
      if (!currentPassword) {
        toast.error('Please provide current password', {
          autoClose: 1000,
        });
        return;
      }
      if (newPassword !== confirmPassword) {
        toast.error('Passwords do not match', { autoClose: 1000 });
        return;
      }
      if (currentPassword === newPassword) {
        toast.error('New password cannot be the same as the current password', {
          autoClose: 1000,
        });
        return;
      }
    }

    try {
      setLoading(true);
      const response = await new UserService().changePassword(
        user?._id,
        currentPassword,
        newPassword
      );
      setLoading(false);
      if (response.message) {
        toast.error('Error updating password');
      } else {
        const encryptedPassword = Utils.encryptPassword(newPassword);
        dispatch(updateUser({ ...user, password: encryptedPassword }));
        toast.success('Password updated successfully', { autoClose: 1000 });
        setShowEditModal(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Error updating password: ' + error.message);
      console.error('Error updating password', error);
    }
  };

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-black bg-opacity-50 z-1000 flex justify-center items-center">
      <div className="relative max-h-90per w-[600px] p-[50px] bg-white rounded-2xl overflow-y-auto hide-scrollbar flex flex-col items-center gap-[40px]">
        <div className="absolute top-[20px] right-[20px] hover:opacity-60">
          <img
            className="h-6 w-6 cursor-pointer"
            src="./close.svg"
            alt="Close"
            onClick={() => setShowEditModal(false)}
          />
        </div>

        <h1 className="text-[#1D2224] font-bold text-ft27 font-jakarta">Edit Your Profile</h1>

        <div className="flex flex-col gap-[10px]">
          {!changePassword && (
            <>
              {' '}
              <div className="flex flex-col gap-[2px]">
                <label htmlFor="name" className="font-jakarta">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter Your Name"
                  className="w-[350px] py-[5px] px-[7px] border-[1.2px] border-[#000000] rounded-md outline-none"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col gap-[2px]">
                <label htmlFor="email" className="font-jakarta">
                  Email:
                </label>
                <input
                  type="text"
                  id="email"
                  placeholder="Enter Your Name"
                  className="w-[350px] py-[5px] px-[7px] border-[1.2px] bg-transparent border-[#000000] rounded-md outline-none disabled:cursor-not-allowed"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={user?.roleId?.name !== 'Admin'}
                />
              </div>
            </>
          )}

          {user?.roleId?.name === 'Admin' &&
            (changePassword ? (
              <>
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="password1" className="font-jakarta">
                    Current Password:
                  </label>
                  <input
                    type="password"
                    id="currentPassword"
                    placeholder="Enter New Password"
                    className="w-[350px] py-[5px] px-[7px] border-[1.2px] border-[#000000] rounded-md outline-none"
                    value={currentPassword}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="password2" className="font-jakarta">
                    New Password:
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    placeholder="Confirm New Password"
                    className="w-[350px] py-[5px] px-[7px] border-[1.2px] border-[#000000] rounded-md outline-none"
                    value={newPassword}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="currentPassword" className="font-jakarta">
                    Confirm Password:
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    placeholder="Enter Current Password"
                    className="w-[350px] py-[5px] px-[7px] border-[1.2px] border-[#000000] rounded-md outline-none"
                    value={confirmPassword}
                    onChange={handlePasswordChange}
                  />
                </div>
                {errorPassword && (
                  <div className="text-red-500 text-center mb-4">Password do not match</div>
                )}
                <button
                  className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center w-[350px] py-[7px] px-[7px] text-white rounded-xl mt-4"
                  onClick={handleClick}
                >
                  Back
                </button>
              </>
            ) : (
              <button
                className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center w-[350px] py-[7px] px-[7px] text-white rounded-xl"
                onClick={handleClick}
              >
                Change Password
              </button>
            ))}
        </div>

        <div className="flex gap-4 justify-center">
          <button
            className="text-[#1D2224] w-[150px] py-2 px-4 rounded-xl hover:bg-[#dad7d7] font-jakarta"
            onClick={() => {
              setShowEditModal(false);
              handleClick();
            }}
          >
            Cancel
          </button>

          <button
            className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[150px] text-white py-2 px-4 rounded-xl"
            onClick={changePassword ? updatePasswordChange : handleSubmit}
            disabled={loading}
          >
            {loading ? <TbLoader3 className="animate-spin w-5 h-5 text-white" /> : 'Update'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
