import React, { useState, useEffect } from 'react';
import { generateDummySubscribers } from '../utils/generateSubscriber';
import Header from '../header/header';
import { useNavigate } from 'react-router-dom';
import ProductInfo from './productCategories';
import Sidebar from '../sidebar/sidebar';
import UserProfileSidebar from '../dashboard/userProfileModal';
import { updateUser } from '../slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const DUMMY_SUBSCRIBERS_COUNT = 30;

const Product = ({ setSelectedAdminSubmenu }) => {
  const [subscribers, setSubscribers] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState('Product Catalogue');
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebarCollapsed');
    return savedState === 'true';
  });
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [dropdownQuery, setDropdownQuery] = useState('name');
  const [searchInput, setSearchInput] = useState('');
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedSubscribers = JSON.parse(localStorage.getItem('subscribers'));
    if (storedSubscribers) {
      setSubscribers(storedSubscribers);
    } else {
      const dummySubscribers = generateDummySubscribers(DUMMY_SUBSCRIBERS_COUNT);
      localStorage.setItem('subscribers', JSON.stringify(dummySubscribers));
      setSubscribers(dummySubscribers);
    }
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleAddSubscriber = (newSubscribers) => {
    setSubscribers(newSubscribers);
    localStorage.setItem('subscribers', JSON.stringify(newSubscribers));
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    localStorage.setItem('sidebarCollapsed', !collapsed);
  };

  const toggleProfileMenu = () => {
    setProfileMenuOpen((prev) => !prev);
  };

  const toggleProfileSidebar = () => {
    setProfileMenuOpen((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('user');
    dispatch(updateUser({...user, isLoggedIn: false}));
    navigate('/');
  };

  return (
    <div className="flex h-screen ">
      <>
        <Sidebar
          className="fixed left-0"
          collapsed={collapsed}
          toggleSidebar={toggleSidebar}
          setSelectedAdminSubmenu={setSelectedAdminSubmenu}
        />

        <div className={`flex-grow overflow-y-auto flex flex-col ${collapsed ? 'ml-2' : 'ml-3'}`}>
          <div className={`fixed ${collapsed ? 'w-[95%]' : 'w-[85%]'} top-0 z-10`}>
            <Header
              onSearch={handleSearch}
              collapsed={collapsed}
              toggleProfileSidebar={toggleProfileSidebar}
              dropdownQuery={dropdownQuery}
              setDropdownQuery={setDropdownQuery}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
          </div>

          <div className="mt-16  ">
            <ProductInfo />
          </div>
        </div>
      </>

      <UserProfileSidebar
        handleLogout={handleLogout}
        profileMenuOpen={profileMenuOpen}
        toggleProfileSidebar={toggleProfileSidebar}
      />
    </div>
  );
};

export default Product;
