import React, { useEffect, useState } from 'react';
import { TbLoader3 } from 'react-icons/tb';
import WithdrawalConfirmationModal from './withdrawalConfirmationModal';
import WithdrawalApprovedModal from './withdrawalApprovedModal';
import WalletService from '../services/wallet';

const WithdrawalRequests = ({ setSelectedAdminSubmenu }) => {
  const [withdrawalNewRequestLoading, setWithdrawalNewRequestLoading] = useState(true);
  const [withdrawalHistoryLoading, setWithdrawalHistoryLoading] = useState(true);
  const [newRequestsData, setNewRequestsData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [selectedWithdrawalRequest, setSelectedWithdrawalRequest] = useState(null);

  const handleWithdrawalRequestClick = (selectedItem) => {
    setSelectedWithdrawalRequest(selectedItem);
    setConfirmationModalOpen(true);
  }

  const fetchWithdrawalRequestsNewData = async () => {
    try {
      setWithdrawalNewRequestLoading(true);
      const res = await new WalletService().getWithdrawalRequestsNewData();
      setNewRequestsData(res);
      setWithdrawalNewRequestLoading(false);
    } catch (error) {
      console.error(error);
      setWithdrawalNewRequestLoading(false);
    }
  }

  const fetchWithdrawalRequestsHistory = async () => {
    try {
      setWithdrawalHistoryLoading(true);
      const res = await new WalletService().getWithdrawalRequestsHistory();
      setHistoryData(res);
      setWithdrawalHistoryLoading(false);
    } catch (error) {
      console.error(error);
      setWithdrawalHistoryLoading(false);
    }
  }

  useEffect(() => {
    fetchWithdrawalRequestsNewData();
    fetchWithdrawalRequestsHistory();
  }, [])

  return (
    <>
      {confirmationModalOpen && (
        <WithdrawalConfirmationModal
          selectedWithdrawalRequest={selectedWithdrawalRequest}
          setConfirmationModalOpen={setConfirmationModalOpen}
          setApproveModalOpen={setApproveModalOpen}
        />
      )}

      {approveModalOpen && <WithdrawalApprovedModal selectedWithdrawalRequest={selectedWithdrawalRequest} setSelectedWithdrawalRequest={setSelectedWithdrawalRequest} setApproveModalOpen={setApproveModalOpen} setSelectedAdminSubmenu={setSelectedAdminSubmenu} fetchWithdrawalRequestsNewData={fetchWithdrawalRequestsNewData} fetchWithdrawalRequestsHistory={fetchWithdrawalRequestsHistory} />}

      <div className="p-8 w-full bg-[#F1F2F2] flex flex-col gap-[24px] font-jakarta" style={{ minHeight: 'calc(100vh - 64px)' }}>
        <div className="flex flex-col gap-[20px]">
          <h2 className="text-[#1D2224] text-[31px] font-bold">New requests</h2>

          <div className="overflow-x-auto relative">
            <table className="w-full border-collapse">
              <thead className="bg-[#F1F2F2] my-[20px]">
                <tr className="pb-[8px] text-ft1_5 text-[#6C6F71] uppercase border-b border-solid border-[#DDDEDE] cursor-default">
                  <th className="w-[30%] pb-[8px] text-left font-medium">User</th>
                  <th className="w-[20%] pb-[8px] text-left font-medium">Type</th>
                  <th className="w-[25%] pb-[8px] text-left font-medium">Payment Method</th>
                  <th className="w-[25%] pb-[8px] text-left font-medium">Withdrawal Amount</th>
                </tr>
              </thead>

              {withdrawalNewRequestLoading ? (
                <div className="flex justify-center items-center w-full h-60">
                  <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-50per left-45per" />
                </div>
              ) : (
                <tbody>
                  {newRequestsData?.length > 0 ? (
                    newRequestsData?.map((item, index) => (
                      <tr
                        className="h-[48px] cursor-pointer hover:bg-white"
                        key={index}
                        onClick={() => handleWithdrawalRequestClick(item)}
                      >
                        <td className="w-[30%] text-[#1D2224] text-[16px]">{item?.user?.email ? item?.user?.email : "N/A"}</td>
                        <td className="w-[20%] text-[#1D2224] text-[16px]">{item?.role?.name ? item?.role?.name : "N/A"}</td>
                        <td className="w-[25%] text-[#1D2224] text-[16px]">{item?.paymentMethod ? item?.paymentMethod : "N/A"}</td>
                        <td className="w-[25%] text-[#1D2224] text-[16px]">
                          {item?.amount ? item?.amount : "N/A"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center py-5">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>

        <div className="flex flex-col gap-[20px]">
          <h2 className="text-[#1D2224] text-[31px] font-bold">History</h2>

          <div className="overflow-x-auto relative">
            <table className="w-full border-collapse">
              <thead className="bg-[#F1F2F2] my-[20px]">
                <tr className="pb-[8px] text-ft1_5 text-[#6C6F71] uppercase border-b border-solid border-[#DDDEDE] cursor-default">
                  <th className="w-[25%] pb-[8px] text-left font-medium">User</th>
                  <th className="w-[15%] pb-[8px] text-left font-medium">Type</th>
                  <th className="w-[22%] pb-[8px] text-left font-medium">Payment Method</th>
                  <th className="w-[23%] pb-[8px] text-left font-medium">Withdrawal Amount</th>
                  <th className="w-[15%] pb-[8px] text-left font-medium">Status</th>
                </tr>
              </thead>

              {withdrawalHistoryLoading ? (
                <div className="flex justify-center items-center w-full h-60">
                  <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-50per left-45per" />
                </div>
              ) : (
                <tbody>
                  {historyData?.length > 0 ? (
                    historyData?.map((item, index) => (
                      <tr className="h-[48px] cursor-default" key={index}>
                        <td className="w-[25%] text-[#1D2224] text-[16px]">{item?.user?.email ? item?.user?.email : "N/A"}</td>
                        <td className="w-[15%] text-[#1D2224] text-[16px]">{item?.role?.name ? item?.role?.name : "N/A"}</td>
                        <td className="w-[22%] text-[#1D2224] text-[16px]">{item?.paymentMethod ? item?.paymentMethod : "N/A"}</td>
                        <td className="w-[23%] text-[#1D2224] text-[16px]">
                          {item?.amount ? item?.amount : "N/A"}
                        </td>
                        <td
                          className={`w-[15%] text-[16px] ${item.status === 'rejected' ? 'text-[#E32028]' : 'text-[#1D2224]'}`}
                        >
                          {item?.status === "rejected" ? "Rejected" : "Approved & Paid"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center py-5">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawalRequests;
