import React from 'react';
import { NavLink } from 'react-router-dom';

const WithdrawalApprovedModal = ({ selectedWithdrawalRequest, setSelectedWithdrawalRequest, setApproveModalOpen, fetchWithdrawalRequestsNewData, fetchWithdrawalRequestsHistory }) => {
  const handleApproveMore = () => {
    fetchWithdrawalRequestsNewData();
    fetchWithdrawalRequestsHistory();
    setSelectedWithdrawalRequest(null);
    setApproveModalOpen(false);
  }

  return (
    <div className="fixed left-0 top-0 h-full w-full bg-[#0000005f] flex justify-center items-center z-50">
      <div className="h-[374px] w-[400px] p-[24px] box-border bg-[#FDFDFD] rounded-[24px] flex flex-col justify-center items-center gap-[40px] relative">
        <img
          src="./close.svg"
          alt="Close-Icon"
          className="absolute top-[24px] right-[24px] h-[24px] w-[24px] cursor-pointer"
          onClick={handleApproveMore}
        />

        <div className="flex flex-col items-center gap-[4px] w-full">
          <img src="./check-circle-blue.svg" alt="Check-Icon" className="h-[40px] w-[40px]" />
          <h4 className="mt-[8px] text-[#1D2224] text-[20px] font-bold">Approved succefully</h4>
          <p className="text-[#3F4345] text-[16px] font-normal">${selectedWithdrawalRequest?.amount}</p>
        </div>

        <div className="flex flex-col gap-[8px] w-full">
          <button
            className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-full text-white rounded-xl"
            onClick={handleApproveMore}
          >
            Approve more
          </button>
          <NavLink to='/dashboard' className="flex justify-center items-center h-[48px] w-full text-[#1D2224] rounded-xl" >
            Go to home
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalApprovedModal;
