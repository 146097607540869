import React, { useState, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import UserService from '../services/user';
import { MdDelete } from 'react-icons/md';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../slices/userSlice';
import { toast } from 'react-toastify';
import CenterWrapper from '../common/CenterWrapper';
import { MdOutlineFileUpload } from 'react-icons/md';

const Declaration = ({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) => {
  const [imageURL, setImageURL] = useState(formData?.signature ? formData?.signature : null);
  const [showSignaturePlaceholder, setShowSignaturePlaceholder] = useState(true);
  const sigCanvas = useRef({});
  const fileUploadRef = useRef(null);
  
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSaveCanvas = () => {
    const signatureDataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setImageURL(signatureDataUrl);
    setFormData({ ...formData, signature: signatureDataUrl });
  };

  const handleDeleteSignature = () => {
    setShowSignaturePlaceholder(true);
    setImageURL(null);
    setFormData({ ...formData, signature: null });
  };

  const handleUploadSignature = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const reqFormData = new FormData();
    reqFormData.append('fileName', file?.name);
    reqFormData.append('files', file);
    reqFormData.append('userType', user?.roleId?.name === 'Admin' ? 'MD' : 'AD');

    try {
      const res = await new UserService().fileUpload(reqFormData);
      setFormData({ ...formData, signature: res?.cdnUrl });
      setImageURL(res?.cdnUrl);
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const handleSubmit = async () => {
    // setLoading(true);
    console.log('formdata', formData);
    const updatedFormData = {
      userId: user._id,
      isTermsAccepted: formData.isTermsAccepted,
      signature: formData.signature,
      isInformationSubmitted :true
      // dateSigned:formData.dateSigned
    };
    try {
      const response = await new UserService().updateProfile(updatedFormData);

      const updatedUserData = { ...updatedFormData, ...response };
      if (response) {
        dispatch(updateUser(updatedUserData));
        toast.success('Details Submitted Successfully', 4000);
        setSelectedTab((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <>
      <CenterWrapper>
        <div className="w-full flex flex-col gap-8">
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-extrabold text-[39px]  leading-[50.7px] -tracking-[0.5%] text-[#1D2224]">
              Accept terms & conditions
            </h3>
            <p className="text-base leading-6 font-normal text-[#6C6F71]">
              Provide signature and accept our T&C and privacy policies.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="signature" className="text-[#6C6F71]">
              Signature of the applicant
            </label>
            <div className="flex items-center gap-[10px] relative">
              {!imageURL && (
                <div className="relative bg-[#FDFDFD] w-full rounded-[12px]">
                  {showSignaturePlaceholder && (
                    <div className="flex flex-col justify-center items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                      <img
                        src="./signature-icon.svg"
                        alt="Signature-Icon"
                        className="h-[40px] w-[40px]"
                      />
                      <p className="text-[#999C9C] text-[16px]">Sign Here</p>
                    </div>
                  )}
                  <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{ className: 'signatureCanvas' }}
                    onBegin={() => setShowSignaturePlaceholder(false)}
                    onEnd={handleSaveCanvas}
                  />
                </div>
              )}
              <div
                onClick={() => fileUploadRef.current.click()}
                className="w-12 h-12 rounded-xl p-3 bg-[#E7F6FC] absolute bottom-2 right-2 flex
                  justify-center items-center z-50">
                <MdOutlineFileUpload />
              </div>

              {imageURL && (
                <div className="relative bg-[#FDFDFD] h-[120px] w-full rounded-[12px] border border-solid border-[#DDDEDE] flex justify-center items-center">
                  <img src={imageURL} alt="Signature" className="h-[80%]" />
                </div>
              )}

              {imageURL && (
                <MdDelete
                  className="absolute right-[-30px] cursor-pointer h-[20px] w-[20px] text-[#6c6f71]"
                  onClick={handleDeleteSignature}
                />
              )}
            </div>
            <input
              type="file"
              className="hidden"
              ref={fileUploadRef}
              onChange={handleUploadSignature}
            />
          </div>
          <div className="flex gap-1">
            <div className="w-5 h-5 flex items-center justify-center pt-1">
              <input
                type="checkbox"
                id="declaration"
                defaultChecked={formData.isTermsAccepted}
                onChange={() =>
                  setFormData({
                    ...formData,
                    isTermsAccepted: !formData.isTermsAccepted,
                  })
                }
                className="h-[15px] w-[15px]"
              />
            </div>
            <div className="flex flex-col gap[2px] grow">
              <label htmlFor="declaration">
                I accept all the <span className="font-bold">terms of service</span> and{' '}
                <span className="font-bold">privacy policy</span>.
              </label>
              <p className="text-[#6C6F71] text-[14px] font-normal leading-5 h-auto">
                {isExpanded ? (
                  <>
                    I hereby declare that the information provided in this form is accurate and
                    complete. I confirm that any information is found incorrect and/or incomplete
                    that leads a violation of regulations may initiate legal actions, and I accept
                    that I am the responsible party for any and all charges, penalties, and
                    violations.
                  </>
                ) : (
                  'I hereby declare that the information provided in this...'
                )}
                <span
                  className="text-[#0EA8DE] text-[14px] font-medium cursor-pointer ml-1"
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}>
                  {isExpanded ? ' Show less' : ' Show more'}
                </span>
              </p>
            </div>
          </div>

          <button
            disabled={tabValidation < 4}
            onClick={handleSubmit}
            className="flex justify-center items-center rounded-3xl py-3 bg-[#39B8E4]">
            <p className="font-bold text-base leading-6">Continue</p>
          </button>
        </div>
      </CenterWrapper>
    </>
  );
};

export default Declaration;
