import React from 'react'
import MessageWrapper from '../common/MessageWrapper';

function FormSubmitted() {
  return (
     <MessageWrapper
       heading={'Thanks for submitting the information.'}
       subheading={
         'We’ll do a security check and approve you as our authorized distributor.'
       }
      isbackButtonShow={true}
     />
   );
}

export default FormSubmitted