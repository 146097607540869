import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TbLoader3 } from 'react-icons/tb';
import { MdAttachMoney } from 'react-icons/md';
import Utils from '../utility';
import { NavLink, useNavigate } from 'react-router-dom';
import UserService from '../services/user';
import { CreateAccountModal, OnboardModal } from './Modals';

const EarningList = ({
  loading,
  transactions,
  sortConfig,
  setSortConfig,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalTransactions,
  exportAdminTransactionsToCSV,
  exportToCSV,
  earningCardsData,
}) => {
  const user = useSelector((state) => state.user);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openCreateAccountModal, setOpenCreateAccountModal] = useState(false);
  const [openOnboardModal, setOpenOnBoardModal] = useState(false);
  const navigate = useNavigate();
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const paginationRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, []);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage * rowsPerPage < totalTransactions) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleWithdrawMoney = async () => {
    setIsLoading(true);
    try {
      if (!user.isStripeAccount && !user.stripeAccountId) {
        // User does not have a Stripe account, open create account modal
        setOpenCreateAccountModal(true);
      } else if (user.isStripeAccount && user.stripeAccountId) {
        // User has a Stripe account, check its status
        const response = await new UserService().checkStripeAccountStatus(user.stripeAccountId);
        const status = response.status;

        if (!status) {
          // Account not fully onboarded, open onboard modal
          setOpenOnBoardModal(true);
        } else {
          // Account is fully onboarded, redirect to withdraw money
          navigate('/withdraw-money');
        }
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAccount = async () => {
    setIsLoading(true);
    try {
      const payload = {
        userId: user._id,
        fullName: user.name,
        country: 'us',
        email: user.email,
      };
      const response = await new UserService().createAccount(payload);
      const stripeCreateAccountUrl = response.url;
      if (stripeCreateAccountUrl) {
        window.location.href = stripeCreateAccountUrl;
      } else {
        throw new Error('Stripe account creation URL not found.');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnboard = async () => {
    setIsLoading(true);
    try {
      const onboardResponse = await new UserService().stripeGetOnboard(user.stripeAccountId);
      const onboardUrl = onboardResponse.url;
      if (onboardUrl) {
        window.location.href = onboardUrl;
      } else {
        throw new Error('Stripe onboarding URL not found.');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-screen font-jakarta">
      {/* Modals */}
      <CreateAccountModal
        isOpen={openCreateAccountModal}
        onClose={() => setOpenCreateAccountModal(false)}
        onCreate={handleCreateAccount}
        isLoading={isLoading}
      />
      <OnboardModal
        isOpen={openOnboardModal}
        onClose={() => setOpenOnBoardModal(false)}
        onOnboard={handleOnboard}
        isLoading={isLoading}
      />

      <div className="p-8 flex-1 bg-[#F1F2F2]">
        <div className="flex justify-between items-center mb-6">
          <div className="flex flex-col gap-[4px]">
            <h2 className="text-3xl font-bold">Earnings</h2>
            <p className="text-[#6C6F71] text-[16px]">
              Manage all earnings and see the latest subscription.
            </p>
          </div>

          <NavLink
            to="/withdraw-money"
            className="flex items-center justify-center gap-[4px] bg-[#1D2224] hover:bg-opacity-85 rounded-[24px] text-[#FDFDFD] px-4 py-2 h-12 cursor-pointer w-[190px]"
            disabled={isLoading}
          >
            <MdAttachMoney className="size-6" />
            <span>Withdraw Money</span>
          </NavLink>
        </div>

        <div className="grid grid-cols-3 gap-6 mb-4">
          {earningCardsData?.map((item, index) => (
            <div key={index} className="bg-white px-6 py-6 rounded-[24px] flex justify-between">
              <div className="flex justify-between w-full ">
                <div className="flex flex-col">
                  <span className="text-[18px] font-semibold text-[#1D2224]">{item.title}</span>
                  <span className="text-[16px] font-normal text-[#6C6F71]">{item.subValue}</span>
                </div>
                <div className="text-[28px] font-jakarta text-[#1D2224] font-extrabold ">
                  {item.value}
                </div>
              </div>
            </div>
          ))}
        </div>

        <h3 className="text-2xl font-bold mt-[30px] ml-[6px]">Recent</h3>

        <div className="overflow-x-auto relative">
          <table className="w-full border-collapse">
            <thead className="bg-[#F1F2F2] border-b border-solid border-[#DDDEDE]">
              <tr className="text-ft1_5 font-normal text-[#6C6F71] uppercase">
                <th
                  className="text-[#6C6F71] font-semibold text-left p-2 cursor-pointer"
                  onClick={() => requestSort('userId')}
                >
                  Transaction ID
                </th>
                {user?.roleId?.name === 'Admin' && (
                  <th
                    className="text-[#6C6F71] font-semibold text-left p-2 cursor-pointer"
                    onClick={() => requestSort('transactionId')}
                  >
                    Wallet ID
                  </th>
                )}

                <th
                  className="text-[#6C6F71] font-semibold text-left p-2 relative right-[35px] cursor-pointer"
                  onClick={() => requestSort('user')}
                >
                  User
                </th>
                <th
                  className="text-[#6C6F71] font-semibold text-left p-2 cursor-pointer"
                  onClick={() => requestSort('addedOn')}
                >
                  Date & Time
                </th>
                <th
                  className="text-[#6C6F71] font-semibold text-left p-2 cursor-pointer"
                  onClick={() => requestSort('description')}
                >
                  Description
                </th>
                <th
                  className="text-[#6C6F71] font-semibold text-left p-2 cursor-pointer"
                  onClick={() => requestSort('amount')}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {loading ? (
              <div className="flex justify-center items-center w-full h-80">
                <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-50per left-45per" />
              </div>
            ) : (
              <tbody>
                {transactions?.length > 0 ? (
                  transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td className="py-4 px-2">
                        {transaction?._id === 'N/A'
                          ? transaction._id
                          : transaction._id?.length > 24
                            ? `${transaction._id.slice(0, 12)}...${transaction._id.slice(-12)}`
                            : transaction._id}
                      </td>
                      {user?.roleId?.name === 'Admin' && (
                        <td className="py-4 px-2">{transaction?.walletId}</td>
                      )}
                      <td className="py-4 px-2 relative right-[35px]">User</td>
                      <td className="py-4 px-2">{Utils.formatTimestamp(transaction.addedOn)}</td>
                      {/* <td className="py-4 px-2">{transaction.description}</td> */}
                      <td className="py-4 px-2">
                        {transaction.description?.length > 30
                          ? `${transaction.description.slice(
                            0,
                            15
                          )}...${transaction.description.slice(-15)}`
                          : transaction.description}
                      </td>
                      <td className="py-4 px-2">
                        <span className="text-green-500">+${transaction.amount}</span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center py-3">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>

        <div className="flex justify-end items-center pb-4 mt-4">
          <div className="flex items-center gap-10">
            <div className="flex items-center">
              <span>Rows per page:</span>
              <div className='relative' onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)} ref={paginationRef}>
                <img src="./chevron_backward.svg" alt="Chevron-Down Icon" className={`absolute right-[15px] top-[17px] transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`} style={{ transitionDuration: '100ms' }} />
                <select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  className="ml-2 py-2 pl-[16px] w-[68px] h-[40px] outline-none border-none rounded-[24px] appearance-none"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
            <span>
              {(currentPage - 1) * rowsPerPage + 1} -{' '}
              {currentPage * rowsPerPage > totalTransactions
                ? totalTransactions
                : currentPage * rowsPerPage}{' '}
              of {totalTransactions}
            </span>
            <div>
              <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
                &lt;
              </button>
              <button className="p-2 rounded-lg" onClick={handleNextPage}>
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EarningList;
