import React, { useEffect, useRef, useState } from 'react';
import CenterWrapper from '../common/CenterWrapper';
import {
  MdClose,
  MdKeyboardArrowDown,
  MdOutlineDone,
  MdError,
  MdOutlineFileUpload,
} from 'react-icons/md';
import { IoMdRadioButtonOn, IoMdRadioButtonOff } from 'react-icons/io';
import { FaFile } from 'react-icons/fa6';
import UserService from '../services/user';
import { IdentityOptions } from '../constants';
import Utils from '../utility';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUser } from '../slices/userSlice';

function IdentityDetails({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);

  const [selectedType, setSelectedType] = useState(null);
  const [identityDropdown, setIdentityDropdown] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(formData.identities);
  const [uploadedTypes, setUploadedTypes] = useState(formData.identities.map((item) => item.type));
  const identityProofDropdownRef = useRef(null);
  const identityProofDropdownChildRef = useRef(null);
  const identityProofFileRef = useRef(null);

  useEffect(() => {
    const handler = (e) => {
      // Close gender dropdown if click is outside
      if (
        identityProofDropdownChildRef.current &&
        identityProofDropdownRef.current &&
        !identityProofDropdownRef.current.contains(e.target)
      ) {
        setIdentityDropdown(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [identityDropdown]);

  const handleIdentityProof = (selectedIdentityProof) => {
    setSelectedType(selectedIdentityProof);
    setIdentityDropdown(false);
  };

  const handleFileUpload = async (e) => {
    console.log('e', e.target.files);

    if (!selectedType || !e.target.files.length) return;

    // Check if the document type has already been uploaded
    if (uploadedTypes.includes(selectedType)) {
      console.log('You have already uploaded a document of this type.');

      Utils.failureToastMessage('You have already uploaded a document of this type.');
      return;
    }

    const file = e.target.files[0];
    const newUpload = {
      name: file.name,
      size: file.size,
      type: selectedType,
      status: 'uploading',
    };

    setUploadingFiles([...uploadingFiles, newUpload]);
    setUploadedTypes([...uploadedTypes, selectedType]); // Add to uploadedTypes

    const reqFormData = new FormData();
    reqFormData.append('fileName', file.name);
    reqFormData.append('files', file);

    try {
      const res = await new UserService().fileUpload(reqFormData);

      // Update the status of the uploaded file
      setUploadingFiles((prevFiles) =>
        prevFiles.map((item) =>
          item.name === file.name
            ? { ...item, status: 'uploaded', documentLink: res?.cdnUrl }
            : item
        )
      );

      // Add successfully uploaded file to formData
      setFormData({
        ...formData,
        identities: [
          ...formData.identities,
          {
            type: selectedType,
            name: file.name,
            size: file.size,
            documentLink: res?.cdnUrl,
            // status: 'uploaded',
          },
        ],
      });
    } catch (error) {
      // Mark the file as failed
      setUploadingFiles((prevFiles) =>
        prevFiles.map((item) => (item.name === file.name ? { ...item, status: 'failed' } : item))
      );
      console.error('File upload failed:', error);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = uploadingFiles.filter((_, i) => i !== index);
    setUploadingFiles(updatedFiles);

    const updatedProofs = formData.identities.filter((_, i) => i !== index);
    setFormData({ ...formData, identities: updatedProofs });
  };

  const handleSubmit = async () => {
    console.log('formdata', formData);
    const updatedFormData = {
      userId: user?._id,
      identities: formData?.identities,
    };
    try {
      const response = await new UserService().updateProfile(updatedFormData);
      console.log('response', response);

      const updatedUserData = { ...updatedFormData, ...response };
      if (response) {
        dispatch(updateUser(updatedUserData));
        toast.success('Details Submitted Successfully', 4000);
        setSelectedTab((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    }
  };

  return (
    <>
      <CenterWrapper>
        <div className=" w-full flex flex-col gap-8">
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-extrabold text-[39px]  leading-[50.7px] -tracking-[0.5%] text-[#1D2224]">
              Proof of identity
            </h3>
            <p className="text-base leading-6 font-normal text-[#6C6F71]">
              Provide documents to proof your residence
            </p>
          </div>

          <div
            className="flex flex-col gap-[2px] relative cursor-default"
            ref={identityProofDropdownRef}>
            <label htmlFor="identityProof" className="text-[#1D2224] text-base leading-6 ">
              Type of document
            </label>
            <div
              className={` h-[48px]  w-full  px-[18px] py-4 rounded-xl 
                      ${identityDropdown ? 'border-2 border-[#1D2224]' : 'border border-[#999C9C]'}  flex justify-between  items-center  cursor-pointer`}
              onClick={() => setIdentityDropdown(!identityDropdown)}>
              <p className={`${selectedType ? 'text-[#1D2224]' : 'text-[#999C9C]'}`}>
                {selectedType || 'Please Select'}
              </p>
              <MdKeyboardArrowDown
                className={`h-[20px] w-[20px] transition-all ${identityDropdown ? 'rotate-180' : ''}`}
              />
            </div>

            {identityDropdown && (
              <div
                className="absolute top-[80px] h-[152px] w-full bg-[#FDFDFD] rounded-xl shadow-lg z-10 overflow-y-auto"
                ref={identityProofDropdownChildRef}>
                {IdentityOptions.length > 0 &&
                  IdentityOptions.map((type) => {
                    // Determine if the type is uploaded or selected
                    const isSelected = selectedType === type;
                    const isUploaded = uploadedTypes.includes(type);
                    const isDisabled = isUploaded; // Disable if uploaded
                    return (
                      <div
                        key={type}
                        className={`flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] hover:bg-[#F1F2F2] ${isDisabled ? 'cursor-not-allowed text-gray-500' : ''}`}
                        onClick={() => !isDisabled && handleIdentityProof(type)}>
                        {/* Render the icon based on whether the document type is selected or uploaded */}
                        {isSelected || isUploaded ? (
                          <IoMdRadioButtonOn className="text-[#1D2224] w-5 h-5" />
                        ) : (
                          <IoMdRadioButtonOff className="text-[#999C9C] w-5 h-5" />
                        )}

                        <p
                          className={`text-[#1D2224] ${isSelected || isUploaded ? 'font-bold' : 'font-normal'}`}>
                          {type}
                        </p>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div
            className="h-[150px] w-full bg-[#FDFDFD] border border-solid border-[#DDDEDE] rounded-xl flex flex-col justify-center items-center cursor-pointer"
            onClick={() => identityProofFileRef.current.click()}>
            <MdOutlineFileUpload className="h-[40px] w-[40px]" />
            <input
              type="file"
              className="hidden"
              ref={identityProofFileRef}
              onChange={handleFileUpload}
            />
            <h4 className="text-[#2F3436] text-[20px] font-semibold">Browse files</h4>
            <p className="text-[#999C9C]">Drag and drop files here</p>
          </div>
          {/* Uploaded, Failed, and Uploading Files */}
          {uploadingFiles.map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-[#FDFDFD] rounded-xl mt-4 p-1 gap-4 px-4">
              <div className="flex items-center gap-4">
                {item.status === 'uploaded' ? (
                  <MdOutlineDone className="text-green-500 w-6 h-6" />
                ) : item.status === 'Failed' ? (
                  <MdError className="text-[#E32028]  w-6 h-6" />
                ) : (
                  <FaFile className="text-gray-500 w-6 h-6" />
                )}
                <div className="flex flex-col items-start">
                  <p className="text-base leading-6 text-[#1D2224]">{item.name}</p>
                  <p
                    className={`text-sm px-1 leading-5 ${item.status === 'failed' ? 'text-red-500' : 'text-[#999C9C]'}`}>
                    {item.status === 'uploading'
                      ? 'Uploading...'
                      : item.status === 'Failed'
                        ? 'Failed'
                        : ''}
                    {item.type} - {Math.ceil(item.size / 1000)} KB
                  </p>
                </div>
              </div>
              <MdClose
                className="text-gray-500 cursor-pointer  w-6 h-6"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}

          <button
            disabled={tabValidation < 2}
            onClick={handleSubmit}
            className="flex justify-center items-center rounded-3xl py-3 bg-[#39B8E4]">
            <p className="font-bold text-base leading-6">Continue</p>
          </button>
        </div>
      </CenterWrapper>
    </>
  );
}

export default IdentityDetails;
