import React, { useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import { BiExport } from 'react-icons/bi';
import { TbLoader3 } from 'react-icons/tb';

const SubscriberTableComp = ({
  subscribers = [],
  setSubscribers,
  subscribersLoading,
  userId,
  searchTerm,
  tableRole,
  totalDistributors,
  pagination,
  setPagination,
  setSubscriberDetailsOpen,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const paginationRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, []);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedSubscribers = [...subscribers].sort((a, b) => {
    if (!pagination.sortConfig.key) return 0;
    if (a[pagination.sortConfig.key] < b[pagination.sortConfig.key]) {
      return pagination.sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[pagination.sortConfig.key] > b[pagination.sortConfig.key]) {
      return pagination.sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const filteredSubscribers = sortedSubscribers.filter(
    (subscriber) =>
      subscriber.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      subscriber.number?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      subscriber.iccid?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      subscriber.imei?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      subscriber.email?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleNextPage = () => {
    if (pagination.currentPage * pagination.rowsPerPage < totalDistributors) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const exportToCSV = () => {
    const headers = ['Name', 'Number', 'ICCID', 'IMEI', 'Email'];
    const rows = filteredSubscribers.map((sub) => [
      sub.name,
      sub.number,
      sub.iccid,
      sub.imei,
      sub.email,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'subscribers.csv');
  };

  const handleRowsPerPageChange = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: Number(event.target.value),
      currentPage: 1,
    });
  };

  return (
    <div className="p-4 font-jakarta">
      <div className="flex justify-between">
        <h2 className="text-[25px] font-bold mb-4">
          Active Subscribers ({filteredSubscribers.length})
        </h2>
        <div
          onClick={exportToCSV}
          className="flex justify-center items-center bg-[#DDDEDE] text-[#1D2224] hover:bg-opacity-85 px-4 gap-2 py-2 h-12 rounded-[24px] cursor-pointer"
        >
          <BiExport className="size-6" />
          <span>Export to CSV</span>
        </div>
      </div>

      <div className={`overflow-x-auto relative `}>
        <table className="w-full border-collapse">
          <thead className="bg-[#F1F2F2]">
            <tr className="text-ft1_5 font-normal text-[#6C6F71] uppercase">
              <th
                className="text-left min-w-[210.4px] p-2 cursor-pointer"
                onClick={() => requestSort('name')}
              >
                Name
              </th>
              <th
                className="text-left min-w-[210.4px] p-2 cursor-pointer"
                onClick={() => requestSort('number')}
              >
                Number
              </th>
              <th
                className="text-left min-w-[210.4px] p-2 cursor-pointer"
                onClick={() => requestSort('iccid')}
              >
                ICCID
              </th>
              <th
                className="text-left min-w-[210.4px] p-2 cursor-pointer"
                onClick={() => requestSort('imei')}
              >
                IMEI
              </th>
              <th
                className="text-left min-w-[210.4px] p-2 cursor-pointer"
                onClick={() => requestSort('email')}
              >
                Email
              </th>
            </tr>
          </thead>

          {subscribersLoading ? (
            <div className="flex justify-center items-center h-96">
              <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-45per left-45per" />
            </div>
          ) : (
            <tbody>
              {filteredSubscribers.length > 0 ? (
                filteredSubscribers.map((subscriber, index) => (
                  <tr
                    key={index}
                    className="hover:rounded-[28px] hover:cursor-pointer hover:bg-white"
                    onClick={() => setSubscriberDetailsOpen(true)}
                  >
                    <td className="py-4 px-2">
                      {subscriber.firstName + ' ' + subscriber.lastName}
                    </td>
                    <td className="py-4 px-2">{subscriber.number}</td>
                    <td className="py-4 px-2">{subscriber.iccid}</td>
                    <td className="py-4 px-2">{subscriber.imei}</td>
                    <td className="py-4 px-2">{subscriber.email}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-3">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      <div className="flex justify-end items-center mt-4">
        <div className="flex items-center gap-10">
          <div className="flex items-center">
            <span>Rows per page:</span>
            <div className='relative' onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)} ref={paginationRef}>
            <img src="./chevron_backward.svg" alt="Chevron-Down Icon" className={`absolute right-[15px] top-[17px] transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`} style={{ transitionDuration: '100ms' }} />
            <select
              value={pagination.rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="ml-2 py-2 pl-[16px] w-[68px] h-[40px] outline-none border-none rounded-[24px] appearance-none"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
            </div>
          </div>
          <span>
            {(pagination.currentPage - 1) * pagination.rowsPerPage + 1} -{' '}
            {pagination.currentPage * pagination.rowsPerPage > totalDistributors
              ? totalDistributors
              : pagination.currentPage * pagination.rowsPerPage}{' '}
            of {totalDistributors}
          </span>
          <div>
            <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
              &lt;
            </button>
            <button className="p-2 rounded-lg" onClick={handleNextPage}>
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriberTableComp;
