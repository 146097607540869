import React from 'react';

function GlobalHeader({ children }) {
  return (
    <header className="w-full relative  flex items-center min-h-[100px] border-1 border-[#F1F2F2] px-[200px] py-[17px]">
      <img src="/tittle-logo.svg" alt="EZMobile Logo" className="h-12 w-[170px] mr-auto" />
      {children && <div className="absolute left-1/2 transform -translate-x-1/2">{children}</div>}
      {/* <img src="/close.svg" alt="close icon" className="ml-auto" /> */}
    </header>
  );
}

export default GlobalHeader;
