import React, { useState, useEffect, useRef } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import 'react-phone-number-input/style.css';

const BankDetails = ({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) => {
  const [accountTypeDropdownOpen, setAccountTypeDropdownOpen] = useState(false);
  const accountTypeDropdownRef = useRef(null);
  const accountTypeDropdownChildRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (
        accountTypeDropdownChildRef.current &&
        !accountTypeDropdownRef.current.contains(e.target)
      ) {
        setAccountTypeDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  useEffect(() => {
    if (formData?.routingNumber && !formData?.bankName) {
      setFormData({ ...formData, bankName: 'ABC Bank' });
    }
    if (!formData?.routingNumber && formData?.bankName) {
      setFormData({ ...formData, bankName: '' });
    }
  }, [formData])

  const handleAccountType = (selectedAccountType) => {
    setFormData({ ...formData, accountType: selectedAccountType });
    setAccountTypeDropdownOpen(false);
  };

  return (
    <div className="flex flex-col gap-[32px] w-[35%] pb-[20px]">
      <div className="flex flex-col">
        <span className="h-[32px] w-[47px] bg-white rounded-[35px] flex justify-center items-center">
          4/5
        </span>
        <h3 className="text-[#2F3436] text-[25px] font-extrabold mt-[4px]">
          Application for {type === 'addMD' ? 'MD' : 'AD'}
        </h3>
        <p className="text-[#1D2224] text-[20px] font-semibold">Bank details</p>
      </div>

      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[2px]">
          <label htmlFor="bankName" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Bank Name
          </label>
          <input
            type="text"
            id="bankName"
            placeholder="Enter Bank Name"
            value={formData.bankName}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />
        </div>

        <div className="flex flex-col gap-[2px]">
          <label
            htmlFor="accountNumber"
            className="text-[#1D2224] text-[13px] font-normal uppercase"
          >
            Account Number
          </label>
          <input
            type="number"
            id="accountNumber"
            placeholder="Enter Account Number"
            value={formData.accountNumber}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />
        </div>

        <div className="flex flex-col gap-[2px]">
          <label
            htmlFor="routingNumber"
            className="text-[#1D2224] text-[13px] font-normal uppercase"
          >
            Routing Number
          </label>
          <input
            type="number"
            id="routingNumber"
            placeholder="Enter Routing Number"
            value={formData.routingNumber}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />
        </div>

        <div
          className="flex flex-col gap-[2px] relative cursor-default"
          ref={accountTypeDropdownRef}
        >
          <label htmlFor="accountType" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Account Type
          </label>
          <div
            className="h-[48px] w-full p-[12px] bg-white rounded-[12px] flex justify-between items-center"
            onClick={() => setAccountTypeDropdownOpen(!accountTypeDropdownOpen)}
          >
            <p
              className={`text-[#1D2224] text-[16px] capitalize ${formData.accountType ? '' : 'text-[#999C9C]'}`}
            >
              {formData.accountType ? formData.accountType : 'Please Select'}
            </p>
            <MdKeyboardArrowDown
              className={`h-[20px] w-[20px] transition-all ${accountTypeDropdownOpen ? 'rotate-180' : ''}`}
            />
          </div>

          {accountTypeDropdownOpen && (
            <div
              className="absolute top-[77px] h-[104px] w-full p-[4px] box-border bg-[#FDFDFD] rounded-[12px] shadow-lg z-10 overflow-y-auto"
              ref={accountTypeDropdownChildRef}
            >
              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleAccountType('Personal Account')}
              >
                <input
                  type="radio"
                  name="accountType"
                  id="personalAccount"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.accountType === 'Personal Account'}
                />
                <label htmlFor="personalAccount" className="text-[#1D2224] font-normal">
                  Personal Account
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleAccountType('Savings Account')}
              >
                <input
                  type="radio"
                  name="accountType"
                  id="savingsAccount"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.accountType === 'Savings Account'}
                />
                <label htmlFor="savingsAccount" className="text-[#1D2224] font-normal">
                  Savings Account
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleAccountType('Business Account')}
              >
                <input
                  type="radio"
                  name="accountType"
                  id="businessAccount"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.accountType === 'Business Account'}
                />
                <label htmlFor="businessAccount" className="text-[#1D2224] font-normal">
                  Business Account
                </label>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-[2px]">
          <label
            htmlFor="accountHolderName"
            className="text-[#1D2224] text-[13px] font-normal uppercase"
          >
            Account Holder Number
          </label>
          <input
            type="text"
            id="accountHolderName"
            placeholder="Enter Account Holder Name"
            value={formData.accountHolderName}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />
        </div>
      </div>

      <div className="flex justify-center items-center gap-[8px]">
        <button
          className="flex justify-center items-center h-[48px] w-[50%] rounded-[12px] bg-[#DDDEDE] text-[#1D2224] font-semibold"
          onClick={() => setSelectedTab(2)}
        >
          Previous
        </button>
        <button
          className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[50%] text-white font-semibold rounded-xl disabled:cursor-not-allowed"
          disabled={tabValidation < 4}
          onClick={() => setSelectedTab(4)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BankDetails;
