import React from 'react';
import { MdOutlineKeyboardBackspace , MdDone } from 'react-icons/md';
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';

function MessageWrapper({ heading, subheading, isError,isbackButtonShow }) {
  const navigate = useNavigate()
  return (
    <div className="w-screen h-screen relative  bg-[#FDFDFD] font-jakarta flex justify-center items-center">
      {/* back button  */}
     {isbackButtonShow && <div
        className="fixed top-153px left-[200px] w-[84px] h-[48px] flex justify-center items-center gap-1 cursor-pointer"
        onClick={()=>  navigate('/dashboard')}>
        <MdOutlineKeyboardBackspace className="w-4 h-4" />
        <p className="font-bold text-[#000000]">Back</p>
      </div> } 
      <div
        className={`w-[480px] h-full flex flex-col gap-6 justify-center ${isError ? 'items-center' : 'items-start'}`}>
        <div
          className={`w-[80px] h-[80px] flex items-center justify-center rounded-[52px] ${isError ? 'bg-[#FBDEDF]' : 'bg-[#C9F8D7]'}`}>
          {isError ? <RxCross2  className='text-[#F83A5D] w-12 h-12' /> : < MdDone className='text-[#16B646] w-12 h-12' />}
        </div>
        <div className="flex flex-col gap-2">
          <h3 className={`font-extrabold ${isError ? 'text-center' : ''} text-[39px] leading-[50.7px] -tracking-[0.5%] text-black`}>
            {heading}
          </h3>
          <p className={`text-base ${isError ? 'text-center' : ''} leading-6 font-normal text-[#6C6F71]`}>{subheading}</p>
        </div>
      </div>
    </div>
  );
}

export default MessageWrapper;
