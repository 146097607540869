import React, { useState } from 'react';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { MdOutlineKeyboardArrowDown, MdClose } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import UserService from '../services/user';
import Utils from '../utility';

export const RejectModal = ({ onClose, formData }) => {
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [reasonMessage, setReasonMessage] = useState('');
  const navigate = useNavigate();
  const params = useParams();

  const [availableReasons] = useState([
    'Identity details',
    'Address details',
    'File upload',
    'Bank Details',
    'Declaration',
  ]);

  const handleSelectChange = (e) => {
    const selectedOption = e.target.value;
    if (!selectedReasons.includes(selectedOption)) {
      setSelectedReasons([...selectedReasons, selectedOption]);
    }
  };

  const removeReason = (reason) => {
    setSelectedReasons(selectedReasons.filter((item) => item !== reason));
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleReviewStatus = async (selectedStatus) => {
    if (selectedStatus === 'Rejected') {
      try {
        const payload = {
          userId: params.id,
          status: 'Rejected',
          rejectReason: selectedReasons,
          comment: reasonMessage,
          permanentReject: false,
        };
        const res = await new UserService().reviewDisitributorStatus(payload);
        if(res){Utils.successToastMessage('Status updated successfully')}
             onClose();
              navigate('/dashboard');
      } catch (error) {
        console.error(error);
        Utils.failureToastMessage(error?.response?.data?.responseData?.message);
        onClose();
      }
    } else {
      try {
        const payload = {
          userId: params.id,
          status: selectedStatus,
          rejectReason: selectedReasons,
          comment: reasonMessage,
          permanentReject: true,
        };
        const res = await new UserService().reviewDisitributorStatus(payload);
       if(res){Utils.successToastMessage('Status updated successfully');}
   onClose();
    navigate('/dashboard');
      } catch (error) {
        console.error(error);
        Utils.failureToastMessage(error?.response?.data?.responseData?.message);
        onClose();
      }
    }
  };

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-[12px] p-10 max-h-95per h-[600px] w-[700px] relative overflow-y-auto hide-scrollbar"
        onClick={handleModalClick}
      >
        <button onClick={onClose} className="absolute top-4 right-4 text-lg font-bold">
          <MdClose size={24} />
        </button>
        <div className="w-[320px] mx-auto flex flex-col gap-2">
          <IoIosInformationCircleOutline size={36} className="text-[#E32028]" />
          <span className="text-[#E32028] text-[20px] font-semibold">
            Invite again or reject permanently
          </span>
          <p className="text-[16px] text-[#3F4345] leading-6">
            You can invite the user again so they can change the details that need to resolve.
          </p>
          <div className="flex flex-col gap-[2px]">
            <span className="text-[#1D2224] text-[14px] font-normal uppercase">
              Select rejecting reason
            </span>
            <div className="relative">
              <div className="flex flex-wrap items-center border border-[#DDDEDE] p-[8px] rounded-[8px] bg-white min-h-[48px] relative mt-1">
                <select
                  className="text-[#1D2224] text-[16px] border-none outline-none bg-transparent cursor-pointer appearance-none w-full"
                  onChange={handleSelectChange}
                  value=""
                >
                  <option value="" disabled>
                    Select a reason
                  </option>
                  {availableReasons.map((reason, index) => (
                    <option key={index} value={reason}>
                      {reason}
                    </option>
                  ))}
                </select>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <MdOutlineKeyboardArrowDown size={24} />
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-2 mt-1">
            {selectedReasons.map((reason, index) => (
              <div
                key={index}
                className="bg-[#DDDEDE] text-[#1D2224] text-[16px] font-normal px-3 py-1 rounded-[8px] flex items-center gap-2"
              >
                <span>{reason}</span>
                <button
                  className="text-[#1D2224] text-[12px] font-bold"
                  onClick={() => removeReason(reason)}
                >
                  <MdClose size={20} />
                </button>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-[2px] mt-4">
            <span className="text-[#1D2224] text-[14px] font-normal uppercase">Add Comments</span>
            <textarea
              className="bg-[#F1F2F2] p-[8px] rounded-[8px] text-[16px] font-normal resize-none"
              rows="3"
              value={reasonMessage}
              onChange={(e) => setReasonMessage(e.target.value)}
            ></textarea>
          </div>
          <button
            className="bg-[#0EA8DE] text-[#FDFDFD] rounded-[12px] px-3 py-2 w-full mt-4"
            onClick={() => handleReviewStatus('Rejected')}
          >
            Invite for changes
          </button>
          <button
            className="bg-[#FBDEDF] text-[#E32028] rounded-[12px] px-3 py-2 w-full mt-2"
            onClick={() => handleReviewStatus('Rejected')}
          >
            Permanently Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export const ApproveModal = ({ onClose, formData, setSelectedAdminSubmenu, setSelectedDistributor }) => {
  const navigate = useNavigate();

  const handleADManagement = () => {
    navigate('/dashboard');
    setSelectedAdminSubmenu(formData?.roleId?.name === "Master Distributor" ? "MD Management" : "AD Management");
  }

  const handleDistributorProfile = () => {
    handleADManagement();
    const distributorDetails = {name: formData?.name, email: formData?.email, invitedBy: formData?.invitedBy, role: formData?.roleId, status: formData?.status, _id: formData?._id}
    setSelectedDistributor(distributorDetails);
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center" onClick={onClose}>
      <div className="w-[320px] mx-auto flex flex-col gap-2">
        <FaRegCircleCheck size={32} className="text-[#0F9E73]" />
        <span className="text-[#0F9E73] text-[20px] leading-7 font-semibold">
          Approved successfully
        </span>
        <p className="text-[#3F4345] text-[16px]">{formData?.email} has been approved as {formData?.roleId?.name === "Master Distributor" ? "MD" : "AD"}.</p>
        <button className="bg-[#DDDEDE] text-[#1D2224] rounded-[12px] px-3 py-2 w-full mt-4 font-semibold" onClick={handleDistributorProfile}>
          Distributor profile
        </button>
        <button className="bg-[#0EA8DE] text-[#FDFDFD] rounded-[12px] px-3 py-2 w-full mt-4" onClick={handleADManagement}>
          Go to {formData?.roleId?.name === "Master Distributor" ? "MD" : "AD"} Management
        </button>
      </div>
    </div>
  )
};
export default ApproveModal;
