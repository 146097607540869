import React, { useState } from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { RejectModal } from './Modals';

const Declaration = ({ setSelectedTab, setTabValidation, formData, handleReviewStatus }) => {
  const [showRejectModal, setShowRejectModal] = useState(false);
  const dateSignedDate =
    new Date(formData?.dateSigned).getDate() > 9
      ? new Date(formData?.dateSigned).getDate()
      : `0${new Date(formData?.dateSigned).getDate()}`;
  const dateSignedMonth =
    new Date(formData?.dateSigned).getMonth() > 8
      ? new Date(formData?.dateSigned).getMonth() + 1
      : `0${new Date(formData?.dateSigned).getMonth() + 1}`;
  const dateSignedYear = new Date(formData?.dateSigned).getFullYear();

  return (
    <div className="flex flex-col gap-[32px] w-full">
      <div className="flex flex-col">
        <span className="py-1 px-3 w-[57px] text-[16px] leading-6 bg-[#FDFDFD] text-[#6C6F71] rounded-[35px] flex justify-center items-center">
        5 / 5
        </span>
        <h3 className="text-[#2F3436] text-[25px] leadig-[35px] font-extrabold mt-[4px]">Application for {formData?.roleId?.name === "Master Distributor" ? "MD" : "AD"}</h3>
        <p className="text-[#1D2224] text-[20px] leading-7 font-semibold">Declaration</p>
      </div>

      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">
            Name of the Applicant
          </span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.signatureName}</p>
        </div>
        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">
            Signature of the applicant
          </span>
          <p className="text-[#1D2224] text-[16px] font-semibold">Link</p>
        </div>
        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">Date Signed</span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{`${dateSignedDate}/${dateSignedMonth}/${dateSignedYear}`}</p>
        </div>
        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">
            Accepted Terms & Conditions
          </span>
          <p className="text-[#1D2224] text-[16px] font-semibold">
            {formData?.isTermsAccepted ? 'Yes' : 'No'}
          </p>
        </div>
        <div className="flex justify-between mt-5">
          <button
            className="p-3 bg-[#DDDEDE] rounded-full  text-[#1D2224] cursor-pointer"
            onClick={() => setSelectedTab(3)}
          >
            {' '}
            <MdOutlineKeyboardArrowLeft size={24} />
          </button>
          <button className="p-3 bg-[#DDDEDE] rounded-full  text-[#1D2224] cursor-pointer" disabled>
            {' '}
            <MdOutlineKeyboardArrowRight size={24} className="opacity-30" />
          </button>
        </div>

        <div className="flex justify-between mt-5">
          <button
            className="bg-[#FBDEDF] text-[#E32028] rounded-[12px] px-3 py-2 w-[188px] h-[48px]"
            onClick={() => setShowRejectModal(true)}
          >
            Reject
          </button>
          <button
            className="bg-[#0EA8DE] text-[#FDFDFD] rounded-[12px] px-3 py-2 w-[188px] h-[48px]"
            onClick={() => handleReviewStatus('Active')}
          >
            Approve
          </button>
        </div>
      </div>

      {showRejectModal && <RejectModal onClose={() => setShowRejectModal(false)} />}
    </div>
  );
};

export default Declaration;
