import HTTPService from '../utility/httpService';
import {
  // httpConstants,
  apiEndPointConstants,
  // keyConstants
} from '../constants';
import Cookies from '../managers/sessionManager';
const { ORDERS, CREATE_CHECKOUT_SESSION, WALLET } = apiEndPointConstants;

export default class WalletService {
  constructor(token) {
    this.httpService = new HTTPService(process.env.REACT_APP_WALLET_MICROSERVICE);
    this.config = {
      headers: {
        Authorization: `Bearer ${token !== undefined ? token : new Cookies().get('ACCESS_TOKEN')}`,
      },
    };
  }

  getOrders() {
    return this.httpService.get(ORDERS, {}, this.config);
  }
  createCheckoutSession(payload) {
    return this.httpService.post(CREATE_CHECKOUT_SESSION, payload, this.config);
  }
  getUserWallet(userID) {
    return this.httpService.get(`${WALLET}/${userID}`, {}, this.config);
  }
}
