import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import Cookies from '../managers/sessionManager';
import Utils from '../utility';
import { updateUser } from '../slices/userSlice';
import UserService from '../services/user';
import { jwtDecode } from 'jwt-decode';
import { TbLoader3 } from 'react-icons/tb';
import CenterWrapper from '../common/CenterWrapper';
import GlobalHeader from '../common/GlobalHeader';
import axios from 'axios';
import InformationWrapper from '../common/InformationWrapper';
import MessageWrapper from '../common/MessageWrapper';
import SuccessMessage from '../message-pages/success-message';

function InitialSignup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [validationStatus, setValidationStatus] = useState('');

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      setLoading(true);
      const decoded = jwtDecode(credentialResponse?.credential);

      // Store the token in localStorage or cookies
      localStorage.setItem('ACCESS_TOKEN', credentialResponse?.credential);
      new Cookies().set('ACCESS_TOKEN', credentialResponse?.credential);

      const userResponse = await new UserService().getUserByEmail(decoded?.email);
      const userId = userResponse?._id;
      const updateUserData = {
        name: decoded?.name,
        firstName: decoded?.given_name,
        lastName: decoded?.family_name,
        emailVerified: decoded?.email_verified,
        // status: "Active",
      };

      await new UserService().updateUser(userId, updateUserData);

      // Update Redux state with the user data
      dispatch(updateUser({ ...decoded, ...userResponse, isLoggedIn: true }));
      console.log('userResponse', userResponse);
      // if (!userResponse?.tokenAccepted) {
      //   setValidationStatus('token');
      // } else if (!userResponse.isApprove) {
      //   //if user is not approved yet
      //   setValidationStatus('approve');
      // } else if (!userResponse.isInformationSubmitted) {
      //   //if user is approved but onboarding information is not submitted yet
      //   navigate('/new-onboarding');
      // } else 

      // if user try to signup directly without invitation link
    navigate('/dashboard'); // if user is accepted token,isApprove,isInformationSubmiitted then go to dashboard
    } catch (error) {
      Utils.failureToastMessage(error?.response?.data?.responseData?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLoginError = () => {
    console.error('Login Failed');
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError,
  });

  return (
    <>
 
        <div div className="w-full min-h-screen flex flex-col bg-[#FDFDFD] font-jakarta">
          <GlobalHeader />
          <CenterWrapper>
            {
              <div className="max-w-[480px] max-h-[182px] w-full h-full flex flex-col justify-center items-center gap-8">
                <h3 className="text-[39px] mt-[58px] font-extrabold font-jakarta text-center leading-[50.7px] -tracking-[0.5%]">
                  Sign up as authorized distributor (AD)
                </h3>

                {loading ? (
                  <div className="flex items-center mr-auto">
                    <TbLoader3 className="animate-spin text-black h-13 w-13" />
                  </div>
                ) : (
                  // <div
                  //   onClick={login}
                  //   className="flex items-center gap-2 py-3 pl-3 pr-11 w-[339px] h-[48px] bg-[#1D2224]  text-[#FDFDFD] rounded-[44px] hover:bg-black cursor-pointer">
                  //   <img src="/google-icon.svg" alt="Google Logo" className="w-6 h-6" />
                  //   <p className="text-base font-semibold leading-6 font-jakarta text-center text-[#FDFDFD] w-[251px] h-[24px]">
                  //     Sign in with Google
                  //   </p>
                  // </div>
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                )}
              </div>
            }
          </CenterWrapper>
        </div>
    
      {/* {validationStatus === 'token' && (
        <MessageWrapper
          heading={'The invitation is not Accepted.'}
          subheading={'Please accept our invitation first'}
          isError={true}
        />
      )}
      {validationStatus === 'approve' && <SuccessMessage />} */}
    </>
  );
}

export default InitialSignup;
