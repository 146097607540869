import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from '../managers/sessionManager';
import CryptoJS from 'crypto-js';
import { failureMessageConstant, formattedDateTypes, keyConstants } from '../constants';
import { jwtDecode } from 'jwt-decode';
import { sideBarPaths } from '../constants/index';

const { ACCESS_TOKEN } = keyConstants;
const { NUMERIC, SHORT, DIGIT_TYPE } = formattedDateTypes;

export default class Utils {
  /**
   * Get item from localStorage
   * @param {string} key - The key to identify the item in localStorage
   * @returns {any|null} Parsed JSON data from localStorage, or null if the key does not exist
   */
  static localStorageGetItem = (key) => {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    return null;
  };

  /**
   * Set item in localStorage
   * @param {string} key - The key to identify the item in localStorage
   * @param {any} data - Data to be stored in localStorage (will be JSON.stringify-ed)
   */
  static localStorageSetItem = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  /**
   * Remove item from localStorage
   * @param {string} key - The key to identify the item in localStorage
   */
  static localStorageRemoveItem = (key) => {
    localStorage.removeItem(key);
  };

  /**
   * Clears the local storage.
   *
   * @return {void}
   */
  static localStorageClear() {
    localStorage.clear();
  }

  /**
   * This function is made to handle success and error callback!
   * @param {Promise} promise - The promise to be handled
   * @returns {Promise<Array>} A promise that resolves to an array with either [null, data] on success or [err] on failure
   */
  static async parseResponse(promise) {
    return promise.then((data) => [null, data]).catch((err) => [err]);
  }

  /**
   * Generate a GUID (Globally Unique Identifier)
   * @returns {string} A string representing a GUID
   */
  static guid() {
    const s4 = () =>
      Math.floor(Math.random() * 65536)
        .toString(16)
        .padStart(4, '0');

    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      '4' +
      s4().substring(1) +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }
  /**
   * Generates a success toast message with the given message and theme.
   *
   * @param {string} message - The message to display in the toast.
   * @param {Object} theme - The theme configuration for the toast. (optional)
   * @param {string} theme.position - The position of the toast. (optional)
   * @param {number} theme.autoClose - The duration in milliseconds for the toast to automatically close. (optional)
   * @param {boolean} theme.hideProgressBar - Whether to hide the progress bar of the toast. (optional)
   * @param {boolean} theme.closeOnClick - Whether to close the toast when it is clicked. (optional)
   * @param {boolean} theme.pauseOnHover - Whether to pause the auto-close timer when the mouse hovers over the toast. (optional)
   * @param {boolean} theme.draggable - Whether the toast can be dragged. (optional)
   * @param {string} theme.theme - The theme style for the toast. (optional)
   * @param {undefined} theme.progress - The progress value for the toast. (optional)
   * @return {void} This function does not return anything.
   */
  static successToastMessage(message) {
    toast.dismiss();
    toast.success(message, {
      position: 'top-center',
      autoClose: 3000,
      toastId: 'successToast',
    });
  }
  /**
   * Generates a failure toast message with the given message and theme.
   *
   * @param {string} message - The message to display in the toast.
   * @param {object} theme - The theme configuration for the toast. (optional)
   * @param {string} theme.position - The position of the toast. (optional)
   * @param {number} theme.autoClose - The duration in milliseconds before the toast automatically closes. (optional)
   * @param {boolean} theme.hideProgressBar - Determines whether to hide the progress bar. (optional)
   * @param {boolean} theme.closeOnClick - Determines whether to close the toast when clicked. (optional)
   * @param {boolean} theme.pauseOnHover - Determines whether to pause the toast on hover. (optional)
   * @param {boolean} theme.draggable - Determines whether the toast can be dragged. (optional)
   * @param {string} theme.theme - The theme style for the toast. (optional)
   * @param {undefined} theme.progress - The progress configuration for the toast. (optional)
   * @return {void} Returns nothing.
   */
  static failureToastMessage(message) {
    toast.dismiss();
    toast.error(message, {
      position: 'top-center',
      autoClose: 3000,
      toastId: 'failureToast',
    });
  }

  static getHeader(token) {
    return {
      headers: {
        Authorization: `Bearer ${token !== undefined ? token : new Cookies().get(ACCESS_TOKEN)}`,
      },
    };
  }
  static dateFormat(date) {
    return new Date(date).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  static handleNumericInput(event) {
    // Allow only numeric values (0-9) and certain control keys
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  static formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const hourMinute = new Intl.DateTimeFormat('en-US', {
      hour: DIGIT_TYPE,
      minute: DIGIT_TYPE,
      hour12: true,
    }).format(date);
    const day = new Intl.DateTimeFormat('en-US', { day: NUMERIC }).format(date);
    const month = new Intl.DateTimeFormat('en-US', { month: SHORT }).format(date);
    const year = new Intl.DateTimeFormat('en-US', { year: NUMERIC }).format(date);
    return `${hourMinute}, ${day} ${month} ${year}`;
  }
  static truncateWalletAddress(address) {
    const start = address.slice(0, 29);
    const end = address.slice(-4);
    return start + '...' + end;
  }

  static truncateString(string, count = 10) {
    const start = string.slice(0, count);
    const end = string.slice(-4);
    return start + '...' + end;
  }

  static isVideoURL(url) {
    // Example: Check if the URL ends with a video extension
    return /\.(mp4|mov|avi|mkv|webm)$/i.test(url);
  }

  static formatCounter = (count) => {
    return count < 10 ? `0${count}` : count;
  };

  // Encryption function
  static encryptPassword = (password) => {
    const encrypted = CryptoJS.AES.encrypt(
      password, // actual password
      process.env.REACT_APP_SECRET_KEY // secret key
    ).toString();
    return encrypted;
  };

  // Decryption function
  static decryptPassword = (encryptedPassword) => {
    const decrypted = CryptoJS.AES.decrypt(
      encryptedPassword, // encrypted password
      process.env.REACT_APP_SECRET_KEY // secret key
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  static checkTokenExpireOrNot() {
    const token = new Cookies().get(ACCESS_TOKEN);
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        this.failureToastMessage(failureMessageConstant.SESSION_EXPIRED_LOGIN_AGAIN);
        return true;
      }
      return false;
    }
    this.failureToastMessage(failureMessageConstant.SESSION_EXPIRED_LOGIN_AGAIN);
    return true;
  }

  static filterSideBarPaths = async (userRole) => {
    switch (userRole) {
      case 'Admin':
        return sideBarPaths;
      case 'Master Distributor':
        return sideBarPaths.filter((path) => path.name === 'Users' || path.name === 'Products');
      case 'Authorized Distributor':
        return sideBarPaths.filter(
          (path) =>
            path.name === 'Products' ||
            path.name === 'Subscriber Management' ||
            path.name === 'Transactions'
        );
      case 'User':
        return sideBarPaths.filter((path) => path.name === 'Products');
      default:
        return [];
    }
  };
}
