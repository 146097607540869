import React, { useEffect, useRef, useState } from 'react';
import CenterWrapper from '../common/CenterWrapper';
import { MdKeyboardArrowDown } from 'react-icons/md';
import InformationWrapper from '../common/InformationWrapper';

function PaymentDetails({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) {
  return (
    <>
      <CenterWrapper>
        <div className=" w-full flex flex-col gap-8">
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-extrabold text-[39px]  leading-[50.7px] -tracking-[0.5%] text-[#1D2224]">
              Add payment information
            </h3>
            <p className="text-base leading-6 font-normal text-[#6C6F71]">
              Provide payment information to get access all primary features and withdraw earnings
            </p>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <InformationWrapper text={'Bank account'} icon={'/Bank.svg'} alt={'bank icon'} />
            <InformationWrapper text={'Wire Transfer'} icon={'/move_down.svg'} alt={'bank icon'} />
            <InformationWrapper text={'Connect Zelle'} icon={'/zelle.svg'} alt={'bank icon'} />
          </div>
          <div className="flex gap-8 justify-between items-center">
            <button
              onClick={() => setSelectedTab((prev) => prev + 1)}
              className="flex grow justify-center items-center py-3 bg-[#39B8E4] rounded-3xl">
              <p className="font-bold text-base leading-6">Add later</p>
            </button>
            <button
                // disabled={tabValidation < 4}
              onClick={() => setSelectedTab((prev) => prev + 1)}
              className="flex grow justify-center items-center rounded-3xl py-3 bg-[#39B8E4]">
              <p className="font-bold text-base leading-6">Continue</p>
            </button>
          </div>
        </div>
      </CenterWrapper>
    </>
  );
}

export default PaymentDetails;
