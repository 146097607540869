import React, { useRef } from 'react';
import { IoMdCloudUpload } from 'react-icons/io';
import { FaFile } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import UserService from '../services/user';
import { useSelector } from 'react-redux';

const FileUpload = ({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) => {
  const identityProofFileRef = useRef(null);
  const addressProofFileRef = useRef(null);
  const user = useSelector((state) => state.user);

  const handleFileUpload = async (e, fileUploadType) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const reqformData = new FormData();
    reqformData.append('fileName', file?.name);
    reqformData.append('files', file);
    reqformData.append('userType', user?.roleId?.name === 'Admin' ? 'MD' : 'AD');

    try {
      const res = await new UserService().fileUpload(reqformData);
      if (fileUploadType === 'IdentityProof') {
        setFormData({
          ...formData,
          proofOfIdentity: {
            ...formData.proofOfIdentity,
            name: file?.name,
            documentLink: res?.cdnUrl,
            size: file?.size,
          },
        });
      } else {
        setFormData({
          ...formData,
          proofOfAddress: {
            ...formData.proofOfAddress,
            name: file?.name,
            documentLink: res?.cdnUrl,
            size: file?.size,
          },
        });
      }
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const handleRemoveFile = (fileUploadType) => {
    if (fileUploadType === 'AddressProof') {
      setFormData({
        ...formData,
        proofOfAddress: { ...formData?.proofOfAddress, name: '', documentLink: '', size: '' },
      });
    } else {
      setFormData({
        ...formData,
        proofOfIdentity: { ...formData?.proofOfIdentity, name: '', documentLink: '', size: '' },
      });
    }
  };

  return (
    <div className="flex flex-col gap-[32px] w-[35%]">
      <div className="flex flex-col">
        <span className="h-[32px] w-[47px] bg-white rounded-[35px] flex justify-center items-center">
          3/5
        </span>
        <h3 className="text-[#2F3436] text-[25px] font-extrabold mt-[4px]">
          Application for {type === 'addMD' ? 'MD' : 'AD'}
        </h3>
        <p className="text-[#1D2224] text-[20px] font-semibold">File Upload</p>
      </div>

      <div className="flex flex-col gap-[8px]">
        <p className="text-[#6C6F71] text-[16px] font-normal">
          Please upload Identity Proof related documents.
        </p>

        <div
          className="min-h-[150px] h-[30vh] w-full bg-[#FDFDFD] border border-solid border-[#DDDEDE] rounded-[12px] flex flex-col justify-center items-center cursor-default"
          onClick={() => identityProofFileRef.current.click()}
        >
          <IoMdCloudUpload className="h-[40px] w-[40px]" />
          <input
            type="file"
            className="hidden"
            ref={identityProofFileRef}
            onChange={(e) => handleFileUpload(e, 'IdentityProof')}
          />
          <h4 className="text-[#2F3436] text-[20px] font-semibold">Browse files</h4>
          <p className="text-[#999C9C] text-[16px] font-normal">Drag and drop files here</p>
        </div>

        {formData?.proofOfIdentity?.size && (
          <div className="h-[61px] w-full px-[12px] bg-[#FDFDFD] rounded-[12px] flex justify-between items-center">
            <div className="flex items-center gap-[12px]">
              <FaFile className="h-[20px] w-[20px] text-[#999C9C]" />
              <div className="flex flex-col">
                <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.proofOfIdentity?.name}</p>
                <p className="text-[#999C9C] text-[14px]">
                  {Math.ceil(formData.proofOfIdentity.size / 1000)} KB
                </p>
              </div>
            </div>

            <MdClose
              className="h-[20px] w-[20px] text-[#1D2224] cursor-pointer"
              onClick={() => handleRemoveFile('IdentityProof')}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col gap-[8px] mt-[10px]">
        <p className="text-[#6C6F71] text-[16px] font-normal">
          Please upload Address Proof related documents.
        </p>

        <div
          className="min-h-[150px] h-[30vh] w-full bg-[#FDFDFD] border border-solid border-[#DDDEDE] rounded-[12px] flex flex-col justify-center items-center cursor-default"
          onClick={() => addressProofFileRef.current.click()}
        >
          <IoMdCloudUpload className="h-[40px] w-[40px]" />
          <input
            type="file"
            className="hidden"
            ref={addressProofFileRef}
            onChange={(e) => handleFileUpload(e, 'AddressProof')}
          />
          <h4 className="text-[#2F3436] text-[20px] font-semibold">Browse files</h4>
          <p className="text-[#999C9C] text-[16px] font-normal">Drag and drop files here</p>
        </div>

        {formData?.proofOfAddress?.size && (
          <div className="h-[61px] w-full px-[12px] bg-[#FDFDFD] rounded-[12px] flex justify-between items-center">
            <div className="flex items-center gap-[12px]">
              <FaFile className="h-[20px] w-[20px] text-[#999C9C]" />
              <div className="flex flex-col">
                <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.proofOfAddress?.name}</p>
                <p className="text-[#999C9C] text-[14px]">
                  {Math.ceil(formData.proofOfAddress.size / 1000)} KB
                </p>
              </div>
            </div>

            <MdClose
              className="h-[20px] w-[20px] text-[#1D2224] cursor-pointer"
              onClick={() => handleRemoveFile('AddressProof')}
            />
          </div>
        )}
      </div>

      <div className="flex justify-center items-center gap-[8px]">
        <button
          className="flex justify-center items-center h-[48px] w-[50%] rounded-[12px] bg-[#DDDEDE] text-[#1D2224] font-semibold"
          onClick={() => setSelectedTab(1)}
        >
          Previous
        </button>
        <button
          className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[50%] text-white font-semibold rounded-xl disabled:cursor-not-allowed"
          disabled={tabValidation < 3}
          onClick={() => setSelectedTab(3)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FileUpload;
