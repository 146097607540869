import React, { useEffect, useState } from 'react';
import AddProductModal from '../popups/addProduct';
import WalletService from '../services/wallet';
import { useSelector } from 'react-redux';
import { TbLoader3 } from 'react-icons/tb';

const ProductInfo = () => {
  const [products, setProducts] = useState([]);
  const [wirelessPlanType, setWirelessPlanType] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProductsData();
  }, [wirelessPlanType]);

  const fetchProductsData = async () => {
    try {
      setLoading(true);
      const response = await new WalletService().getProductsData();
      setLoading(false);
      let updatedProductsData = [];

      if (wirelessPlanType === 0) {
        updatedProductsData = response.filter((item) => item.teaserType === 'Custom Overage');
      } else {
        updatedProductsData = response.filter((item) => item.teaserType !== 'Custom Overage');
      }
      setProducts(updatedProductsData);
      setSelectedPlan(null);
    } catch (error) {
      console.error('Error while fetching products data', error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col h-screen font-jakarta">
      <div className="p-8 flex-1 bg-[#F1F2F2]">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-3xl font-bold">Product Catalogue</h2>
            <span className="text-gray-600">Create, manage and update products</span>
          </div>
          {user?.roleId?.name === 'Admin' && (
            <button
              onClick={handleOpenModal}
              className="bg-black text-white px-5 py-2 h-12 rounded-[24px]"
            >
              + Create Product
            </button>
          )}
        </div>

        <div className="mb-4 flex items-center">
          <span className="font-bold text-lg mr-4">Wireless Plans</span>
          <div className="flex gap-4">
            <button
              className={`px-4 py-2 rounded ${
                wirelessPlanType === 0 ? 'bg-black text-white' : 'text-black'
              }`}
              onClick={() => setWirelessPlanType(0)}
            >
              Tether
            </button>
            <button
              className={`px-4 py-2 rounded ${
                wirelessPlanType === 1 ? 'bg-black text-white' : 'text-black'
              }`}
              onClick={() => setWirelessPlanType(1)}
            >
              No Tether
            </button>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-60per w-full">
            <TbLoader3 className="animate-spin text-black h-13 w-13" />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
            {products.map((product, index) => (
              <div
                key={product._id}
                className={`p-4 w-[280px] h-[228px] rounded-[24px] border-[4px] cursor-pointer ${
                  selectedPlan === index
                    ? 'border-[#97DAF1] bg-[#E7F6FC]'
                    : 'border-transparent bg-[#F1F2F2]'
                }`}
                onClick={() => setSelectedPlan(index === selectedPlan ? null : index)}
              >
                <h3 className="font-extrabold text-ft6 ">{product?.displayName}</h3>
                <p className="text-ft2 text-[#999C9C] py-2">{product?.offerName}</p>
                {/* <p className="text-gray-600 py-2">AD Price {product.amount}</p> */}
                <p className="text-ft2 py-2 w-60per text-[#999C9C]">
                  {product.deviceType}, {product.teaserType}, {product.size}, {product.serviceType}
                </p>
                <p className="font-extrabold ">${product.amount}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {isModalOpen && <AddProductModal open={isModalOpen} onClose={handleCloseModal} />}
    </div>
  );
};

export default ProductInfo;
