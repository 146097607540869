import React, { useState, useEffect } from 'react';
import { TbLoader3, TbCheck } from 'react-icons/tb';
import LoadingScreen from '../common/loadingScreen';
import WalletService from '../services/wallet';
import { useSelector } from 'react-redux';
import history from '../managers/history';
import { toast } from 'react-toastify';
const AddSubscriberModal = ({ onClose, isOpen }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [portingType, setPortingType] = useState('existing');
  const [contactNumberError, setContactNumberError] = useState(null);
  const [imeiError, setImeiError] = useState(null);
  const [subscriberDetails, setSubscriberDetails] = useState({
    firstName: '',
    lastName: '',
    streetNumber: '',
    streetDirection: '',
    streetName: '',
    city: '',
    state: '',
    zipCode: '',
    email: '',
    contactNumber: '',
  });
  const [imei, setImei] = useState('');
  const [iccid, setIccid] = useState('');
  const [plans, setPlans] = useState([]);
  const [sortedPlans, setSortedPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [subscriber, setSubscriber] = useState('');
  const [products, setProducts] = useState('');
  const [msisdn, setMsisdn] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const user = useSelector((state) => state?.user);
  const [wirelessPlanType, setWirelessPlanType] = useState(0);
  const validateImei = (imei) => {
    const imeiRegex = /^\d{15}$/;
    return imeiRegex.test(imei);
  };

  // Call getCRMProducts when the component mounts
  useEffect(() => {
    getCRMProducts();
    handlePlansSorting(0);
  }, []);

  const getCRMProducts = async () => {
    try {
      const response = await new WalletService().getProducts();
      setProducts(response);
    } catch (error) {
      console.error('Error fetching CRM products:', error);
      setError('Error fetching CRM products.');
    }
  };

  const handleNext = async () => {
    if (currentStep === 2) {
      const errors = validateSubscriberDetails();
      if (Object.keys(errors).length === 0) {
        setLoading(true);
        try {
          const response = await new WalletService().registerSubscriber({
            ...subscriberDetails,
            mvnoId: 11,
            addedBy: user?._id,
          });
          setSubscriber(response);
          setCurrentStep(3);
        } catch (error) {
          console.error('Error adding subscriber:', error.message);
          if (error.message) {
            toast.error(error.message);
          } else {
            toast.error('An unexpected error occurred. Please try again.');
          }
        } finally {
          setLoading(false);
        }
      } else {
        setFormErrors(errors);
      }
    } else if (currentStep === 3) {
      if (!validateImei(imei)) {
        setImeiError('IMEI must be a 15-digit number.');
      } else {
        setImeiError(null);
        setLoading(true);
        try {
          const response = await new WalletService().getAvailablePlans({
            imei,
            mvnoId: 11,
          });

          setPlans(response);
          setSelectedPlan(null);
          // fetchPlans();
          setCurrentStep(4);
        } catch (error) {
          console.error('Error activating subscriber:', error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      setCurrentStep((prev) => (prev < 5 ? prev + 1 : prev));
    }
  };

  const startSubscriberActivation = async () => {
    setLoading(true);
    if (portingType === 'existing') {
      startPortInActivation();
      return;
    }
    if (!selectedPlan) {
      console.error('No plan selected.');
      setLoading(false);
      return;
    }

    const simCardPurchaseProduct = products.find((product) => product.name === 'SIM Card Purchase');
    const customerActivationProduct = products.find(
      (product) => product.name === 'Customer Activation'
    );
    const wirelessServicesProduct = products.find(
      (product) => product.name === 'Wireless Services'
    );

    if (!simCardPurchaseProduct || !customerActivationProduct || !wirelessServicesProduct) {
      console.error('Required CRM products not found.');
      setLoading(false);
      return;
    }

    const requestData = {
      imei: imei,
      iccid: iccid,
      carrier: 'AT&T',
      product: {
        deviceType: selectedPlan?.deviceType,
        productFamily: selectedPlan?.productFamily,
        size: selectedPlan?.size,
        serviceType: selectedPlan?.serviceType,
        offerId: selectedPlan?.offerId,
        offerName: selectedPlan?.offerName,
        soc: selectedPlan?.soc,
      },
      mid: subscriber.mid,
      subscriberId: subscriber?._id,
      adUserId: user?._id,
      simCardPurchaseProductId: simCardPurchaseProduct?._id,
      customerActivationProductId: customerActivationProduct?._id,
      wirelessServicesProductId: wirelessServicesProduct?._id,
      planId: selectedPlan?._id,
      isNewSubscriber: true,
      isPortIn: false,
    };

    try {
      const response = await new WalletService().startPlanActivation(requestData);

      const activateRequestData = {
        accountId: response?.accountId,
        tempSubscriptionId: response?.tempSubscriptionId,
        subscriptionId: response?.subscriberId,
      };

      const result = await activateSubscriberPlan(activateRequestData);
      setLoading(false);
      if (result.success) {
        setActivationSuccess(true);
        history.push('/dashboard');
      }
      return true;
    } catch (error) {
      console.error('Error starting subscriber activation:', error);
      setLoading(false);
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      return error;
    }
  };

  const activateSubscriberPlan = async (requestData) => {
    try {
      await new WalletService().activateSubscriberPlan(requestData);
    } catch (error) {
      console.error('Error activating plan', error);
      setLoading(false);
      return error;
    }
  };

  const portInCheck = async () => {
    try {
      setLoading(true);
      const requestData = {
        msisdn: msisdn,
        zipCode: zipCode,
      };
      const response = await new WalletService().portInCheck(requestData);
      await setLoading(false);
      // handleNext();
      if (response.message) {
        setErrorMessage(null);
        setSuccessMessage(response.message);
      }
    } catch (error) {
      setLoading(false);
      setSuccessMessage(null);
      setErrorMessage('Error checking eligibility');
      console.error('Error checking eligibility', error);
    }
  };

  const startPortInActivation = async () => {
    if (!selectedPlan) {
      console.error('No plan selected.');
      setLoading(false);
      return;
    }
    const simCardPurchaseProduct = products.find((product) => product.name === 'SIM Card Purchase');
    const customerActivationProduct = products.find(
      (product) => product.name === 'Customer Activation'
    );
    const wirelessServicesProduct = products.find(
      (product) => product.name === 'Wireless Services'
    );

    if (!simCardPurchaseProduct || !customerActivationProduct || !wirelessServicesProduct) {
      console.error('Required CRM products not found.');
      setLoading(false);
      return;
    }

    const requestData = {
      oldService: {
        billingAccountNumber: '287337652357',
        billingAccountPassword: '5277',
        firstName: 'beesu',
        lastName: 'reddy',
      },
      accountDetails: {
        firstName: subscriberDetails.firstName,
        lastName: subscriberDetails.lastName,
        streetNumber: subscriberDetails.streetNumber,
        streetDirection: subscriberDetails.streetDirection,
        streetName: subscriberDetails.streetName,
        city: subscriberDetails.city,
        state: subscriberDetails.state,
        zipCode: subscriberDetails.zipCode,
        email: subscriberDetails.email,
        contactNumber: subscriberDetails.contactNumber,
        mvnoId: 11,
      },
      activationDetails: {
        imei: imei,
        iccid: iccid,
        carrier: 'AT&T',
        product: {
          deviceType: selectedPlan?.deviceType,
          offerId: selectedPlan?.offerId,
          offerName: selectedPlan?.offerName,
          productFamily: selectedPlan?.productFamily,
          soc: selectedPlan?.soc,
          size: selectedPlan?.size,
          serviceType: selectedPlan?.serviceType,
          retailName: selectedPlan?.retailName || '',
          retailPrice: selectedPlan?.retailPrice || '0.0',
        },
      },
      msisdn: subscriber?.mid,
      subscriberId: subscriber?._id,
      adUserId: user?._id,
      simCardPurchaseProductId: simCardPurchaseProduct?._id,
      customerActivationProductId: customerActivationProduct?._id,
      wirelessServicesProductId: wirelessServicesProduct?._id,
      planId: selectedPlan?._id,
      isNewSubscriber: false,
      isPortIn: true,
    };

    try {
      const response = await new WalletService().portInStart(requestData);
      const activateRequestData = {
        accountId: response?.accountId,
        tempPortInId: '370',
        subscriptionId: response?.subscriberId,
        oldService: {
          billingAccountNumber: '287337652357',
          billingAccountPassword: '5277',
          firstName: 'beesu',
          lastName: 'reddy',
        },
        msisdn: response?.msisdn,
      };
      if (response) {
        await portInMake(activateRequestData);
        setLoading(false);
        setActivationSuccess(true);
      }

      return;
    } catch (error) {
      console.error('Error starting port-in activation:', error);
      setLoading(false);
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      return error;
    }
  };

  const portInMake = async (requestData) => {
    try {
      await new WalletService().portInMake(requestData);
    } catch (error) {
      console.error('Error activating port in', error);
      setLoading(false);
      return error;
    }
  };

  const handlePrev = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const fetchPlans = async () => {
    setLoading(true);
    try {
      // const response = await axios.get("https://d3jlqgqxlcr546.cloudfront.net/plans");
      const response = new WalletService().getPlans();
      const { responseData } = response.data;
      setPlans(responseData);
    } catch (error) {
      console.error('Error fetching plans:', error);
    } finally {
      setLoading(false);
    }
  };

  const validateSubscriberDetails = () => {
    const errors = {};
    for (let key in subscriberDetails) {
      if (subscriberDetails[key].trim() === '' && key !== 'streetDirection') {
        errors[key] = `Please enter ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}.`;
      }
    }
    if (!/^\d{1,15}$/.test(subscriberDetails.contactNumber.trim())) {
      errors['contactNumber'] = 'Contact number should be valid number.';
    }
    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'contactNumber') {
      if (/^\d*$/.test(value)) {
        setSubscriberDetails({ ...subscriberDetails, [name]: value });
        setFormErrors({ ...formErrors, [name]: '' });
        setContactNumberError(null);
      } else {
        setContactNumberError('Please enter only numeric characters.'); // Display error for non-numeric input
      }
    } else {
      setSubscriberDetails({ ...subscriberDetails, [name]: value });
      setFormErrors({ ...formErrors, [name]: '' }); // Clear error message on input change
    }
  };

  const isActive = (step) => {
    return step === currentStep;
  };

  const isStepCompleted = (step) => {
    return step < currentStep;
  };

  const handlePlansSorting = (index) => {
    let updatedPlansData = [];
    if (index === 0) {
      updatedPlansData = plans.filter((item) => item.teaserType === 'Custom Overage');
    } else {
      updatedPlansData = plans.filter((item) => item.teaserType !== 'Custom Overage');
    }

    setSortedPlans(updatedPlansData);
    setWirelessPlanType(index);
    setSelectedPlan(null);
  };

  const tabList = [
    'Porting',
    'Add Subscriber',
    'Activate Subscriber',
    'Add Wireless Plan',
    'Activate Iccid',
    // "Checkout"
  ];

  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[900px] h-95per overflow-y-auto hide-scrollbar rounded-[24px] shadow-lg p-7">
        <div className="flex justify-end mb-4">
          <img onClick={onClose} className="h-6 w-6 cursor-pointer" src="./close.svg" alt="Logo" />
        </div>

        <div className="relative mb-4">
          <div className="flex gap-4 justify-center overflow-x-auto">
            {tabList.map((tab, index) => (
              <div key={index} className="flex flex-col items-center">
                <button
                  className={`text-ft4  flex items-center py-2 px-3 font-medium ${
                    isActive(index + 1)
                      ? 'font-bold text-[#1D2224]'
                      : isStepCompleted(index + 1)
                        ? 'text-green-500'
                        : 'text-[#5A5E5F]'
                  } `}
                  disabled={index + 1 > currentStep}
                  onClick={() => setCurrentStep(index + 1)}
                >
                  {isStepCompleted(index + 1) && (
                    <img src="./check_circle.svg" alt="Completed" className="w-4 h-4 mr-2" />
                  )}
                  {tab}
                </button>
                {isActive(index + 1) && (
                  <div
                    style={{
                      height: '4px',
                      width: '100%',
                      backgroundColor: 'black',
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        {currentStep === 1 && (
          <div className="flex flex-col w-[351px] mx-auto mt-4 ">
            <div className="mt-1 mb-4 items-start flex flex-col  ">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">1/5</span>
              <h2 className="text-xl font-bold">Porting in?</h2>
            </div>
            <div className="flex bg-[#F1F2F2] rounded-[27px] p-1 items-center mb-4">
              <button
                className={`py-2 px-4 ${
                  portingType === 'existing' ? 'bg-black rounded-[27px] text-white' : ''
                }`}
                onClick={() => setPortingType('existing')}
              >
                Existing Sim Card
              </button>
              <button
                className={`py-2 px-4 ml-2 ${
                  portingType === 'new' ? 'bg-[#1D2224] rounded-[27px] text-white' : ''
                }`}
                onClick={() => setPortingType('new')}
              >
                New Sim Card
              </button>
            </div>
            {portingType === 'existing' && (
              <div>
                <div className="text-[#1D2224] font-semibold text-ft21 mb-6">Eligibility Check</div>
                <div className="mb-4">
                  <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                    Port In Number
                  </label>
                  <input
                    type="text"
                    className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                    placeholder="Enter port in number"
                    value={msisdn}
                    onChange={(e) => setMsisdn(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="w-full outline-none placeholder:text-[#999C9C] text-ft4 bg-[#F1F2F2] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                    placeholder="Enter zip code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                </div>
                {errorMessage && (
                  <div className="text-red-500 text-center mb-4">{errorMessage}</div>
                )}
                {successMessage && (
                  <div className="text-green-500 text-center mb-4">{successMessage}</div>
                )}
                <div className="mt-10 flex gap-4 justify-center">
                  <button
                    onClick={onClose}
                    className=" text-[#1D2224] py-2 px-12 rounded-xl hover:bg-gray-5"
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#0EA8DE] h-[48px] text-white py-2 px-4 rounded-xl"
                    onClick={() => (successMessage ? handleNext() : portInCheck())}
                    disabled={!msisdn || !zipCode}
                  >
                    {loading ? (
                      <TbLoader3 className="animate-spin w-5 h-5 text-white" />
                    ) : successMessage ? (
                      'Next'
                    ) : (
                      'Check Eligibility'
                    )}
                  </button>
                </div>
              </div>
            )}
            {portingType === 'new' && (
              <div className="mt-10 flex gap-4 justify-center">
                <button
                  onClick={handlePrev}
                  className=" text-[#1D2224] py-2 px-12 rounded-xl hover:bg-gray-5"
                >
                  Cancel
                </button>
                <button
                  onClick={handleNext}
                  className="bg-[#0EA8DE] hover:bg-opacity-85 h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )}

        {currentStep === 2 && (
          <div className="flex flex-col w-[351px] mx-auto mt-4">
            <div className="mt-2 mb-4">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">2/5</span>
              <h2 className="text-[#1D2224] font-bold text-ft21 ">Subscriber Details</h2>
            </div>
            <div className="pr-3">
              {[
                'firstName',
                'lastName',
                'streetNumber',
                'streetDirection',
                'streetName',
                'city',
                'state',
                'zipCode',
                'email',
                'contactNumber',
              ].map((field, index) => (
                <div className="mb-4" key={index}>
                  <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                    {field.replace(/([A-Z])/g, ' $1').toUpperCase()}
                  </label>
                  <input
                    type="text"
                    name={field}
                    value={subscriberDetails[field]}
                    onChange={handleInputChange}
                    className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                    placeholder={`Subscriber's ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
                  />
                  {formErrors[field] && (
                    <p className="text-red-500 text-sm mt-1">{formErrors[field]}</p>
                  )}
                </div>
              ))}
            </div>
            <div className="mt-4 flex gap-4 justify-center relative right-1">
              <button
                onClick={handlePrev}
                className=" text-[#1D2224] py-2 px-12 rounded-xl hover:bg-gray-5"
              >
                Cancel
              </button>
              <button
                onClick={handleNext}
                className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
                disabled={loading}
              >
                {loading ? <TbLoader3 className="animate-spin w-5 h-5 text-white" /> : 'Next'}
              </button>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div className="flex flex-col w-[351px] mx-auto">
            <div className="mt-2 mb-4">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">3/5</span>
              <h2 className="text-[#1D2224] font-bold text-ft21">Activate Subscriber</h2>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">IMEI</label>
              <input
                type="text"
                value={imei}
                onChange={(e) => {
                  setImei(e.target.value);
                  setImeiError(null);
                }}
                className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Enter subscriber's IMEI"
              />
              {imeiError && <p className="text-red-500 text-sm mt-1">{imeiError}</p>}
            </div>
            <div className="mt-6 flex gap-4 justify-center">
              <button
                onClick={handlePrev}
                className=" text-[#1D2224] py-2 px-12 rounded-xl hover:bg-gray-5"
              >
                Cancel
              </button>
              <button
                onClick={handleNext}
                className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
                disabled={loading}
              >
                {loading ? <TbLoader3 className="animate-spin w-5 h-5 text-white" /> : 'Next'}
              </button>
            </div>
          </div>
        )}

        {currentStep === 4 && (
          <div className="flex flex-col gap-[10px] mx-auto w-full max-w-[700px]">
            <div className="mt-2 mb-4">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">4/5</span>
              <div className="flex justify-between items-center gap-10">
                <h2 className="text-[#1D2224] font-bold text-ft21">Add Wireless Plan</h2>
                <div className="flex items-center gap-[8px] h-[48px] w-[248px] p-[4px] box-border bg-[#F1F2F2] rounded-[24px] relative bottom-[14px]">
                  <button
                    className={`w-[116px] px-4 py-2 rounded-[24px] ${
                      wirelessPlanType === 0 ? 'bg-black text-white' : 'text-black'
                    }`}
                    onClick={() => handlePlansSorting(0)}
                  >
                    Tether
                  </button>
                  <button
                    className={`w-[116px] px-4 py-2 rounded-[27px] ${
                      wirelessPlanType === 1 ? 'bg-black text-white' : 'text-black'
                    }`}
                    onClick={() => handlePlansSorting(1)}
                  >
                    No Tether
                  </button>
                </div>
              </div>
            </div>
            {loading ? (
              <LoadingScreen />
            ) : sortedPlans.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-4">
                {sortedPlans.map((plan, index) => (
                  <div
                    key={index}
                    className={`p-4 w-[212.67px] rounded-[24px] border-[4px] cursor-pointer h-full ${
                      selectedPlan?._id === plan?._id
                        ? 'border-[#97DAF1] bg-[#E7F6FC]'
                        : 'border-transparent bg-[#F1F2F2]'
                    }`}
                    onClick={() => setSelectedPlan(plan)}
                  >
                    <h3 className="font-extrabold pb-2">{plan?.displayName}</h3>
                    <p className="text-sm pb-2 text-[#999C9C]">{plan?.offerName}</p>
                    <p className="text-sm pb-2 text-[#999C9C]">
                      {plan?.deviceType},{plan?.size}, {plan?.serviceType}
                    </p>
                    <p className="font-extrabold">${plan?.amount}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-red-500">No plans available.</p>
            )}
            <div className="flex gap-4 justify-center">
              <button
                onClick={handlePrev}
                className=" text-[#1D2224] py-2 px-12 rounded-xl hover:bg-gray-5"
              >
                Cancel
              </button>
              <button
                onClick={handleNext}
                className={`bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center  h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl`}
                disabled={loading}
              >
                {loading ? <TbLoader3 className="animate-spin w-5 h-5 text-white" /> : 'Next'}
              </button>
            </div>
          </div>
        )}

        {currentStep === 5 && (
          <div className="flex flex-col w-[351px] mx-auto">
            <div className="mt-2 mb-4">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">5/5</span>
              <h2 className="text-[#1D2224] font-bold text-ft21">Activate Iccid</h2>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 uppercase">Iccid</label>
              <input
                type="text"
                value={iccid}
                className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Enter iccid"
                onChange={(e) => setIccid(e.target.value)}
              />
            </div>
            <div className="mt-4 flex gap-4 justify-center">
              <button
                onClick={handlePrev}
                className=" text-[#1D2224] py-2 px-12 rounded-xl hover:bg-gray-5"
              >
                Cancel
              </button>
              <button
                onClick={startSubscriberActivation}
                className={`flex justify-center items-center h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl ${
                  activationSuccess ? 'bg-green-300' : 'bg-[#0EA8DE]'
                }`}
                disabled={loading || activationSuccess}
              >
                {loading ? (
                  <TbLoader3 className="animate-spin w-5 h-5 text-white" />
                ) : activationSuccess ? (
                  <>
                    {' '}
                    <TbCheck className="w-5 h-5 text-white" />
                    <span> Done </span>
                  </>
                ) : (
                  'Activate'
                )}
              </button>
            </div>
          </div>
        )}

        {/* {currentStep === 6 && (
          <div className="flex flex-col w-[686px] mx-auto justify-center">
            <div className="mb-4">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">5/5</span>
              <h2 className="text-[#1D2224] font-bold text-ft21">Checkout</h2>
            </div>
            <div className="mt-10 flex gap-4 justify-center">
              <button onClick={handlePrev} className=" text-[#1D2224] py-2 px-8 rounded-xl hover:bg-gray-5">
                Cancel
              </button>
              <button
                onClick={() => setError("Checkout not implemented")}
                className="bg-[#0EA8DE] h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
              >
                Checkout
              </button>
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AddSubscriberModal;
