import React, { useState, useRef, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import UserService from '../services/user';
import { MdDelete } from 'react-icons/md';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../slices/userSlice';
import { toast } from 'react-toastify';
import { TbLoader3 } from 'react-icons/tb';

const Declaration = ({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  phoneNumberInput,
}) => {
  const [imageURL, setImageURL] = useState(formData?.signature ? formData?.signature : null);
  const [showSignaturePlaceholder, setShowSignaturePlaceholder] = useState(true);
  const sigCanvas = useRef({});
  const fileUploadRef = useRef(null);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const dateObject = new Date(formData?.dateSigned);
  const formattedDateSignedDate = `${dateObject.getUTCFullYear()}-${String(dateObject.getUTCMonth() + 1).padStart(2, '0')}-${String(dateObject.getUTCDate()).padStart(2, '0')}`;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currentDate = new Date().getDate();
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const today = `${currentYear}-${currentMonth > 8 ? currentMonth + 1 : `0${currentMonth + 1}`
      }-${currentDate > 9 ? currentDate : `0${currentDate}`}`;
    setFormData({ ...formData, dateSigned: today });
  }, []);

  const handleSaveCanvas = () => {
    const signatureDataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setImageURL(signatureDataUrl);
    setFormData({ ...formData, signature: signatureDataUrl });
  };

  const handleDeleteSignature = () => {
    setShowSignaturePlaceholder(true);
    setImageURL(null);
    setFormData({ ...formData, signature: null });
  };

  const handleUploadSignature = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const reqFormData = new FormData();
    reqFormData.append('fileName', file?.name);
    reqFormData.append('files', file);
    reqFormData.append('userType', user?.roleId?.name === 'Admin' ? 'MD' : 'AD');

    try {
      const res = await new UserService().fileUpload(reqFormData);
      setFormData({ ...formData, signature: res?.cdnUrl });
      setImageURL(res?.cdnUrl);
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const updatedFormData = {
      ...formData,
      userId: user._id,
      fatherName: formData.fatherFirstName + ' ' + formData.fatherLastName,
      signatureName: formData.signatureFirstName + ' ' + formData.signatureLastName,
      address: {
        zipCode: formData.postalCode,
        country: formData.country,
      },

      // phoneCountryCode: parsePhoneNumber(phoneNumberInput)?.countryCallingCode,
      // phoneNumber: parsePhoneNumber(phoneNumberInput)?.nationalNumber,
    };
    if (user?.roleId?.name === 'Authorized Distributor') {
      updatedFormData.authorizedDistributorId = user?.roleId?._id;
    } else {
      updatedFormData.masterDistributorId = user?.roleId?._id;
    }

    try {
      const response = await new UserService().updateFirstTimeProfile(updatedFormData);
      setLoading(false);
      const updatedUserData = { ...updatedFormData, ...response };
      if (response) {
        dispatch(updateUser(updatedUserData));
        toast.success('Details Submitted Successfully', 4000);
        setSelectedTab(5);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-[32px] w-[35%]">
      <div className="flex flex-col">
        <span className="h-[32px] w-[47px] bg-white rounded-[35px] flex justify-center items-center">
          5/5
        </span>
        <h3 className="text-[#2F3436] text-[25px] font-extrabold mt-[4px]">
          Application for {type === 'addMD' ? 'MD' : 'AD'}
        </h3>
        <p className="text-[#1D2224] text-[20px] font-semibold">Declaration</p>
      </div>

      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[2px]">
          <label
            htmlFor="signatureFirstName"
            className="text-[#1D2224] text-[13px] font-normal uppercase"
          >
            Name of the Applicant
          </label>
          <div className="flex items-center gap-[8px]">
            <input
              type="text"
              id="signatureFirstName"
              placeholder="First name"
              value={formData.signatureFirstName}
              onChange={handleChange}
              className="h-[48px] w-[50%] p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
            />
            <input
              type="text"
              id="signatureLastName"
              placeholder="Last name"
              value={formData.signatureLastName}
              onChange={handleChange}
              className="h-[48px] w-[50%] p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
            />
          </div>
        </div>

        <div className="flex flex-col gap-[2px]">
          <label htmlFor="signature" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Signature of the applicant
          </label>

          <div className="flex items-center gap-[10px] relative">
            {!imageURL && (
              <div className="relative bg-[#FDFDFD] w-full rounded-[12px]">
                {showSignaturePlaceholder && (
                  <div className="flex flex-col justify-center items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <img
                      src="./signature-icon.svg"
                      alt="Signature-Icon"
                      className="h-[40px] w-[40px]"
                    />
                    <p className="text-[#999C9C] text-[16px]">Sign Here</p>
                  </div>
                )}
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{ className: 'signatureCanvas' }}
                  onBegin={() => setShowSignaturePlaceholder(false)}
                  onEnd={handleSaveCanvas}
                />
              </div>
            )}

            {imageURL && (
              <div className="relative bg-[#FDFDFD] min-h-[150px] h-[25vh] w-full rounded-[12px] border border-solid border-[#DDDEDE] flex justify-center items-center">
                <img src={imageURL} alt="Signature" className="h-[80%]" />
              </div>
            )}

            {imageURL && (
              <MdDelete
                className="absolute right-[-30px] cursor-pointer h-[20px] w-[20px] text-[#6c6f71]"
                onClick={handleDeleteSignature}
              />
            )}
          </div>

          <div
            className="h-[48px] w-full mt-[6px] bg-[#FDFDFD] border border-solid border-[#DDDEDE] rounded-[8px] flex justify-center items-center cursor-default"
            onClick={() => fileUploadRef.current.click()}
          >
            Or, Upload signature
          </div>
          <input
            type="file"
            className="hidden"
            ref={fileUploadRef}
            onChange={handleUploadSignature}
          />
        </div>

        <div className="flex flex-col gap-[2px]">
          <label htmlFor="dateSigned" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Date Signed
          </label>
          <input
            type="date"
            id="dateSigned"
            placeholder="DD-MM-YYYY"
            value={formattedDateSignedDate}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />
        </div>

        <div className="flex flex-col gap-[2px]">
          <div className="flex items-center gap-[4px]">
            <input
              type="checkbox"
              id="declaration"
              defaultChecked={formData.isTermsAccepted}
              onChange={() =>
                setFormData({
                  ...formData,
                  isTermsAccepted: !formData.isTermsAccepted,
                })
              }
              className="h-[15px] w-[15px]"
            />
            <label htmlFor="declaration">
              I accept all the <span className="font-bold">terms of service</span> and{' '}
              <span className="font-bold">privacy policy</span>.
            </label>
          </div>

          <div className="flex items-center relative left-[19px]">
            <p className="text-[#6C6F71] text-[14px] h-[21px] w-[245px] text-ellipsis overflow-hidden">
              I hereby declare that the information provided in this form is accurate and complete.
              I confirm that any information is found incorrect and/or incomplete that leads a
              violation of regulations may initiate legal actions, and I accept that I am the
              responsible party for any and all charges, penalties and violations.
            </p>
            <p className="text-[#0EA8DE] text-[14px] font-medium">Show more</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center gap-[8px]">
        <button
          className="flex justify-center items-center h-[48px] w-full rounded-[12px] bg-[#DDDEDE] text-[#1D2224] font-semibold"
          onClick={() => setSelectedTab(2)}
        >
          Previous
        </button>

        <button
          className="bg-[#0EA8DE] text-white text-[14px] font-semibold h-[50px] w-full rounded-[12px] cursor-pointer flex justify-center items-center"
          onClick={handleSubmit}
        >
          {loading ? <TbLoader3 className="animate-spin w-7 h-7 text-white" /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default Declaration;
