import React, { useState } from 'react';
import Sidebar from './sidebar';
import DashboardComponent from './dashboard';
import Header from './header';
import WalletService from '../services/wallet';
import { useSelector } from 'react-redux';

const Dashboard = () => {
    const user = useSelector((state) => state.user);
    const [selectedSidebarTab, setSelectedSidebarTab] = useState(0);
    const [dropdownQuery, setDropdownQuery] = useState('MDN');
    const [searchInput, setSearchInput] = useState("");
    const [paginationQuery, setPaginationQuery] = useState({ currentPage: 1, rowsPerPage: 10, totalRows: 6 });
    const [latestSubscribersData, setLatestSubscribersData] = useState([]);

    const fetchLatestSubscribersData = async () => {
        try {
            const queryParams = `?skip=0&limit=20&${dropdownQuery}=${searchInput}`;
            const response = await new WalletService().getActiveSubscribersData(user?._id, queryParams);
            if (response) {
                setLatestSubscribersData(response?.subscribersList);
                setPaginationQuery({...paginationQuery, totalRows: response?.totalCount});
            }
        } catch (error) {
            console.error('Error fetching latest subscribers data:', error);
        }
    };

    return (
        <div className='min-h-screen w-full bg-[#E9F9FF] flex justify-end font-jakarta'>
            <Sidebar selectedSidebarTab={selectedSidebarTab} setSelectedSidebarTab={setSelectedSidebarTab} />

            <Header dropdownQuery={dropdownQuery} setDropdownQuery={setDropdownQuery} searchInput={searchInput} setSearchInput={setSearchInput} fetchLatestSubscribersData={fetchLatestSubscribersData} />

            <div className='w-[78%] mt-[84px] py-[24px] px-[4%] flex flex-col gap-[24px]'>
                {selectedSidebarTab === 0 && <DashboardComponent dropdownQuery={dropdownQuery} searchInput={searchInput} latestSubscribersData={latestSubscribersData} fetchLatestSubscribersData={fetchLatestSubscribersData} paginationQuery={paginationQuery} setPaginationQuery={setPaginationQuery} />}
            </div>
        </div>
    )
}

export default Dashboard;