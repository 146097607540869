import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const IdentityDetails = ({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState({
    genderDropdown: false,
    identityProofDropdown: false,
    nationalityDropdown: false,
  });
  const genderDropdownRef = useRef(null);
  const genderDropdownChildRef = useRef(null);
  const identityProofDropdownRef = useRef(null);
  const identityProofDropdownChildRef = useRef(null);
  const nationalityDropdownRef = useRef(null);
  const nationalityDropdownChildRef = useRef(null);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const dateObject = new Date(formData?.dob);
  const formattedDobDate = `${dateObject.getUTCFullYear()}-${String(dateObject.getUTCMonth() + 1).padStart(2, '0')}-${String(dateObject.getUTCDate()).padStart(2, '0')}`;

  useEffect(() => {
    let handler = (e) => {
      if (genderDropdownChildRef.current && !genderDropdownRef.current.contains(e.target)) {
        setDropdownOpen({ ...dropdownOpen, genderDropdown: false });
      }
      if (
        identityProofDropdownChildRef.current &&
        !identityProofDropdownRef.current.contains(e.target)
      ) {
        setDropdownOpen({ ...dropdownOpen, identityProofDropdown: false });
      }
      if (
        nationalityDropdownChildRef.current &&
        !nationalityDropdownRef.current.contains(e.target)
      ) {
        setDropdownOpen({ ...dropdownOpen, nationalityDropdown: false });
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const handleGender = (selectedGender) => {
    setFormData({ ...formData, gender: selectedGender });
    setDropdownOpen({ ...dropdownOpen, genderDropdown: false });
  };

  const handleIdentityProof = (selectedIdentityProof) => {
    setFormData({
      ...formData,
      proofOfIdentity: { ...formData.proofOfIdentity, type: selectedIdentityProof },
    });
    setDropdownOpen({ ...dropdownOpen, identityProofDropdown: false });
  };

  return (
    <div className="flex flex-col gap-[32px] w-[35%]">
      <div className="flex flex-col">
        <span className="h-[32px] w-[47px] bg-white rounded-[35px] flex justify-center items-center">
          1/5
        </span>
        <h3 className="text-[#2F3436] text-[25px] font-extrabold mt-[4px]">
          Application for {type === 'addMD' ? 'MD' : 'AD'}
        </h3>
        <p className="text-[#1D2224] text-[20px] font-semibold">Applicant's identity details</p>
      </div>

      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[2px]">
          <label htmlFor="firstName" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Name of the Applicant
          </label>
          <div className="flex items-center gap-[8px]">
            <input
              type="text"
              id="firstName"
              placeholder="First name"
              value={formData.firstName}
              onChange={handleChange}
              className="h-[48px] w-[50%] p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
            />
            <input
              type="text"
              id="lastName"
              placeholder="Last name"
              value={formData.lastName}
              onChange={handleChange}
              className="h-[48px] w-[50%] p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
            />
          </div>
        </div>

        <div className="flex flex-col gap-[2px]">
          <label
            htmlFor="fatherFirstName"
            className="text-[#1D2224] text-[13px] font-normal uppercase"
          >
            Father's Name
          </label>
          <div className="flex items-center gap-[8px]">
            <input
              type="text"
              id="fatherFirstName"
              placeholder="First name"
              value={formData.fatherFirstName}
              onChange={handleChange}
              className="h-[48px] w-[50%] p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
            />
            <input
              type="text"
              id="fatherLastName"
              placeholder="Last name"
              value={formData.fatherLastName}
              onChange={handleChange}
              className="h-[48px] w-[50%] p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
            />
          </div>
        </div>

        <div className="flex flex-col gap-[2px] relative cursor-default" ref={genderDropdownRef}>
          <label htmlFor="gender" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Gender
          </label>
          <div
            className="h-[48px] w-full p-[12px] bg-white rounded-[12px] flex justify-between items-center"
            onClick={() =>
              setDropdownOpen({ ...dropdownOpen, genderDropdown: !dropdownOpen.genderDropdown })
            }
          >
            <p className="text-[#1D2224] text-[16px] capitalize">{formData.gender}</p>
            <MdKeyboardArrowDown
              className={`h-[20px] w-[20px] transition-all ${dropdownOpen.genderDropdown ? 'rotate-180' : ''}`}
            />
          </div>

          {dropdownOpen.genderDropdown && (
            <div
              className="absolute top-[77px] h-[104px] w-full p-[4px] box-border bg-[#FDFDFD] rounded-[12px] shadow-lg z-10"
              ref={genderDropdownChildRef}
            >
              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleGender('Male')}
              >
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.gender === 'Male'}
                />
                <label htmlFor="male" className="text-[#1D2224] font-normal">
                  Male
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleGender('Female')}
              >
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.gender === 'Female'}
                />
                <label htmlFor="female" className="text-[#1D2224] font-normal">
                  Female
                </label>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-[2px]">
          <label htmlFor="dob" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Date of Birth
          </label>
          <input
            type="date"
            id="dob"
            placeholder="DD-MM-YYYY"
            value={formattedDobDate}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />
        </div>

        <div className="flex flex-col gap-[2px]">
          <label htmlFor="identityNo" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Pan
          </label>
          <input
            type="text"
            id="identityNo"
            placeholder="Enter PAN"
            value={formData.identityNo}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />
        </div>

        <div
          className="flex flex-col gap-[2px] relative cursor-default"
          ref={identityProofDropdownRef}
        >
          <label
            htmlFor="identityProof"
            className="text-[#1D2224] text-[13px] font-normal uppercase"
          >
            Proof of Identity Submitted for PAN Exempt Cases
          </label>
          <div
            className="h-[48px] w-full p-[12px] bg-white rounded-[12px] flex justify-between items-center"
            onClick={() =>
              setDropdownOpen({
                ...dropdownOpen,
                identityProofDropdown: !dropdownOpen.identityProofDropdown,
              })
            }
          >
            <p
              className={`text-[#1D2224] text-[16px] capitalize ${formData.proofOfIdentity.type ? '' : 'text-[#999C9C]'}`}
            >
              {formData.proofOfIdentity.type
                ? formData.proofOfIdentity.type
                : 'Please Select'}
            </p>
            <MdKeyboardArrowDown
              className={`h-[20px] w-[20px] transition-all ${dropdownOpen.identityProofDropdown ? 'rotate-180' : ''}`}
            />
          </div>

          {dropdownOpen.identityProofDropdown && (
            <div
              className="absolute top-[77px] h-[104px] w-full p-[4px] box-border bg-[#FDFDFD] rounded-[12px] shadow-lg z-10 overflow-y-auto"
              ref={identityProofDropdownChildRef}
            >
              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleIdentityProof('UID')}
              >
                <input
                  type="radio"
                  name="identityProof"
                  id="uid"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfIdentity.name === 'UID'}
                />
                <label htmlFor="uid" className="text-[#1D2224] font-normal">
                  UID
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleIdentityProof('passport')}
              >
                <input
                  type="radio"
                  name="identityProof"
                  id="passport"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfIdentity.name === 'passport'}
                />
                <label htmlFor="passport" className="text-[#1D2224] font-normal">
                  Passport
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleIdentityProof('Voter ID')}
              >
                <input
                  type="radio"
                  name="identityProof"
                  id="voterId"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfIdentity.name === 'Voter ID'}
                />
                <label htmlFor="voterId" className="text-[#1D2224] font-normal">
                  Voter ID
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleIdentityProof('Driving License')}
              >
                <input
                  type="radio"
                  name="identityProof"
                  id="drivingLicense"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfIdentity.name === 'Driving License'}
                />
                <label htmlFor="drivingLicense" className="text-[#1D2224] font-normal">
                  Driving License
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleIdentityProof('other')}
              >
                <input
                  type="radio"
                  name="identityProof"
                  id="other"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfIdentity.name === 'other'}
                />
                <label htmlFor="other" className="text-[#1D2224] font-normal">
                  Other
                </label>
              </div>
            </div>
          )}
        </div>

        <div
          className="flex flex-col gap-[2px] relative cursor-default"
          ref={nationalityDropdownRef}
        >
          <label htmlFor="nationality" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Nationality
          </label>
          <Select
            options={countryOptions}
            value={formData.nationality ? { value: countryList().getValue(formData.nationality)?.[0] || '', label: formData.nationality } : null}
            onChange={(e) => setFormData({ ...formData, nationality: e.label })}
            className="country-input"
          />
        </div>
      </div>

      <div className="flex justify-center items-center gap-[8px]">
        <NavLink
          to="/dashboard"
          className="flex justify-center items-center h-[48px] w-[50%] text-[#1D2224] font-semibold rounded-xl"
        >
          Cancel
        </NavLink>
        <button
          className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[50%] text-white font-semibold rounded-xl disabled:cursor-not-allowed"
          disabled={tabValidation < 1}
          onClick={() => setSelectedTab(1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default IdentityDetails;
