import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/user';
import Utils from '../utility';
import { useDispatch } from 'react-redux';

import Cookies from '../managers/sessionManager';
import { updateUser } from '../slices/userSlice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function AdminLogin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const passwordInputRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (passwordInputRef.current && !passwordInputRef.current.contains(e.target)) {
        setInputFocused(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, []);

  const handleLogin = async (data) => {
    setLoading(true);
    try {
      // Perform sign-in request
      const response = await new UserService().logIn(data);

      // Extract user data and token from the response
      const { user, token } = response;

      // Update Redux state with the user data
      dispatch(updateUser({ ...user, isLoggedIn: true }));

      // Store the token in localStorage or cookies (not shown here)
      localStorage.setItem('ACCESS_TOKEN', token);
      new Cookies().set('ACCESS_TOKEN', token);

      // Redirect to the dashboard
      //  history.push("/dashboard");
      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setLoginError('Incorrect email or password.');
      } else if (error.response && error.response.status === 403) {
        setLoginError('Access not provided.');
      } else {
        Utils.failureToastMessage("Incorrect email or password");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen p-10 flex items-center font-jakarta justify-center bg-[#F1F2F2]">
      <div className="bg-[#F1F2F2] rounded-lg flex justify-center items-center gap-x-[80px] w-full">
        <div className="w-full max-w-max lg:w-1/2 lg:items-center p-8 md:w-[339px] flex flex-col">
          <div className="w-full relative left-[4px]">
            <img src="/tittle-logo.svg" alt="EZ Mobile Logo" className="w-[170px] h-[48px]" />
          </div>
          <h2 className="text-[39px] mt-[48px] font-extrabold md:w-[343px] w-full font-jakarta text-start mb-6">
            Sign-in to CRM Admin Dashboard
          </h2>
          <form onSubmit={handleSubmit(handleLogin)} className="space-y-4">
            <div>
              <label className="block text-[13px] uppercase font-normal">Email</label>
              <input
                type="email"
                className={`mt-1 block w-full bg-[#ffffff] md:w-[339px] px-3 py-2 rounded-[37px] border ${errors.email ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-[#67C8EA] focus:border-[#67C8EA]`}
                {...register('email', { required: true })}
              />
              {errors.email && <p className="mt-1 text-sm text-red-600">Email is required</p>}
            </div>
            <div className="relative">
              <label className="block text-[13px] uppercase font-normal">Password</label>
              <div className='rounded-[37px]' style={inputFocused ? { border: '2.5px solid #67C8EA' } : { border: '2.5px solid transparent' }} ref={passwordInputRef}>
                <div className={`relative block w-full bg-[#FDFDFD] md:w-[339px] px-3 py-2 rounded-[37px] border ${inputFocused ? 'border-[#67C8EA]' : errors.password ? 'border-red-500' : 'border-gray-300'}`}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className='border-none outline-none'
                    style={{ width: 'calc(100% - 48px)' }}
                    {...register('password', { required: true })}
                    onFocus={() => setInputFocused(true)}
                  />

                  <div className="absolute bottom-[2px] right-0 h-[38px] w-[48px] z-10 flex justify-center items-center rounded-r-[37px]">
                    {showPassword ? (
                      <FaEye onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                      <FaEyeSlash onClick={() => setShowPassword(!showPassword)} />
                    )}
                  </div>
                </div>
              </div>
              {errors.password && <p className="mt-1 text-sm text-red-600">Password is required</p>}
            </div>
            {loginError && <p className="mt-1 text-sm text-red-600">{loginError}</p>}
            <div className="flex justify-between items-center">
              <button
                type="submit"
                className="w-full md:w-[339px] bg-black mt-4 text-white py-2 rounded-[44px] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 text-white mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    Signing In...
                  </div>
                ) : (
                  'Sign In'
                )}
              </button>
            </div>
          </form>
          <div className="mt-8 w-full md:w-[339px] text-start">
            <a href="/forgot-password" className="text-[#0C8FBD] hover:underline">
              Forgot Password?
            </a>
          </div>
        </div>

        <div className="hidden max-w-max lg:block lg:w-1/2 bg-cover rounded-r-[48px]">
          <img
            src="/sideframe.png"
            alt="Login-Banner"
            className="h-[500px] w-[500px] rounded-r-[48px] relative bottom-[20px]"
          />
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
