const StepIndicator = ({ currentStep, totalSteps }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '300px',
        height: '20px',
        padding: '8px',
        alignItems: 'center',
        gap: '8px',
      }}>
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          style={{
            width: '50px',
            height: '4px',
            borderRadius: '4px',
            backgroundColor: '#F1F2F2',
            position: 'relative', // To position the inner div
          }}>
          {/* Conditionally render the blue inner div for the current step */}
          {index + 1 === currentStep && (
            <div
              style={{
                width: '8px',
                height: '4px',
                borderRadius: '4px',
                backgroundColor: '#0EA8DE',
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            />
          )}
          {/* Full blue background for completed steps */}
          {index + 1 < currentStep && (
            <div
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '4px',
                backgroundColor: '#0EA8DE',
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default StepIndicator