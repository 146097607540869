import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement } from 'chart.js';
import Pagination from './pagination';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const DashboardComponent = ({ dropdownQuery, searchInput, latestSubscribersData, fetchLatestSubscribersData, paginationQuery, setPaginationQuery }) => {
    const [earningsData, setEarningsData] = useState([
        {
            amount: '103',
            description: 'Sim card',
            percentage: '2.0',
            type: 'increase',
        },
        {
            amount: '152',
            description: 'Activation',
            percentage: '1.7',
            type: 'decrease',
        },
        {
            amount: '68',
            description: 'Payment Fee',
            percentage: '0.6',
            type: 'decrease',
        },
        {
            amount: '496.5',
            description: 'Monthly Subs',
            percentage: '1.0',
            type: 'increase',
        },
    ]);

    const lineChartOptions = {
        scales: {
            x: {
                grid: { display: false },
                ticks: { display: false },
                border: {display: false},
            },
            y: {
                grid: { display: false },
                ticks: { display: false },
                border: {display: false},
            },
        },
        plugins: {
            legend: {
                display: false
            }
        },
        elements: {
            line: {
                borderWidth: 5
            },
            point: {
                radius: 0
            }
        }
    };

    const activeSubscribersData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Sales',
                data: [0, 40, 20, 60, 30, 55],
                fill: false,
                borderColor: '#19C84D',
                tension: 0.4,
            }
        ]
    };

    const totalSubscribersData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Sales',
                data: [0, 80, 20, 70, 30, 90],
                fill: false,
                borderColor: '#6F47FF',
                tension: 0.4,
            }
        ]
    };

    const subscriberRetentionData = {
        labels: ['January', 'February', 'March', 'April'],
        datasets: [
            {
                label: 'Sales',
                data: [80, 50, 60, 0],
                fill: false,
                borderColor: '#F94E6D',
                tension: 0.4,
            }
        ]
    };

    const popularPlansData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'],
        datasets: [
            {
                label: 'Sales',
                data: [90, 80, 60, 50, 40, 30, 20, 10],
                backgroundColor: ['#009DFF', '#BFC1C4', '#BFC1C4', '#BFC1C4', '#BFC1C4', '#BFC1C4', '#BFC1C4', '#BFC1C4'],
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 0,
                borderRadius: 50,
                borderSkipped: false,
            },
        ],
    };

    const popularPlansOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: false,
                text: 'Sales Data (2024)',
            },
        },
        scales: {
            x: {
                display: false,
                beginAtZero: true,
                categoryPercentage: 0.1,
            },
            y: {
                display: false,
                beginAtZero: true,
            },
        },
    };

    const handleExport = () => {
        const headers = ['MDN', 'Plan', 'Creation Date', 'Billing Cycle'];

        const rows = latestSubscribersData.slice(0, 4).map((subscriber) => [
            subscriber.mid,
            subscriber.plan,
            `"${subscriber.creationDate}"`,
            `"${subscriber.billingCycle}"`,
        ]);

        const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'Latest Subscribers.csv');
    };

    useEffect(() => {
        fetchLatestSubscribersData();
    }, [])

    return (
        <div className='flex flex-col gap-[24px]'>
            <div className='flex gap-[24px]'>
                <div className='flex flex-col gap-[24px] w-[65%]'>
                    <div className='h-[225px] w-full bg-[#86E0FF] rounded-[16px] flex justify-between items-center'>
                        <div className='flex flex-col gap-[16px] py-[24px] pl-[24px]'>
                            <h3 className='text-[25px] text-[#1D2224] font-extrabold'>Welcome back Al Mamun!</h3>

                            <div className='flex items-center gap-[16px]'>
                                <div className='flex flex-col gap-[2px]'>
                                    <h4 className='text-[18px] text-[#1D2224] font-bold'>06</h4>
                                    <p className='text-[14px] text-[#45474A] font-normal'>Subscribed today</p>
                                </div>
                                <div className='flex flex-col gap-[2px]'>
                                    <h4 className='text-[18px] text-[#1D2224] font-bold'>$42</h4>
                                    <p className='text-[14px] text-[#45474A] font-normal'>Earned today</p>
                                </div>
                            </div>

                            <div className='h-[28px] min-w-[175px] w-max py-[8px] px-[12px] bg-[#E7F6FC] flex justify-center items-center text-[#0C8FBD] text-[16px] font-normal rounded-[37px]'>+5% than yesterday</div>
                        </div>
                        <img src="./dashboard-welcome-banner.svg" alt="Dashboard-Welcome Banner" className='w-full max-w-[271px]' />
                    </div>

                    <div className='flex gap-[24px]'>
                        <div className='h-[253px] w-[50%] bg-[#C5FFED] rounded-[16px]'>
                            <div className='h-[108px] w-full p-[24px] flex flex-col gap-[4px]'>
                                <div className='flex items-center gap-[4px]'>
                                    <div className='h-[16px] w-[16px] bg-[#FFFFFF] rounded-full flex justify-center items-center'>
                                        <div className='h-[8px] w-[8px] bg-[#08CE91] rounded-full'></div>
                                    </div>
                                    <h4 className='text-[20px] text-[#585B5F] font-semibold'>Active subscribers</h4>
                                </div>

                                <div className='h-[28px] min-w-[85px] w-max py-[4px] px-[8px] bg-[#FFFFFF] flex justify-center items-center text-[#80848A] text-[13px] font-medium rounded-[37px] uppercase'>Year 2024</div>
                            </div>

                            <div className='h-[145px] w-full px-[24px] py-[20px] flex justify-between items-center'>
                                <div className='flex flex-col gap-[2px]'>
                                    <div className='flex items-center gap-[2px]'>
                                        <p className='text-[14px] text-[#1D2224] font-medium'>8%</p>
                                        <img src="./arrow-up-green.svg" alt="Arrow-Up Icon" className='h-[24px] w-[24px]' />
                                    </div>
                                    <h4 className='text-[24px] text-[#1D2224] font-semibold'>2431</h4>
                                </div>

                                <div className='h-[96px] w-[172px]'>
                                    <Line data={activeSubscribersData} options={lineChartOptions} />
                                </div>
                            </div>
                        </div>

                        <div className='h-[253px] w-[50%] bg-[#CDD8FF] rounded-[16px]'>
                            <div className='h-[108px] w-full p-[24px] flex flex-col gap-[4px]'>
                                <div className='flex items-center gap-[4px]'>
                                    <h4 className='text-[20px] text-[#585B5F] font-semibold'>Total subscribers</h4>
                                </div>

                                <div className='h-[28px] min-w-[85px] w-max py-[4px] px-[8px] bg-[#FFFFFF] flex justify-center items-center text-[#80848A] text-[13px] font-medium rounded-[37px] uppercase'>Year 2024</div>
                            </div>

                            <div className='h-[145px] w-full px-[24px] py-[20px] flex justify-between items-center'>
                                <div className='flex flex-col gap-[2px]'>
                                    <div className='flex items-center gap-[2px]'>
                                        <p className='text-[14px] text-[#1D2224] font-medium'>12%</p>
                                        <img src="./arrow-up-green.svg" alt="Arrow-Up Icon" className='h-[24px] w-[24px]' />
                                    </div>
                                    <h4 className='text-[24px] text-[#1D2224] font-semibold'>2817</h4>
                                </div>

                                <div className='h-[96px] w-[172px]'>
                                    <Line data={totalSubscribersData} options={lineChartOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='min-h-[502px] w-[35%] p-[24px] bg-[#FFFFFF] rounded-[16px] flex flex-col gap-[24px]'>
                    <div className='flex justify-between items-center'>
                        <h3 className='text-[20px] text-[#6C6F75] font-semibold'>EZ Earnings</h3>
                        <img src="./three-dots-vertical-icon.svg" alt="Three-Dots Icon" className='h-[24px] w-[24px] cursor-pointer hover:opacity-80' />
                    </div>

                    <div className='flex flex-col gap-[24px]'>
                        <div className='flex justify-between gap-[12px]'>
                            <div className='flex items-center gap-[12px]'>
                                <img src="./earnings-wallet-icon.svg" alt="Wallet-Icon" />
                                <div className='flex flex-col'>
                                    <h4 className='text-[25px] text-[#6C6F75] font-semibold'>$1,132</h4>
                                    <p className='text-[14px] text-[#A0A2A7] font-normal'>Wallet</p>
                                </div>
                            </div>

                            <div className='flex items-center gap-[12px]'>
                                <img src="./earnings-wallet-icon.svg" alt="Wallet-Icon" />
                                <div className='flex flex-col'>
                                    <h4 className='text-[25px] text-[#6C6F75] font-semibold'>$2,212</h4>
                                    <p className='text-[14px] text-[#A0A2A7] font-normal'>Paid</p>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col gap-[12px]'>
                            {earningsData?.map((item, index) => (
                                <div className='flex justify-between items-center gap-[20px]' key={index}>
                                    <div className='flex flex-col'>
                                        <h4 className='text-[20px] text-[#6C6F75] font-semibold'>${item.amount}</h4>
                                        <p className='text-[14px] text-[#A0A2A7] font-normal'>{item.description}</p>
                                    </div>

                                    <div className='flex items-center gap-[2px]'>
                                        <img src={item.type === "increase" ? './arrow-up-green.svg' : './arrow-down-red.svg'} alt="Arrow-Up Icon" className='h-[24px] w-[24px]' />
                                        <p className='text-[14px] text-[#80848A] font-normal'>{item.percentage}%</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='flex justify-between items-center gap-[14px]'>
                        <p className='text-[14px] text-[#A0A2A7] font-normal'>You have done 3.2% more sales. Check all your earning states here.</p>
                        <img src="./chevron-right-icon-blue.svg" alt="Chevron-Right Icon" className='h-[40px] w-[40px] cursor-pointer hover:opacity-90' />
                    </div>
                </div>
            </div>

            <div className='flex gap-[24px] w-full'>
                <div className='h-[512px] w-[65%] bg-[#FFFFFF] rounded-[16px]'>
                    <div className='h-[88px] w-full p-[24px] flex justify-between items-center gap-[20px]'>
                        <h2 className='text-[20px] text-[#6C6F75] font-semibold'>Latest subscribers</h2>
                        <button className='h-[40px] w-[108px] bg-[#E7F6FC] rounded-[24px] flex justify-center items-center gap-[12px] cursor-pointer hover:bg-opacity-90'>
                            <p className='text-[16px] text-[#0EA8DE] font-semibold' onClick={handleExport}>Export</p>
                            <img src="./chevron-down-blue.svg" alt="Chevron-Down Icon" className='h-[16px] w-[16px]' />
                        </button>
                    </div>

                    <div className='flex flex-col items-center h-[374px] px-[8px] overflow-y-auto'>
                        <div className='h-[56px] w-full grid grid-cols-4 border-b border-solid border-[#F4F5F5]'>
                            <div className='text-[12px] text-[#45474A] font-medium p-[16px]'>MDN</div>
                            <div className='text-[12px] text-[#45474A] font-medium p-[16px]'>PLAN</div>
                            <div className='text-[12px] text-[#45474A] font-medium p-[16px]'>CREATION DATE</div>
                            <div className='text-[12px] text-[#45474A] font-medium p-[16px]'>BILLING CYCLE</div>
                        </div>

                        {latestSubscribersData.length > 0 ? latestSubscribersData?.map((item, index) => (
                            <div className='w-full grid grid-cols-4 rounded-[12px] cursor-pointer hover:bg-[#E7F6FC]' key={index}>
                                <div className='text-[14px] text-[#45474A] font-normal p-[16px]'>+{item.mid}</div>
                                <div className='text-[14px] text-[#45474A] font-normal p-[16px]'>{item.plan}</div>
                                <div className='text-[14px] text-[#45474A] font-normal p-[16px]'>{item.creationDate}</div>
                                <div className='text-[14px] text-[#45474A] font-normal p-[16px]'>{item.billingCycle}</div>
                            </div>
                        )) : <p className='h-full w-full flex justify-center items-center'>No data found</p>}
                    </div>

                    <Pagination paginationQuery={paginationQuery} setPaginationQuery={setPaginationQuery} />
                </div>

                <div className='flex flex-col gap-[24px] w-[35%]'>
                    <div className='h-[204px] w-full p-[24px] bg-[#FFFFFF] rounded-[16px]'>
                        <div className='h-[100px] w-full flex justify-between items-start'>
                            <div className='flex flex-col gap-[4px]'>
                                <h4 className='text-[20px] text-[#585B5F] font-semibold'>Subscriber Retention</h4>
                                <div className='h-[28px] min-w-[85px] w-max py-[4px] px-[8px] bg-[#F4F5F5] flex justify-center items-center text-[#A0A2A7] text-[13px] font-medium rounded-[37px] uppercase'>August 2024</div>
                            </div>

                            <img src="./three-dots-vertical-icon.svg" alt="Three-Dots Icon" className='h-[24px] w-[24px] cursor-pointer hover:opacity-90 relative top-[5px]' />
                        </div>

                        <div className='w-full flex justify-between items-center gap-[16px]'>
                            <div className='flex items-center gap-[4px]'>
                                <h3 className='text-[31px] text-[#45474A] font-semibold'>8.72%</h3>
                                <div className='flex items-center gap-[2px]'>
                                    <img src="./arrow-down-red.svg" alt="Arrow-Down Icon" className='h-[24px] w-[24px]' />
                                    <p className='text-[14px] text-[#EE797E] font-medium'>12%</p>
                                </div>
                            </div>

                            <div className='h-[76px] w-[120px]'>
                                <Line data={subscriberRetentionData} options={lineChartOptions} />
                            </div>
                        </div>
                    </div>

                    <div className='h-[284px] w-full p-[24px] bg-[#FFFFFF] rounded-[16px] flex flex-col justify-between gap-[24px]'>
                        <div className='w-full flex justify-between items-center'>
                            <h4 className='text-[20px] text-[#585B5F] font-semibold'>Popular plans</h4>
                            <img src="./three-dots-vertical-icon.svg" alt="Three-Dots Icon" className='h-[24px] w-[24px] cursor-pointer hover:opacity-90' />
                        </div>

                        <div className='h-[111px] w-[250px]'>
                            <Bar data={popularPlansData} options={popularPlansOptions} />
                        </div>

                        <div className='flex justify-between items-center gap-[20px]'>
                            <div className='flex flex-col'>
                                <h3 className='text-[20px] text-[#0EA8DE] font-semibold'>EZ Starter</h3>
                                <p className='text-[14px] text-[#A0A2A7] font-normal'>Retail price $29</p>
                            </div>

                            <div className='flex flex-col items-end'>
                                <div className='flex items-center gap-[2px]'>
                                    <img src="./arrow-down-red.svg" alt="Arrow-Down Icon" className='h-[24px] w-[24px]' />
                                    <p className='text-[14px] text-[#EE797E] font-medium'>1%</p>
                                </div>
                                <p className='text-[14px] text-[#A0A2A7] font-normal'>1298 Subscribers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardComponent;