import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowUp, MdOutlineClose } from 'react-icons/md';
import { LuPencilLine } from 'react-icons/lu';
import { FaRegStar } from 'react-icons/fa';
import { FiPause } from 'react-icons/fi';
import { TbLoader3 } from 'react-icons/tb';
import WalletService from '../services/wallet';

const SubscriberDetails = () => {
  const [subscriberAccountData, setSubscriberAccountData] = useState({
    subscriberFirstName: '',
    subscriberLastName: '',
    email: '',
    contactNumber: '',
    address: { city: '', state: '', zipCode: '' },
    activeSubscriptions: [],
  });
  const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriberAccountDetails = async () => {
      setLoading(true);
      // const res = await new WalletService().getSubscriberAccountDetails('1338998917');
      // console.log(res)

      const res = {
        "accountId": 24485,
        "mid": 1936083097,
        "subscriberFirstName": "Weendy",
        "subscriberLastName": "Julien",
        "contactNumber": "5617702219",
        "email": "mytag.egold@gmail.com",
        "address": {
          "streetNumber": "1023",
          "streetName": "Water Tower way",
          "streetDirection": "N",
          "city": "lantana ",
          "state": "f1",
          "zipCode": "33462"
        },
        "activeSubscriptions": [
          {
            "id": 28200,
            "subscriberNumberStatus": "Rejected",
            "subscriberNumber": null,
            "product": "EZ-Cut-Off Data Only Smartphone",
            "retailName": "EZ Mobile 1GB Plan",
            "retailPrice": "15.0",
            "remainingDaysToResume": null,
            "iccid": "89012602547292187831",
            "cancelledMdn": null
          },
          {
            "id": 28206,
            "subscriberNumberStatus": "Rejected",
            "subscriberNumber": null,
            "product": "EZ-Cut-Off Data Only Smartphone",
            "retailName": "EZ Mobile 1GB Plan",
            "retailPrice": "15.0",
            "remainingDaysToResume": null,
            "iccid": "89012602547292187830",
            "cancelledMdn": null
          },
          {
            "id": 28387,
            "subscriberNumberStatus": "Rejected",
            "subscriberNumber": null,
            "product": "EZ-Cut-Off Data Only Smartphone",
            "retailName": "EZ Mobile 1GB Plan",
            "retailPrice": "15.0",
            "remainingDaysToResume": null,
            "iccid": "89014103334350332056",
            "cancelledMdn": null
          }
        ],
        "tempSubscriptions": [],
        "activePortingProcesses": [],
        "tempPortingProcesses": []
      }
      res?.activeSubscriptions.forEach((item) => (item.isOpen = false));
      subscriptionPlanDetails.length = res?.activeSubscriptions.length;
      setSubscriberAccountData({ ...subscriberAccountData, ...res });
      setLoading(false);
    };
    fetchSubscriberAccountDetails();
  }, []);

  const fetchSubscriptionPlanDetails = async (subscriptionPlanId, subscriptionPlanIndex) => {
    setLoading(true);
    // const res = await new WalletService().getSubscriberAccountDetails('1338998917');
    // console.log(res)

    // const res = {
    //   "id": 28200,
    //   "ban": "287337652356",
    //   "creationDate": "2024-09-08 15:29:07 GMT",
    //   "activationDate": "2024-09-08 15:29:07 GMT",
    //   "subscriberNumberStatus": "Rejected",
    //   "totalDataUsageKB": 0,
    //   "totalDataUsageMB": 0,
    //   "totalDataUsageGB": 0,
    //   "kbBankMB": null,
    //   "kbBankGB": null,
    //   "carrier": "AT&T",
    //   "mdn": "1338998917",
    //   "imei": "351678916998030",
    //   "iccid": "89012602547292187831",
    //   "nextBillCycleDate": null,
    //   "errorMessage": "Returns Parse Exception",
    //   "remainingDaysToResume": null,
    //   "cancelledMdn": null,
    //   "product": {
    //     "deviceType": "Data Device",
    //     "offerId": "594",
    //     "product": "Airespring Throttle - EZ-Cut-Off Data Only Smartphone",
    //     "soc": "AX5DOIP23",
    //     "productSize": "1 GB",
    //     "serviceType": "Unlimited",
    //     "description": "Unlimited Talk\nUnlimited Text\n1GB Data",
    //     "retailName": "EZ Mobile 1GB Plan",
    //     "retailPrice": "15.0",
    //     "productFamily": "Airespring Throttle"
    //   },
    //   "features": []
    // }

    const res = {
      "id": 28420,
      "ban": "287337652356",
      "creationDate": "2024-09-11 16:24:23 GMT",
      "activationDate": "2024-09-11 16:24:23 GMT",
      "subscriberNumberStatus": "Active",
      "totalDataUsageKB": 0.0,
      "totalDataUsageMB": 0.0,
      "totalDataUsageGB": 0.0,
      "kbBankMB": 1024.0,
      "kbBankGB": 1.0,
      "carrier": "AT&T",
      "mdn": "5614464952",
      "imei": "356680110951190",
      "iccid": "89014103334319497818",
      "nextBillCycleDate": "2024-09-25Z",
      "errorMessage": null,
      "remainingDaysToResume": null,
      "cancelledMdn": null,
      "product": {
        "deviceType": "Smartphone",
        "offerId": "587",
        "product": "Airespring Throttle - EZ-Cut-Off 1 GB",
        "soc": "APX1G5I23",
        "productSize": "1 GB",
        "serviceType": "Unlimited",
        "description": "",
        "retailName": "",
        "retailPrice": "0.0",
        "productFamily": "Airespring Throttle"
      },
      "features": [
        {
          "name": "Block International Roaming Except Mexico/Canada",
          "code": "NIRMAPEX",
          "description": "Block International Roaming Except Mexico/Canada - Compatible with all APEX plans (APEX Unlimited, APEX Mobile Select, APEX Exclusive, and Custom Plans).  ",
          "classificationName": "feature"
        },
        {
          "name": "Message Suppression",
          "code": "TRKSOCV56",
          "description": "Blocks DUCCS notice for international alerts and other automated AT&T messages",
          "classificationName": "feature"
        },
        {
          "name": "Stream Saver",
          "code": "DSABR2",
          "description": "Video Management",
          "classificationName": "feature"
        }
      ]
    }

    const updatedSubscriptionPlanDetails = [...subscriptionPlanDetails];
    updatedSubscriptionPlanDetails[subscriptionPlanIndex] = { ...res };
    setSubscriptionPlanDetails(updatedSubscriptionPlanDetails);
    setLoading(false);
  };

  const handleDropdown = (subscriptionPlanId, subscriptionPlanIndex) => {
    fetchSubscriptionPlanDetails(subscriptionPlanId, subscriptionPlanIndex);
    const updatedSubscribersAccountData = { ...subscriberAccountData };
    updatedSubscribersAccountData.activeSubscriptions.forEach((item, index) => {
      if (index === subscriptionPlanIndex) {
        updatedSubscribersAccountData.activeSubscriptions[subscriptionPlanIndex].isOpen =
          !updatedSubscribersAccountData.activeSubscriptions[subscriptionPlanIndex].isOpen;
      }
    });

    setSubscriberAccountData(updatedSubscribersAccountData);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <div
      className={`flex h-full w-full bg-[#F1F2F2] p-[24px] font-jakarta ${loading ? 'justify-center' : 'justify-end'}`}
      style={{ minHeight: 'calc(100vh - 65px)' }}
    >
      {loading ? (
        <div className="flex justify-center items-center h-85per w-full">
          <TbLoader3 className="animate-spin text-black h-13 w-13" />
        </div>
      ) : (
        <>
          <div
            className="w-[300px] p-[16px] bg-[#FDFDFD] rounded-[24px] flex flex-col justify-between fixed left-[340px] top-[86px]"
            style={{ height: 'calc(100% - 100px)' }}
          >
            <div className="flex flex-col gap-[24px]">
              <h1 className="text-[#1D2224] text-[35px] font-extrabold">
                {subscriberAccountData.subscriberFirstName + ' ' + subscriberAccountData.subscriberLastName}
              </h1>

              <div className="flex flex-col">
                <p className="text-[#5A5E5F] uppercase">Email</p>
                <p className="text-[#1D2224]">{subscriberAccountData.email}</p>
              </div>

              <div className="flex flex-col">
                <p className="text-[#5A5E5F] uppercase">Phone</p>
                <p className="text-[#1D2224]">{subscriberAccountData.contactNumber}</p>
              </div>

              <div className="flex flex-col">
                <p className="text-[#5A5E5F] uppercase">Address</p>
                <p className="text-[#1D2224]">
                  {subscriberAccountData?.address?.city +
                    ', ' +
                    subscriberAccountData?.address?.state +
                    ', ' +
                    subscriberAccountData?.address?.zipCode}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-[8px]">
              <button className="h-[48px] w-full rounded-[24px] bg-[#F1F2F2] text-[#1D2224] text-[14px]">
                Edit details
              </button>
              <button className="h-[48px] w-full rounded-[24px] bg-[#0EA8DE] text-[#FDFDFD] text-[14px]">
                Add a subscription
              </button>
            </div>
          </div>

          <div
            className="flex flex-col gap-[13px] min-w-[580px]"
            style={{ width: 'calc(100% - 320px)' }}
          >
            {subscriberAccountData?.activeSubscriptions?.map((item, index) => (
              <div
                className={`h-max w-full p-[16px] box-border rounded-[24px] bg-[#FDFDFD] flex flex-col gap-[32px] transition-all ${item?.isOpen ? 'min-h-max' : ''}`}
                key={index}
              >
                <div
                  className="flex items-center gap-[16px] cursor-default"
                  onClick={() => handleDropdown(item._id, index)}
                >
                  <h4 className="w-[35%] text-[#1D2224] font-extrabold">{item.product}</h4>
                  <div className="w-[25%] h-[32px]">
                    <div
                      className={`h-full min-w-[126px] w-max rounded-[34px] flex justify-center items-center gap-[6px] ${item.subscriberNumberStatus === 'Active' ? 'bg-[#A3F6DD] text-[#0C7B59]' : 'bg-[#F1F2F2] text-[#999C9C]'}`}
                    >
                      <div
                        className={`h-[8px] w-[8px] rounded-full relative top-[1px] ${item.subscriberNumberStatus === 'Active' ? 'bg-[#0C7B59]' : 'bg-[#999C9C]'}`}
                      ></div>
                      <p>{item.subscriberNumberStatus}</p>
                    </div>
                  </div>
                  <p className="w-[35%] text-[#383D3E] font-bold">{item.subscriberNumber}</p>
                  <MdKeyboardArrowUp
                    className={`h-[24px] w-[24px] text-[#1D2224] transition-all ${item.isOpen ? '' : 'rotate-180'}`}
                  />
                </div>

                {item.isOpen && (
                  <>
                    {subscriptionPlanDetails[index]?.features && <div className="flex flex-col gap-[4px]">
                      <p className="text-[#6C6F71]">Features</p>
                      <div className="flex items-center gap-[4px] flex-wrap">
                        {subscriptionPlanDetails[index]?.features?.map((item, index) => (
                          <div className="px-[12px] py-[4px] bg-[#F1F2F2] rounded-[34px] flex justify-center items-center" key={index}>
                            {item?.name}
                          </div>
                        ))}
                      </div>
                    </div>}

                    <div className="flex gap-[16px]">
                      <div className="w-[33%] flex flex-col gap-[24px]">
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">MDN</p>
                          <p className="text-[#1D2224]">{subscriptionPlanDetails[index]?.mdn}</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">Plan Size</p>
                          <p className="text-[#1D2224]">{subscriptionPlanDetails[index]?.product?.productSize}</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">BAN</p>
                          <p className="text-[#1D2224]">{subscriptionPlanDetails[index]?.ban}</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">IMEI</p>
                          <p className="text-[#1D2224]">{subscriptionPlanDetails[index]?.imei}</p>
                        </div>
                      </div>

                      <div className="w-[33%] flex flex-col gap-[24px]">
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">Creation Date</p>
                          <p className="text-[#1D2224]">{formatDate(subscriptionPlanDetails[index]?.creationDate)}</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">Total Data Usage (GB)</p>
                          <p className="text-[#1D2224]">{subscriptionPlanDetails[index]?.totalDataUsageGB} GB</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">Service Type</p>
                          <p className="text-[#1D2224]">{subscriptionPlanDetails[index]?.product?.serviceType}</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">Bill Cycle</p>
                          <p className="text-[#1D2224]">{formatDate(subscriptionPlanDetails[index]?.nextBillCycleDate)}</p>
                        </div>
                      </div>

                      <div className="w-[33%] flex flex-col gap-[24px]">
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">Activation Date</p>
                          <p className="text-[#1D2224]">{formatDate(subscriptionPlanDetails[index]?.activationDate)}</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">Total Data Usage (MB)</p>
                          <p className="text-[#1D2224]">{subscriptionPlanDetails[index]?.totalDataUsageMB} MB</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">ICCID</p>
                          <p className="text-[#1D2224]">{subscriptionPlanDetails[index]?.iccid}</p>
                        </div>
                        <div className="flex flex-col h-[48px]">
                          <p className="text-[#6C6F71]">Data Balance (MB)</p>
                          <p className="text-[#1D2224]">0 MB</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center gap-[4px]">
                      <div className="px-[16px] py-[8px] bg-[#C5EAF7] rounded-[24px] flex justify-center items-center  gap-[4px] text-[#0C8FBD] font-semibold">
                        <LuPencilLine className="text-[18px]" />
                        <p>Edit</p>
                      </div>

                      <div className="px-[16px] py-[8px] bg-[#C5EAF7] rounded-[24px] flex justify-center items-center  gap-[4px] text-[#0C8FBD] font-semibold">
                        <FaRegStar className="text-[18px]" />
                        <p>Features</p>
                      </div>

                      <div className="px-[16px] py-[8px] bg-[#C5EAF7] rounded-[24px] flex justify-center items-center  gap-[4px] text-[#0C8FBD] font-semibold">
                        <FiPause className="text-[18px]" />
                        <p>Suspend</p>
                      </div>

                      <div className="px-[16px] py-[8px] bg-[#C5EAF7] rounded-[24px] flex justify-center items-center  gap-[4px] text-[#0C8FBD] font-semibold">
                        <MdOutlineClose className="text-[18px]" />
                        <p>Cancel</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriberDetails;
