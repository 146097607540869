import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import MessageWrapper from '../common/MessageWrapper';
import InitialSignup from '../new-auth/initial-signup';

function InvitationValidation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState(null);
  const [loading, setloading] = useState(false);

  const ValidationToken = async () => {
    try {
      setloading(true);
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      // Validate the token
      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_USER_MICROSERVICE}/validate/invitation`,
          { token }
        );
        if (response.data.success) {
          setValidationStatus('valid');
        }
      } else {
        setValidationStatus('invalid');
      }
    } catch (error) {
      if (error.response?.data?.message === 'Token expired') {
        setValidationStatus('expired');
      } else {
        setValidationStatus('invalid');
      }
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    ValidationToken();
  }, [location]);

  return (
    <>
      {validationStatus === 'expired' && (
        <MessageWrapper
          heading={'The Token is expired.'}
          subheading={'Please get valid token.'}
          isError={true}
        />
      )}
      {validationStatus === 'invalid' && (
        <MessageWrapper
          heading={'The Token is not valid.'}
          subheading={'Please get valid  token.'}
          isError={true}
        />
      )}
        {validationStatus === 'valid' && (
       <InitialSignup />
      )}
      {/* </div> */}
    </>
  );
}

export default InvitationValidation;
