import React, { useEffect, useState } from 'react';
import IdentityDetails from './identityDetails';
import AddressDetails from './addressDetails';
import FileUpload from './fileUpload';
import Declaration from './declaration';
import AddAdSuccessModal from './addAdSuccessModal';
import BankDetails from './bankDetails';
import { AddADFormData } from '../constants';
import { useSelector } from 'react-redux';
import Utils from '../utility';

const AddADComponent = ({ type }) => {
  const [tabDetails, setTabDetails] = useState([
    'Identity details',
    'Address details',
    'File upload',
    'Bank Details',
    'Declaration',
  ]);
  const [selectedTab, setSelectedTab] = useState(0);
  const user = useSelector((state) => state.user);
  const fatherNameArr = user?.fatherName ? user.fatherName.split(" ") : ["", ""];
  const signatureNameArr = user?.fatherName ? user.fatherName.split(" ") : ["", ""];

  const [prefilledFormData, setPrefilledFormData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    fatherFirstName: fatherNameArr[0],
    fatherLastName: fatherNameArr[fatherNameArr.length - 1],
    gender: user?.gender,
    dob: user?.dob,
    identityNo: user?.identityNo,
    proofOfIdentity: user?.proofOfIdentity,
    nationality: user?.nationality,
    postalCode: user?.address?.zipCode,
    country: user?.address?.country,
    email: user?.email,
    phoneCountryCode: user?.phoneCountryCode,
    phoneNumber: user?.phoneNumber,
    proofOfAddress: user?.proofOfAddress,
    bankName: user?.bankName,
    accountNumber: user?.accountNumber,
    routingNumber: user?.routingNumber,
    accountType: user?.accountType,
    accountHolderName: user?.accountHolderName,
    signatureFirstName: signatureNameArr[0],
    signatureLastName: signatureNameArr[signatureNameArr.length - 1],
    signature: user?.signature,
    dateSigned: user?.dateSigned,
    isTermsAccepted: user?.isTermsAccepted,
  });
  
  const [formData, setFormData] = useState(
    user?.isInformationSubmitted ? prefilledFormData : AddADFormData
  );
  const [tabValidation, setTabValidation] = useState(0);
  const [phoneNumberInput, setPhoneNumberInput] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  useEffect(() => {
  if (user?.status === 'Rejected') {
    const rejectReason = user.rejectReason || [];
    if (rejectReason.includes('Bank Details')) {
      setSelectedTab(3);
      Utils.failureToastMessage('Please update your Bank Details:',user?.rejectComment);
    } else if (rejectReason.includes('Identity Details')) {
      setSelectedTab(0);
       Utils.failureToastMessage('Please update your Identity Details.',user?.rejectComment);
    } else if (rejectReason.includes('Address Details')) {
      setSelectedTab(1);
       Utils.failureToastMessage('Please update your Address Details.',user?.rejectComment);
    }
  }
}, [user]);

  useEffect(() => {
    if (
      formData?.firstName &&
      formData?.lastName &&
      formData?.fatherFirstName &&
      formData?.fatherLastName &&
      formData?.gender &&
      formData?.dob &&
      formData?.identityNo &&
      formData?.proofOfIdentity.type &&
      formData?.nationality
    ) {
      setTabValidation(1);
    }
    if (
      formData?.postalCode &&
      formData?.country &&
      phoneNumberInput &&
      formData?.email &&
      formData?.proofOfAddress.type
    ) {
      setTabValidation(2);
    }
    if (formData?.proofOfIdentity?.documentLink && formData?.proofOfAddress?.documentLink) {
      setTabValidation(3);
    }
    if (
      formData?.bankName &&
      formData?.accountNumber &&
      formData?.routingNumber &&
      formData?.accountType &&
      formData?.accountHolderName
    ) {
      setTabValidation(4);
    }
    if (
      formData?.signatureFirstName &&
      formData?.signatureLastName &&
      formData?.signature &&
      formData?.dateSigned &&
      formData?.isTermsAccepted
    ) {
      setTabValidation(5);
    }
  }, [formData]);

  useEffect(() => {
    setFormData({ ...formData, firstName: user?.firstName, lastName: user?.lastName, email: user?.email, signatureFirstName: user?.firstName, signatureLastName: user?.lastName });
  }, []);

  return (
    <div className="flex flex-col items-center gap-[30px] bg-[#F1F2F2] min-h-screen py-[30px] relative font-jakarta">
      <div className="fixed top-0 left-[50px] right-0 flex justify-center items-center pt-[30px] bg-[#F1F2F2] z-50">
        <img
          src="./tittle-logo.svg"
          alt="Logo"
          className="absolute left-[0px] top-[40px] w-[170px]"
        />

        <div className="flex flex-col items-center gap-[10px] min-w-[820px] w-[38%] p-[20px] box-border relative left-[50px] 2xl:left-0">
          <div className="min-w-[780px] w-full flex items-center">
            {tabDetails.map((item, index) => (
              <p
                className={`min-w-[156px] w-[20%] text-[16px] text-center relative ${selectedTab === index ? 'text-[#1D2224] font-semibold border-bottom-line' : 'text-[#5A5E5F] font-normal'} ${tabValidation >= index ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                key={index}
                onClick={() => tabValidation >= index && setSelectedTab(index)}
              >
                {item}
              </p>
            ))}
          </div>
          <div className="h-[4px] w-full bg-[#DDDEDE] rounded-[14px]"></div>
        </div>
      </div>

      <div className="w-full mt-[100px] flex justify-center">
        {selectedTab === 0 && (
          <IdentityDetails
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 1 && (
          <AddressDetails
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
            setPhoneNumberInput={setPhoneNumberInput}
          />
        )}
        {selectedTab === 2 && (
          <FileUpload
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 3 && (
          <BankDetails
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 4 && (
          <Declaration
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
            phoneNumberInput={phoneNumberInput}
          />
        )}
        {selectedTab === 5 && <AddAdSuccessModal type={type} formData={formData} />}
      </div>
    </div>
  );
};

export default AddADComponent;
