import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TbLoader3 } from 'react-icons/tb';
import { BiExport } from 'react-icons/bi';
import Utils from '../utility';

const TransactionsList = ({
  loading,
  transactions,
  sortConfig,
  setSortConfig,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalTransactions,
  exportAdminTransactionsToCSV,
  exportToCSV,
}) => {
  const user = useSelector((state) => state.user);
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const paginationRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, []);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage * rowsPerPage < totalTransactions) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="flex flex-col h-screen font-jakarta">
      <div className="p-8 flex-1 bg-[#F1F2F2]">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-bold">Transactions</h2>
          <div
            className="flex items-center justify-center bg-[#DDDEDE] hover:bg-opacity-85 rounded-[24px] text-[#1D2224] px-4 gap-2 py-2 h-12 cursor-pointer"
            onClick={user?.roleId?.name === 'Admin' ? exportAdminTransactionsToCSV : exportToCSV}
          >
            <BiExport className="size-6" />
            <span>Export to CSV</span>
          </div>
        </div>

        <div className="overflow-x-auto relative">
          <table className="w-full border-collapse">
            <thead className="bg-[#F1F2F2]">
              <tr className="text-ft1_5 font-normal text-[#6C6F71] uppercase">
                <th className="text-left p-2 cursor-pointer" onClick={() => requestSort('userId')}>
                  Transaction ID
                </th>
                {user?.roleId?.name === 'Admin' && (
                  <th
                    className="text-left p-2 cursor-pointer"
                    onClick={() => requestSort('transactionId')}
                  >
                    Wallet ID
                  </th>
                )}
                <th className="text-left p-2 cursor-pointer" onClick={() => requestSort('addedOn')}>
                  Date & Time
                </th>
                <th
                  className="text-left p-2 cursor-pointer"
                  onClick={() => requestSort('description')}
                >
                  Description
                </th>
                <th className="text-left p-2 cursor-pointer" onClick={() => requestSort('amount')}>
                  Amount
                </th>
                <th className="text-left p-2 cursor-pointer" onClick={() => requestSort('balance')}>
                  Balance
                </th>
              </tr>
            </thead>

            {loading ? (
              <div className="flex justify-center items-center w-full h-80">
                <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-50per left-45per" />
              </div>
            ) : (
              <tbody>
                {transactions.length > 0 ? (
                  transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td className="py-4 px-2">
                        {transaction?.transactionId === 'N/A'
                          ? transaction._id
                          : transaction.transactionId.length > 24
                            ? `${transaction.transactionId.slice(
                              0,
                              12
                            )}...${transaction.transactionId.slice(-12)}`
                            : transaction.transactionId}
                      </td>
                      {user?.roleId?.name === 'Admin' && (
                        <td className="py-4 px-2">{transaction?.walletId}</td>
                      )}
                      <td className="py-4 px-2">{Utils.formatTimestamp(transaction.addedOn)}</td>
                      {/* <td className="py-4 px-2">{transaction.description}</td> */}
                      <td className="py-4 px-2">
                        {transaction.description.length > 30
                          ? `${transaction.description.slice(
                            0,
                            15
                          )}...${transaction.description.slice(-15)}`
                          : transaction.description}
                      </td>
                      <td className="py-4 px-2">
                        <span
                          className={`${transaction?.type === 'Credit' ? 'text-green-500' : 'text-red-500'
                            }`}
                        >
                          {transaction?.type === 'Credit'
                            ? `+${transaction.amount}`
                            : `-${transaction.amount}`}
                        </span>
                      </td>
                      <td className="py-4 px-2">${transaction.balance}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center py-3">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>

        <div className="flex justify-end items-center pb-4 mt-4">
          <div className="flex items-center gap-10">
            <div className="flex items-center">
              <span>Rows per page:</span>
              <div className='relative' onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)} ref={paginationRef}>
                <img src="./chevron_backward.svg" alt="Chevron-Down Icon" className={`absolute right-[15px] top-[17px] transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`} style={{ transitionDuration: '100ms' }} />
                <select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  className="ml-2 py-2 pl-[16px] w-[68px] h-[40px] outline-none border-none rounded-[24px] appearance-none"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
            <span>
              {(currentPage - 1) * rowsPerPage + 1} -{' '}
              {currentPage * rowsPerPage > totalTransactions
                ? totalTransactions
                : currentPage * rowsPerPage}{' '}
              of {totalTransactions}
            </span>
            <div>
              <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
                &lt;
              </button>
              <button className="p-2 rounded-lg" onClick={handleNextPage}>
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsList;
