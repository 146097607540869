import React, { useState } from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { RejectModal } from './Modals';

const IdentityDetails = ({ setSelectedTab, setTabValidation, tabValidation, formData }) => {
  const [showRejectModal, setShowRejectModal] = useState(false);
  const dobDate =
    new Date(formData?.dob).getDate() > 9
      ? new Date(formData?.dob).getDate()
      : `0${new Date(formData?.dob).getDate()}`;
  const dobMonth =
    new Date(formData?.dob).getMonth() > 8
      ? new Date(formData?.dob).getMonth() + 1
      : `0${new Date(formData?.dob).getMonth() + 1}`;
  const dobYear = new Date(formData?.dob).getFullYear();

  return (
    <div className="flex flex-col gap-[32px]">
      <div className="flex flex-col">
        <span className="py-1 px-3 w-[53px] text-[16px] leading-6 bg-[#FDFDFD] text-[#6C6F71] rounded-[35px] flex justify-center items-center">
        1 / 5
        </span>
        <h3 className="text-[#2F3436] text-[25px] leadig-[35px] font-extrabold mt-[4px]">Application for {formData?.roleId?.name === "Master Distributor" ? "MD" : "AD"}</h3>
        <p className="text-[#1D2224] text-[20px] leading-7 font-semibold">Applicant's identity details</p>
      </div>

      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">
            Name of the Applicant
          </span>
          <p className="text-[#1D2224] text-[16px] font-semibold">
            {formData?.firstName + ' ' + formData?.lastName}
          </p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">
            Father's/Spouse's Name
          </span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.fatherName}</p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">Gender</span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.gender}</p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">Date of Birth</span>
          <p className="text-[#1D2224] text-[16px] font-semibold">
            {`${dobDate}/${dobMonth}/${dobYear}`}
          </p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">Pan</span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.identityNo}</p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">
            Proof of Identity Submitted for PAN Exempt Cases
          </span>
          <p className="text-[#1D2224] text-[16px] font-semibold">
            {formData?.proofOfIdentity?.type}
          </p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">Nationality</span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.nationality}</p>
        </div>
        <div className="flex justify-between mt-5">
          <button className="p-3 bg-[#DDDEDE] rounded-full  text-[#1D2224] cursor-pointer" disabled>
            {' '}
            <MdOutlineKeyboardArrowLeft size={24} className="opacity-30" />
          </button>
          <button
            className="p-3 bg-[#DDDEDE] rounded-full text-[#1D2224] cursor-pointer"
            onClick={() => setSelectedTab(1)}
          >
            {' '}
            <MdOutlineKeyboardArrowRight size={24} />
          </button>
        </div>
        <div className="flex justify-between gap-2">
          <button
            className="bg-[#FBDEDF] text-[#E32028] rounded-[12px] px-3 py-2 w-[188px] h-[48px]"
            onClick={() => setShowRejectModal(true)}
          >
            Reject
          </button>
          <button
            className="bg-[#0EA8DE] text-[#FDFDFD] rounded-[12px] px-3 py-2 w-[188px] h-[48px]"
            onClick={() => {
              setTabValidation(1);
              setSelectedTab(1);
            }}
          >
            Next
          </button>
        </div>
        {showRejectModal && <RejectModal onClose={() => setShowRejectModal(false)} />}
      </div>
    </div>
  );
};

export default IdentityDetails;
