import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { useSelector } from 'react-redux';

const Header = ({
  collapsed,
  toggleProfileSidebar,
  handleSearch,
  dropdownQuery,
  setDropdownQuery,
  searchInput,
  setSearchInput,
  handleSearchInput,
  subscriberDetailsOpen,
  setSubscriberDetailsOpen,
}) => {
  const user = useSelector((state) => state.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showSearchBtn, setShowSearchBtn] = useState(false);
  const dropdownRef = useRef(null);
  const searchBtnRef = useRef(null);
  const location = useLocation();
  const [selectedAdminSubmenu, setSelectedAdminSubmenu] = useState(
    localStorage.getItem('selectedAdminSubmenu') || ''
  );

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'selectedAdminSubmenu') {
        setSelectedAdminSubmenu(e.newValue || '');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    setSelectedAdminSubmenu(localStorage.getItem('selectedAdminSubmenu') || '');
  }, [location.pathname]);

  useEffect(() => {
    let handler = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
      if (searchBtnRef.current && !searchBtnRef.current.contains(e.target)) {
        setShowSearchBtn(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const handleDropdownClick = (query) => {
    setDropdownQuery(query);
    setDropdownOpen(false);
  };

  const renderDropdown = () => {
    if (
      (user?.roleId?.name === 'Admin' || user?.roleId?.name === 'Master Distributor') &&
      location.pathname === '/dashboard'
    ) {
      return (
        <div
          className="absolute w-full pt-10 pb-3 rounded-[20px] top-0 bg-white border border-gray-300 shadow-lg mt-2 z-10 transition-all"
          ref={dropdownRef}
        >
          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'name' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('name')}
          >
            Name
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'email' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('email')}
          >
            Email
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'status' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('status')}
          >
            Status
          </div>
        </div>
      );
    }

    if (user?.roleId?.name === 'Authorized Distributor' && location.pathname === '/dashboard') {
      return (
        <div
          className="absolute w-full pt-10 pb-3 rounded-[20px] top-0 bg-white border border-gray-300 shadow-lg mt-2 z-10 transition-all"
          ref={dropdownRef}
        >
          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'name' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('name')}
          >
            Name
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'email' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('email')}
          >
            Email
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'ICCID' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('ICCID')}
          >
            ICCID
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'IMEI' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('IMEI')}
          >
            IMEI
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'BAN' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('BAN')}
          >
            BAN
          </div>
        </div>
      );
    }

    if (user?.roleId?.name === 'Admin' && location.pathname === '/transactions') {
      return (
        <div
          className="absolute w-full pt-10 pb-3 rounded-[20px] top-0 bg-[white] border border-gray-300 shadow-lg mt-2 z-10 transition-all"
          ref={dropdownRef}
        >
          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'name' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('name')}
          >
            Name
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'email' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('email')}
          >
            Email
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'description' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('description')}
          >
            Description
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'transactionId' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('transactionId')}
          >
            Transaction Id
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'walletId' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('walletId')}
          >
            Wallet Id
          </div>
        </div>
      );
    }

    if (user?.roleId?.name !== 'Admin' && location.pathname === '/transactions') {
      return (
        <div
          className="absolute w-full pt-10 pb-3 rounded-[20px] top-0 bg-[white] border border-gray-300 shadow-lg mt-2 z-10 transition-all"
          ref={dropdownRef}
        >
          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'description' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('description')}
          >
            Description
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'type' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('type')}
          >
            Type
          </div>

          <div
            className={`px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer ${
              dropdownQuery === 'transactionId' ? 'bg-[#F1F2F2]' : 'bg-white'
            }`}
            onClick={() => handleDropdownClick('transactionId')}
          >
            Transaction Id
          </div>
        </div>
      );
    }
  };

  const getPlaceholderText = () => {
    switch (selectedAdminSubmenu) {
      case 'Subscriber Management':
        return 'Search for a subscriber';
      case 'Product Catalogue':
        return 'Search for a product';
      case 'Transactions':
        return 'Search for a transaction';
      case 'AD Management':
        return 'Search for a distributor';
      case 'MD Management':
        return 'Search for a distributor';
      default:
        return 'Search...';
    }
  };

  return (
    <div className="flex bg-[#F1F2F2] p-4 w-full">
      <div
        className={`flex w-full ${!collapsed ? 'headerCalcWidth' : ''} justify-between items-center rounded-[48px] h-[48px] pr-4 p-2 ${subscriberDetailsOpen ? 'bg-[#FFFFFF] subscriberDetailsHeaderCalcWidth ml-2 px-0 py-1' : ''}`}
      >
        <div className="flex items-center space-x-4 w-full">
          {!subscriberDetailsOpen && (
            <div className="relative">
              <button
                className={`p-4 bg-[#FFFFFF] justify-between w-[142px] h-[40px] rounded-[20px] flex items-center z-20 relative border-1 ${
                  dropdownOpen ? 'border-black' : 'border-transparent'
                }`}
                onClick={() => setDropdownOpen(true)}
              >
                {dropdownQuery[0].toUpperCase()}
                {dropdownQuery.slice(1)}{' '}
                <img
                  src="/chevron_backward.svg"
                  alt="Dropdown Icon"
                  className={`w-4 h-4 ml-1 transition-all ${dropdownOpen && 'rotate-180'}`}
                />
              </button>

              {dropdownOpen && renderDropdown()}
            </div>
          )}

          {!subscriberDetailsOpen && (
            <div
              className="relative searchCalcWidth rounded-[20px] bg-white overflow-hidden"
              ref={searchBtnRef}
            >
              <img
                src="/search.svg"
                alt="Search Icon"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4.5 h-4 text-gray-400"
              />

              <input
                type="text"
                placeholder={getPlaceholderText()}
                className="searchInputCalcWidth p-2 pl-10 placeholder:text-[#999C9C] rounded-[20px] outline-none"
                value={searchInput}
                onFocus={() => setShowSearchBtn(true)}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={handleSearchInput}
              />

              <button
                className={`py-[5px] px-8 rounded-[20px] flex items-center bg-[#e1e0e0] absolute top-[3px] right-[3px] transition-all hover:brightness-90 ${
                  showSearchBtn ? 'opacity-100' : 'opacity-0'
                }`}
                onClick={handleSearch}
                disabled={!showSearchBtn}
              >
                Search
              </button>
            </div>
          )}

          {subscriberDetailsOpen && (
            <div
              onClick={() => setSubscriberDetailsOpen(false)}
              className="h-[48px] w-max p-[12px] rounded-[24px] bg-white flex items-center gap-[8px] cursor-pointer"
            >
              <FaArrowLeft className="text-[#1C1B1F]" />
              <p className="text-[#999C9c]">Back to Subscriber Management</p>
            </div>
          )}
        </div>

        <div className="flex items-center space-x-4 w-[160px]">
          <div className="relative">
            <button className="p-2 rounded-lg">
              <img src="/notifications_unread.svg" alt="Notification Icon" className="w-4 h-4" />
            </button>
            <span className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 rounded-full"></span>
          </div>

          <div
            onClick={toggleProfileSidebar}
            className="relative cursor-pointer w-10 h-10 rounded-full flex items-center justify-center overflow-hidden"
          >
            {user && user?.image ? (
              <img
                src={user?.image}
                alt={user?.name}
                className="w-[60px] h-[60px] object-cover cursor-pointer"
              />
            ) : (
              <div
                className={`w-[60px] h-[60px] rounded-full flex items-center justify-center ${subscriberDetailsOpen ? 'bg-[#999C9C]' : 'bg-[#FFFFFF]'}`}
              >
                {user?.name
                  ? `${user?.name.substring(0, 1).toUpperCase()}${user?.name
                      .split(' ')
                      .map((word) => word[0])
                      .join('')
                      .substring(1, 2)
                      .toUpperCase()}`
                  : 'AB'}
              </div>
            )}
          </div>

          <button onClick={toggleProfileSidebar} className="p-2 rounded-lg">
            <img src="/chevron_backward.svg" alt="Left Arrow Icon" className="w-4 h-4 rotate-90" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
