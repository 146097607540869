import React, { useState, useEffect, useRef } from 'react';

const Header = ({ dropdownQuery, setDropdownQuery, searchInput, setSearchInput, fetchLatestSubscribersData}) => {
    const dropdownRef = useRef(null);
    const dropdownChildRef = useRef(null);
    const searchBtnRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showSearchBtn, setShowSearchBtn] = useState(false);

    useEffect(() => {
        let handler = (e) => {
            if (dropdownRef.current && dropdownChildRef.current && !dropdownRef.current.contains(e.target) && !dropdownChildRef.current.contains(e.target)) {
                setDropdownOpen(false);
            }
            if (searchBtnRef.current && !searchBtnRef.current.contains(e.target)) {
                setShowSearchBtn(false);
            }
        };

        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, []);

    const handleDropdownQuery = (selectedItem) => {
        setDropdownQuery(selectedItem);
        setDropdownOpen(false);
    }

    return (
        <div className='fixed top-0 left-[23%] h-[100px] w-[77%] bg-[#E9F9FF] z-30'>
            <div className='fixed top-[24px] left-[26%] h-[56px] w-[70%] p-[8px] bg-[#FFFFFF] rounded-[50px] flex justify-between items-center gap-[20px] z-50'>
            <div className='flex items-center w-[80%] relative'>
                <div className={`h-[40px] w-[15%] bg-[#E7F6FC] rounded-[20px] flex justify-center items-center gap-[10px] z-30 cursor-pointer ${dropdownOpen ? 'border-1' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)} ref={dropdownRef}>
                    <p>{dropdownQuery}</p>
                    <img src="./chevron_backward.svg" alt="Chevron-Down Icon" className={`h-[7px] w-[12px] transition-all ${dropdownOpen ? 'rotate-180' : ''}`} />
                </div>

                {dropdownOpen && <div className='absolute top-0 left-0 h-[210px] w-[15%] pt-[50px] bg-[#FFFFFF] rounded-[20px] overflow-hidden z-20 flex flex-col items-center shadow-2xl' ref={dropdownChildRef}>
                    <p className={`w-full py-2 text-center cursor-pointer hover:bg-[#E7F6FC] ${dropdownQuery === "MDN" ? 'bg-[#FFFFFF]' : ''}`} onClick={() => handleDropdownQuery("MDN")}>MDN</p>
                    <p className={`w-full py-2 text-center cursor-pointer hover:bg-[#E7F6FC] ${dropdownQuery === "Name" ? 'bg-[#FFFFFF]' : ''}`} onClick={() => handleDropdownQuery("Name")}>Name</p>
                    <p className={`w-full py-2 text-center cursor-pointer hover:bg-[#E7F6FC] ${dropdownQuery === "Email" ? 'bg-[#FFFFFF]' : ''}`} onClick={() => handleDropdownQuery("Email")}>Email</p>
                    <p className={`w-full py-2 text-center cursor-pointer hover:bg-[#E7F6FC] ${dropdownQuery === "Status" ? 'bg-[#FFFFFF]' : ''}`} onClick={() => handleDropdownQuery("Status")}>Status</p>
                </div>}

                <div className="relative w-[75%] bg-white" ref={searchBtnRef} >
                    <img src="./search.svg" alt="Search-Icon" className="absolute left-4 top-1/2 transform -translate-y-1/2 w-4.5 h-4 text-gray-400" />

                    <input type="text" placeholder='Search for a subscriber' className="w-[72%] p-2 pl-10.5 placeholder:text-[#A0A2A7] outline-none" value={searchInput} onFocus={() => setShowSearchBtn(true)} onChange={(e) => setSearchInput(e.target.value)} onKeyDown={(e) => e.key === "Enter" && fetchLatestSubscribersData()} />

                    <button className={`absolute top-0 right-[3px] h-[40px] w-[120px] flex justify-center items-center bg-[#E7F6FC] text-[#0EA8DE] rounded-[20px] transition-all hover:bg-opacity-80 ${showSearchBtn ? 'opacity-100' : 'opacity-0'}`} onClick={fetchLatestSubscribersData} disabled={!showSearchBtn}>
                        Search
                    </button>
                </div>
            </div>

            <div className='flex items-center gap-[12px]'>
                <img src="./notifications-icon.svg" alt="Notifications-Icon" className='h-[24px] w-[24px] cursor-pointer hover:bg-opacity-80' />
                <img src="./user-profile.svg" alt="User-Profile" className='h-[40px] w-[40px] rounded-full cursor-pointer hover:bg-opacity-80' />
            </div>
        </div>
        </div>
    )
}

export default Header;