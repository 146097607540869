import React, { useEffect, useState } from 'react';
import Dashboard from './dashboard';
import Header from '../header/header';
import { Navigate, useNavigate } from 'react-router-dom';
import UserProfileSidebar from './userProfileModal';
import Sidebar from '../sidebar/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import ADManagement from './ADManagement';
import MDManagement from './MDManagement';
import UserService from '../services/user';
import WalletService from '../services/wallet';
import SubscriberDetails from '../subscriber-details';
import WithdrawalRequests from '../withdraw-money/withdrawalRequests';
import { updateUser } from '../slices/userSlice';

const MainDashboard = ({ selectedAdminSubmenu, setSelectedAdminSubmenu, selectedDistributor, setSelectedDistributor }) => {
  const [subscribers, setSubscribers] = useState([]);
  const [subscribersLoading, setSubscribersLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebarCollapsed');
    return savedState === 'true';
  });
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsPerPage: 10,
    sortConfig: { key: null, direction: 'ascending' },
  });
  const [subscriberDetailsOpen, setSubscriberDetailsOpen] = useState(true);

  const [dropdownQuery, setDropdownQuery] = useState('name');
  const [searchInput, setSearchInput] = useState('');
  const [mdDistributors, setMdDistributors] = useState([]);
  const [mdDistributorsLoading, setMdDistributorsLoading] = useState(false);
  const [adDistributors, setAdDistributors] = useState([]);
  const [adDistributorsLoading, setAdDistributorsLoading] = useState(false);
  const [totalDistributors, setTotalDistributors] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchMdPortalData = async (userId) => {
      try {
        setSubscribersLoading(true);
        const queryParams = `?skip=0&limit=20&${dropdownQuery}=${searchInput}`;
        const response = await new UserService().getMdPortalDistributorsData(userId, queryParams);
        setSubscribersLoading(false);

        const updatedSubscribersData = [];
        response.users.forEach((item) => {
          updatedSubscribersData.push({
            ...item,
            name: item.firstName + ' ' + item.lastName,
            number: item.contactNumber,
          });
        });
        setSubscribersLoading(false);
        setSubscribers( updatedSubscribersData);
      } catch (error) {
        console.error('Error fetching subscribers data:', error);
      }
    };
    user?.roleId?.name === 'Master Distributor' && fetchMdPortalData(user?._id);

    const fetchAdPortalData = async (userId) => {
      try {
        setSubscribersLoading(true);
        const queryParams = `?skip=0&limit=20&${dropdownQuery}=${searchInput}`;
        const response = await new WalletService().getActiveSubscribersData(userId, queryParams);
        setSubscribersLoading(false);
        const updatedSubscribersData = [];
        response.subscribersList.forEach((item) => {
          updatedSubscribersData.push({
            ...item,
            name: item.firstName + ' ' + item.lastName,
            number: item.contactNumber,
          });
        });
        setSubscribersLoading(false);
        setSubscribers( updatedSubscribersData );
      } catch (error) {
        console.error('Error fetching subscribers data:', error);
      } finally {
        setSubscribersLoading(false);
      }
    };
    user?.roleId?.name === 'Authorized Distributor' && fetchAdPortalData(user?._id);
  }, []);

  useEffect(() => {
    if (!selectedAdminSubmenu) {
      setSelectedAdminSubmenu(
        user?.roleId?.name === 'Admin'
          ? 'MD Management'
          : user?.roleId?.name === 'Master Distributor'
            ? 'AD Management'
            : user?.roleId?.name === 'Authorized Distributor'
              ? 'Subscriber Management'
              : null
      );
    }
  }, []);

  useEffect(() => {
    handleSearch();
  }, [pagination]);

  const handleSearch = () => {
    if (user?.roleId?.name === 'Admin' && selectedAdminSubmenu === 'MD Management') {
      const fetchUserData = async () => {
        setMdDistributorsLoading(true);
        try {
          const defaultQueryParams = `?skip=${
            (pagination.currentPage - 1) * pagination.rowsPerPage
          }&limit=${
            pagination.rowsPerPage
          }&role=Master Distributor&${dropdownQuery}=${dropdownQuery === "status" && "information submitted".includes(searchInput?.toLowerCase()) ? 'information_submitted' : searchInput}`;
          const response = await new UserService().getDistributorsData(defaultQueryParams);
          setTotalDistributors(response.totalCount);
          setMdDistributors(response.distributorsList);
        } catch (err) {
          console.error('Error fetching user data:', err);
        } finally {
          setMdDistributorsLoading(false);
        }
      };
      fetchUserData();
    } else if (user?.roleId?.name === 'Admin' && selectedAdminSubmenu === 'AD Management') {
      const fetchUserData = async () => {
        setAdDistributorsLoading(true);
        try {
          const defaultQueryParams = `?skip=${
            (pagination.currentPage - 1) * pagination.rowsPerPage
          }&limit=${
            pagination.rowsPerPage
          }&role=Authorized Distributor&${dropdownQuery}=${dropdownQuery === "status" && "information submitted".includes(searchInput?.toLowerCase()) ? 'information_submitted' : searchInput}`;
          const response = await new UserService().getDistributorsData(defaultQueryParams);
          setTotalDistributors(response.totalCount);
          setAdDistributors(response.distributorsList);
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setAdDistributorsLoading(false);
        }
      };
      fetchUserData();
    } else if (user?.roleId?.name === 'Master Distributor') {
      const fetchUserData = async (userId, skipQueryParam) => {
        try {
          setSubscribersLoading(true);
          const defaultQueryParams = `?skip=${
            (pagination.currentPage - 1) * pagination.rowsPerPage
          }&limit=${pagination.rowsPerPage}&${dropdownQuery}=${searchInput}`;
          const response = await new UserService().getMdPortalDistributorsData(
            userId,
            defaultQueryParams
          );
          setSubscribersLoading(false);
          const updatedSubscribersData = response.users;
          setSubscribers( updatedSubscribersData );
          setTotalDistributors(response.totalCount);
        } catch (error) {
          console.error('Error fetching subscribers data:', error);
        } finally {
          setSubscribersLoading(false);
        }
      };
      fetchUserData(user?._id);
    } else if (user?.roleId?.name === 'Authorized Distributor') {
      const fetchUserData = async (userId) => {
        try {
          setSubscribersLoading(true);
          const defaultQueryParams = `?skip=${
            (pagination.currentPage - 1) * pagination.rowsPerPage
          }&limit=${pagination.rowsPerPage}&${dropdownQuery}=${searchInput}`;
          const response = await new WalletService().getActiveSubscribersData(
            userId,
            defaultQueryParams
          );
          setSubscribersLoading(false);
          const updatedSubscribersData = [];
          response.subscribersList.forEach((item) => {
            updatedSubscribersData.push({
              ...item,
              name: item.firstName + ' ' + item.lastName,
              number: item.contactNumber,
            });
          });
          setSubscribers( updatedSubscribersData );
          setTotalDistributors(response.totalCount);
        } catch (error) {
          console.error('Error fetching subscribers data:', error);
        } finally {
          setSubscribersLoading(false);
        }
      };
      fetchUserData(user._id);
    }
  };

  const handleSearchInput = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleAddSubscriber = (newSubscribers) => {
    setSubscribers(newSubscribers);
    localStorage.setItem('subscribers', JSON.stringify(newSubscribers));
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    localStorage.setItem('sidebarCollapsed', !collapsed);
  };

  const toggleProfileSidebar = () => {
    setProfileMenuOpen((prev) => !prev);
  };

  const handleLogout = () => {
    dispatch(updateUser({...user, isLoggedIn: false}));
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('user');
    if (user?.roleId?.name === "Admin") {
      navigate('/admin');
    }
    else {
      navigate('/');
    }
  };

  return (
    <div className="flex h-screen ">
      <>
          <>
            <Sidebar
              className="fixed left-0"
              collapsed={collapsed}
              toggleSidebar={toggleSidebar}
              selectedAdminSubmenu={selectedAdminSubmenu}
              setSelectedAdminSubmenu={setSelectedAdminSubmenu}
            />

            <div
              className={`flex-grow overflow-y-auto flex flex-col ${collapsed ? 'ml-2' : 'ml-3'}`}
            >
              <div
                className={`fixed ${collapsed ? 'w-[95%]' : 'headerWrapperCalcWidth'} top-0 z-10`}
              >
                <Header
                  collapsed={collapsed}
                  toggleProfileSidebar={toggleProfileSidebar}
                  handleSearch={handleSearch}
                  dropdownQuery={dropdownQuery}
                  setDropdownQuery={setDropdownQuery}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  handleSearchInput={handleSearchInput}
                  subscriberDetailsOpen={subscriberDetailsOpen}
                  setSubscriberDetailsOpen={setSubscriberDetailsOpen}
                />
              </div>

              <div className="mt-16  ">
                {user?.roleId?.name !== 'Admin' ? (
                  <>
                    {subscriberDetailsOpen ? (
                      <SubscriberDetails />
                    ) : (
                      <Dashboard
                        subscribers={subscribers}
                        setSubscribers={setSubscribers}
                        subscribersLoading={subscribersLoading}
                        searchTerm={searchTerm}
                        onAddSubscriber={handleAddSubscriber}
                        totalDistributors={totalDistributors}
                        pagination={pagination}
                        setPagination={setPagination}
                        setSubscriberDetailsOpen={setSubscriberDetailsOpen}
                      />
                    )}
                  </>
                ) : selectedAdminSubmenu === 'AD Management' ? (
                  <ADManagement
                    subscribers={subscribers}
                    setSubscribers={setSubscribers}
                    searchTerm={searchTerm}
                    onAddSubscriber={handleAddSubscriber}
                    distributors={adDistributors}
                    setDistributors={setAdDistributors}
                    setDropdownQuery={setDropdownQuery}
                    setSearchInput={setSearchInput}
                    adDistributorsLoading={adDistributorsLoading}
                    totalDistributors={totalDistributors}
                    setTotalDistributors={setTotalDistributors}
                    pagination={pagination}
                    setPagination={setPagination}
                    selectedDistributor={selectedDistributor}
                    setSelectedDistributor={setSelectedDistributor}
                  />
                ) : selectedAdminSubmenu === 'MD Management' ? (
                  <MDManagement
                    subscribers={subscribers}
                    setSubscribers={setSubscribers}
                    searchTerm={searchTerm}
                    onAddSubscriber={handleAddSubscriber}
                    distributors={mdDistributors}
                    setDistributors={setMdDistributors}
                    setDropdownQuery={setDropdownQuery}
                    setSearchInput={setSearchInput}
                    mdDistributorsLoading={mdDistributorsLoading}
                    totalDistributors={totalDistributors}
                    setTotalDistributors={setTotalDistributors}
                    pagination={pagination}
                    setPagination={setPagination}
                    selectedDistributor={selectedDistributor}
                    setSelectedDistributor={setSelectedDistributor}
                  />
                ) : (
                  <WithdrawalRequests setSelectedAdminSubmenu={setSelectedAdminSubmenu} />
                )}
              </div>
            </div>
          </>   
      </>
      <UserProfileSidebar
        handleLogout={handleLogout}
        profileMenuOpen={profileMenuOpen}
        toggleProfileSidebar={toggleProfileSidebar}
      />
    </div>
  );
};

export default MainDashboard;
