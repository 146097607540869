import React, { useEffect, useState } from 'react';
import IdentityDetails from './indentifyDetails';
import AddressDetails from './addressDetails.js';
import FileUpload from './fileUpload.js';
import BankDetails from './bankDetails.js';
import Declaration from './declaration.js';
import { ApproveModal, RejectModal } from './Modals.js';
import { useParams } from 'react-router-dom';
import UserService from '../services/user.js';
import { AddADFormData } from '../constants/index.js';
import TitleLogo from '../images/tittle-logo.svg';
import Utils from '../utility';

const AdApplicationReview = ({ setSelectedAdminSubmenu, setSelectedDistributor }) => {
  const [tabDetails, setTabDetails] = useState([
    'Identity details',
    'Address details',
    'File upload',
    'Bank Details',
    'Declaration',
  ]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabValidation, setTabValidation] = useState(0);
  const params = useParams();
  const [formData, setFormData] = useState(AddADFormData);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  const handleReviewStatus = async (selectedStatus) => {
    if (selectedStatus === 'Active') {
      try {
        const payload = { userId: params.id, status: 'Active' };
        const res = await new UserService().reviewDisitributorStatus(payload);
        if (res.success) { Utils.successToastMessage('Status updated successfully'); }
        if (res) {
          setTabValidation(5);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setRejectModalOpen(true);
    }
  };

  const handleRejectModalClose = () => {
    setRejectModalOpen(false);
  };

  useEffect(() => {
    const fetchDistributorDetails = async () => {
      try {
        const res = await new UserService().getDistributorsDetails(params.id);
        setFormData({ ...formData, ...res });
      } catch (error) {
        console.error('error while fetching distributor details', error);
      }
    };

    fetchDistributorDetails();
  }, []);

  return (
    <div className="flex flex-col items-center gap-[30px] bg-[#F1F2F2] py-[30px] relative min-h-screen">
      <div className="flex justify-center items-center pt-[30px] bg-[#F1F2F2] z-50">
        <img src={TitleLogo} alt="Logo" className="absolute left-[50px] top-[40px] w-[170px]" />

        {rejectModalOpen && <RejectModal onClose={handleRejectModalClose} />}

        {tabValidation !== 5 ? (
          <div className="flex flex-col items-center gap-[10px] min-w-[800px] w-[35%] p-[20px] box-border">
            <div className="min-w-[650px] w-full flex items-center">
              {tabDetails.map((item, index) => (
                <p
                  className={`min-w-[156px] w-[25%] text-[#5A5E5F] text-[16px] font-normal text-center relative cursor-pointe font-jakarta cursor-pointer ${selectedTab === index ? 'text-[#1D2224] font-semibold border-bottom-line' : ''
                    }`}
                  key={index}
                  onClick={() => setSelectedTab(index)}
                >
                  {item}
                </p>
              ))}
            </div>
            <div className="h-[4px] w-full bg-[#DDDEDE] rounded-[14px]"></div>
            <div className="w-[384px] mt-[50px] flex justify-center font-jakarta">
              {selectedTab === 0 && (
                <IdentityDetails
                  setSelectedTab={setSelectedTab}
                  setTabValidation={setTabValidation}
                  tabValidation={tabValidation}
                  formData={formData}
                />
              )}
              {selectedTab === 1 && (
                <AddressDetails
                  setSelectedTab={setSelectedTab}
                  setTabValidation={setTabValidation}
                  tabValidation={tabValidation}
                  formData={formData}
                />
              )}
              {selectedTab === 2 && (
                <FileUpload
                  setSelectedTab={setSelectedTab}
                  setTabValidation={setTabValidation}
                  tabValidation={tabValidation}
                  formData={formData}
                />
              )}
              {selectedTab === 3 && (
                <BankDetails
                  setSelectedTab={setSelectedTab}
                  setTabValidation={setTabValidation}
                  tabValidation={tabValidation}
                  formData={formData}
                />
              )}
              {selectedTab === 4 && (
                <Declaration
                  setSelectedTab={setSelectedTab}
                  setTabValidation={setTabValidation}
                  formData={formData}
                  handleReviewStatus={handleReviewStatus}
                />
              )}
            </div>
          </div>
        ) : (
        <ApproveModal formData={formData} setSelectedAdminSubmenu={setSelectedAdminSubmenu} setSelectedDistributor={setSelectedDistributor} />
        )}
      </div>
    </div>
  );
};

export default AdApplicationReview;
