import React, { useState } from 'react';
import InformationWrapper from '../common/InformationWrapper';
import CenterWrapper from '../common/CenterWrapper';
import { FormTabs } from '../constants';

function FormDashboard({
  user,
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) {
  const completed = [];
  const incomplete = [];
  console.log("formData",formData);
  

  // const [formData, setFormData] = useState({
  //   proofOfIdentity: ['ID123456'], // Array for identity details (dummy data)
  //   proofOfAddress: ['123 Main St, City'], // Array for proof of address (dummy data)
  //   bankName: '', // Empty to simulate incomplete payment details
  //   accountNumber: '',
  //   routingNumber: '',
  //   signature: '', // Empty to simulate incomplete declaration
  //   isTermsAccepted: false,
  // });
  // Check each tab in FormTabs and compare with formData
  FormTabs.forEach((tab) => {
    switch (tab.text) {
      case 'Identity details':
        if (formData?.proofOfIdentity?.length > 0) {
          completed.push(tab);
        } else {
          incomplete.push(tab);
        }
        break;

      case 'Proof of address':
        if (formData?.proofOfAddress?.length > 0) {
          completed.push(tab);
        } else {
          incomplete.push(tab);
        }
        break;

      case 'Payment details':
        if (formData?.bankName && formData?.accountNumber && formData?.routingNumber) {
          completed.push(tab);
        } else {
          incomplete.push(tab);
        }
        break;

      case 'Declaration':
        if (formData?.signature && formData?.isTermsAccepted) {
          completed.push(tab);
        } else {
          incomplete.push(tab);
        }
        break;

      default:
        break;
    }
  });

  // Title based on form progress
  const isFirstTime = completed.length === 0;
  return (
    <>
      <CenterWrapper>
        <div className=" w-full flex flex-col gap-8">
          <div className="w-full flex flex-col gap-2 ">
            <h3 className="font-extrabold text-[39px]  leading-[50.7px] -tracking-[0.5%] text-[#1D2224]">
              {isFirstTime ? 'Fill the form to operate all features of ezmobile' : 'Welcome back!'}
            </h3>
            <p className="text-base leading-6 font-normal text-[#6C6F71]">
              {isFirstTime
                ? 'Please provide your information to operate ezmobile features. Tap to continue.'
                : 'Fill the form to operate all features of ezmobile'}
            </p>
          </div>
          {/* Display "Need to complete" sections */}
          {incomplete.length > 0 && (
            <div className="flex flex-col gap-4">
              <p className="font-normal text-base leading-6 text-[#6C6F71]">Need to complete</p>
              <div className="grid grid-cols-2 gap-4">
                {incomplete.map(({ text, icon }) => (
                  <InformationWrapper key={icon} text={text} icon={icon} />
                ))}
              </div>
            </div>
          )}

          {/* Display "Completed" sections */}
          {completed.length > 0 && (
            <div className="flex flex-col gap-4">
              <p className="font-normal text-base leading-6 text-[#6C6F71]">Completed</p>
              <div className="grid grid-cols-2 gap-4">
                {completed.map(({ text, icon }) => (
                  <InformationWrapper key={icon} text={text} icon={icon} completed />
                ))}
              </div>
            </div>
          )}
          <button
            onClick={() => setSelectedTab((prev) => prev + 1)}
            className="flex justify-center items-center rounded-3xl py-3 bg-[#39B8E4]">
            <p className="font-bold text-base leading-6 cursor-pointer">Continue</p>
          </button>
        </div>
      </CenterWrapper>
    </>
  );
}

export default FormDashboard;
