import React, { useState } from 'react';

const AddProductModal = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [portingType, setPortingType] = useState('active');

  const tabList = ['Product Details', 'Set Price', 'Set Commissions', 'Confirmation'];

  const [formData, setFormData] = useState({
    productName: '',
    description: '',
    price: '',
    adCommission: '',
    mdCommission: '',
    tether: 'Tether',
  });

  const handleTetherChange = (tether) => {
    setFormData({
      ...formData,
      tether,
    });
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const isActive = (step) => step === currentStep;

  const isStepCompleted = (step) => step < currentStep;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[900px] h-[841px] rounded-[24px] shadow-lg p-7">
        <div className="flex justify-end mb-4">
          <img onClick={onClose} className="h-6 w-6 cursor-pointer" src="./close.svg" alt="Close" />
        </div>

        <div className="relative mb-4">
          <div className="flex gap-4 justify-center overflow-x-auto">
            {tabList.map((tab, index) => (
              <div key={index} className="flex flex-col items-center">
                <button
                  className={`text-ft4 flex items-center py-2 px-3 font-medium ${
                    isActive(index + 1)
                      ? 'font-bold text-[#1D2224]'
                      : isStepCompleted(index + 1)
                        ? 'text-green-500'
                        : 'text-[#5A5E5F]'
                  }`}
                  disabled={index + 1 > currentStep}
                  onClick={() => setCurrentStep(index + 1)}
                >
                  {isStepCompleted(index + 1) && (
                    <img src="./check_circle.svg" alt="Completed" className="w-4 h-4 mr-2" />
                  )}
                  {tab}
                </button>
                {isActive(index + 1) && (
                  <div
                    style={{
                      height: '4px',
                      width: '100%',
                      backgroundColor: 'black',
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        {currentStep === 1 && (
          <div className="flex flex-col w-[351px] mx-auto justify-center">
            <div className="mb-4 flex flex-col items-start">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">1/4</span>
              <h2 className="text-xl font-bold">Add Product Details</h2>
            </div>
            <div className="flex bg-[#F1F2F2] rounded-[27px] p-1 items-center mb-4">
              <button
                className={`py-2 px-4 ${
                  portingType === 'active' ? 'bg-black rounded-[27px] text-white' : ''
                }`}
                onClick={() => setPortingType('active')}
              >
                Active
              </button>
              <button
                className={`py-2 px-4 ml-2 ${
                  portingType === 'inactive' ? 'bg-[#1D2224] rounded-[27px] text-white' : ''
                }`}
                onClick={() => setPortingType('inactive')}
              >
                Inactive
              </button>
            </div>
            <div className="mb-4">
              <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                Plan Name
              </label>
              <input
                type="text"
                className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Enter port in number"
              />
            </div>
            <div className="mb-4">
              <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                Description
              </label>
              <textarea
                className="w-full outline-none placeholder:text-[#999C9C] text-ft4 bg-[#F1F2F2] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Provide plan description with tether"
              />
              <span className="text-red-500 text-sm mt-1">* Tether</span>
            </div>
            <div className="mb-4">
              <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                Description
              </label>
              <textarea
                className="w-full outline-none placeholder:text-[#999C9C] text-ft4 bg-[#F1F2F2] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Provide plan description without tether"
              />
              <span className="text-red-500 text-sm mt-1">* No Tether</span>
            </div>

            <div className="mt-10 flex gap-4 justify-center">
              <button onClick={onClose} className=" text-[#1D2224] py-2 px-4 rounded">
                Cancel
              </button>
              <button
                onClick={handleNext}
                className="bg-[#0EA8DE] hover:bg-opacity-85 h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="flex flex-col w-[351px] h-[464px] mx-auto justify-center">
            <div className="mb-4 flex flex-col items-start">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">2/4</span>
              <h2 className="text-xl font-bold">Set Price</h2>
            </div>
            <div className="mb-4">
              <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                Currency
              </label>
              <select className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1">
                <option>USD</option>
                <option>EUR</option>
                <option>GBP</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                Price Amount
              </label>
              <input
                type="text"
                className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Enter price of the plan"
              />
            </div>
            <div className="mb-4">
              <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">Tax</label>
              <input
                type="text"
                className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Provide TAX amount"
              />
            </div>

            <div className="mt-10 flex gap-4 justify-center">
              <button onClick={handlePrev} className=" text-[#1D2224] py-2 px-4 rounded">
                Cancel
              </button>
              <button
                onClick={handleNext}
                className="bg-[#0EA8DE] hover:bg-opacity-85 h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div className="flex flex-col w-[351px] h-[464px] mx-auto justify-center">
            <div className="mb-4 flex flex-col items-start">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">3/4</span>
              <h2 className="text-xl font-bold">Set Commissions</h2>
            </div>
            <div className="mb-4">
              <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                Ad Commission
              </label>
              <input
                type="text"
                className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Enter AD commission"
              />
            </div>
            <div className="mb-4">
              <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">
                MD Commission
              </label>
              <input
                type="text"
                className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
                placeholder="Enter MD commission"
              />
            </div>

            <div className="mt-10 flex gap-4 justify-center">
              <button onClick={handlePrev} className=" text-[#1D2224] py-2 px-4 rounded">
                Cancel
              </button>
              <button
                onClick={handleNext}
                className="bg-[#0EA8DE] h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {currentStep === 4 && (
          <div className="flex flex-col w-[351px] h-[464px] mx-auto justify-center">
            <div className="mb-4 flex flex-col items-start">
              <span className="bg-[#F1F2F2] px-2 py-1 rounded-full">4/4</span>
              <h2 className="text-xl font-bold">Preview and confirm wireless plan</h2>
            </div>
            <div className="flex gap-2 mb-4">
              <button
                onClick={() => handleTetherChange('Tether')}
                className={`px-4 py-2 rounded-full ${formData.tether === 'Tether' ? 'bg-black text-white' : 'bg-[#F1F2F2] text-black'}`}
              >
                Tether
              </button>
              <button
                onClick={() => handleTetherChange('No Tether')}
                className={`px-4 py-2 rounded-full ${formData.tether === 'No Tether' ? 'bg-black text-white' : 'bg-[#F1F2F2] text-black'}`}
              >
                No Tether
              </button>
            </div>
            <div className="bg-[#F1F2F2] p-4 rounded-lg">
              <h3 className="text-lg font-bold">{formData.productName}</h3>
              <p>AD Price: ${formData.adCommission}</p>
              <p>{formData.description}</p>
              <p className="font-bold text-xl">${formData.price}</p>
            </div>
            <div className="mt-10 flex gap-4 justify-center">
              <button onClick={handlePrev} className="text-[#1D2224] py-2 px-4 rounded">
                Cancel
              </button>
              <button
                onClick={() => {
                  onClose();
                }}
                className="bg-[#0EA8DE] h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
              >
                Create Product
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddProductModal;
