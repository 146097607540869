import React from 'react';
import { SiZelle } from 'react-icons/si';
import { NavLink } from 'react-router-dom';

const WithdrawalMethod = ({ formData, setFormData, setSelectedTab, handleCancel }) => {
  return (
    <div className="flex flex-col gap-[32px] w-[35%]">
      <div className="flex flex-col">
        <span className="h-[32px] w-[47px] bg-white rounded-[35px] flex justify-center items-center">
          1/3
        </span>
        <h3 className="text-[#2F3436] text-[25px] font-extrabold mt-[4px]">Select Method</h3>
      </div>

      <div className="flex flex-col gap-[8px]">
        <div
          className={`h-[48px] w-full rounded-[12px] flex justify-center items-center gap-[5px] text-[#1D2224] text-[16px] font-semibold cursor-default ${formData.withdrawalMethod === 'Bank' ? 'bg-[#00D66F]' : 'bg-[#FDFDFD]'}`}
          onClick={() => setFormData({ ...formData, withdrawalMethod: 'Bank' })}
        >
          Withdraw to Bank
        </div>

        <div
          className={`h-[48px] w-full rounded-[12px] flex justify-center items-center text-[#1D2224] text-[16px] font-semibold cursor-default ${formData.withdrawalMethod === 'Zelle' ? 'bg-[#00D66F]' : 'bg-[#FDFDFD]'}`}
          onClick={() => setFormData({ ...formData, withdrawalMethod: 'Zelle' })}
        >
          Withdraw to <SiZelle className="relative left-[2px]" />
          elle
        </div>

        <div
          className={`h-[48px] w-full rounded-[12px] flex justify-center items-center gap-[5px] text-[#1D2224] text-[16px] font-semibold cursor-default ${formData.withdrawalMethod === 'Wire Transfer' ? 'bg-[#00D66F]' : 'bg-[#FDFDFD]'}`}
          onClick={() => setFormData({ ...formData, withdrawalMethod: 'Wire Transfer' })}
        >
          Wire Transfer
        </div>
      </div>

      <div className="flex justify-center items-center gap-[8px] mt-[10px]">
        <NavLink
          to="/dashboard"
          className="flex justify-center items-center h-[48px] w-[50%] text-[#1D2224] rounded-xl font-semibold"
          onClick={handleCancel}
        >
          Cancel
        </NavLink>
        <button
          className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[50%] text-white rounded-xl font-semibold disabled:cursor-not-allowed"
          disabled={!formData?.withdrawalMethod}
          onClick={() => setSelectedTab(1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default WithdrawalMethod;
