import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

function UserProfileComponent() {
  const navigate = useNavigate();
  const [walletDetails, setWalletDetails] = useState({});
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      fetchWalletDetails(user._id);
    }
  }, []);

  const fetchWalletDetails = async (userId) => {
    try {
      const response = await axios.get(`https://d3jlqgqxlcr546.cloudfront.net/wallet/${userId}`);
      setWalletDetails(response.data.responseData);
    } catch (error) {
      console.error('Error fetching wallet details:', error);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-white font-jakarta p-6 h-screen flex flex-col items-center">
      <div className="flex w-full justify-between items-center mb-8 mt-6">
        <img
          onClick={() => navigate('/dashboard')}
          src="/tittle-logo.svg"
          alt="EZMobile"
          className="h-10 cursor-pointer"
        />
      </div>
      <div className="flex flex-col justify-center w-[480px]">
        <div className="flex items-center gap-4 mb-9 justify-start">
          <button onClick={handleGoBack} className="h-4 w-4">
            <img src="/arrow-back.svg" alt="back" className="h-4 w-4" />
          </button>
          <p className="text-[#1D2224] text-[31px] font-extrabold">
            {user?.name?.split(' ')?[0] : ''}'s Profile
          </p>
        </div>
        <div className="bg-[#F1F2F2] p-4 rounded-lg w-full">
          <div className="flex flex-col">
            <div className="flex flex-col gap-1">
              <p className="text-gray-500 uppercase text-sm">Balance</p>
              <p className="text-2xl font-bold">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(walletDetails.balance || 0)}
              </p>
              <p className="text-gray-500 text-sm">
                Last Deposit: $
                {walletDetails.lastDeposit
                  ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(walletDetails.lastDeposit)
                  : '0.00'}
              </p>
            </div>
            <div className="flex mt-10 w-full gap-4 justify-center">
              <Link
                to="/add-money"
                className="bg-[#0EA8DE] flex justify-center items-center h-[48px] w-[216px] text-white py-2 px-4 rounded-xl"
              >
                Add Cash
              </Link>
              <button className="bg-white  text-[#1D2224] w-[216px] py-2 px-4 rounded-xl">
                View Transactions
              </button>
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col mt-8">
          <div className="flex items-center gap-6">
            {user && user?.image ? (
              <img
                src={user?.image}
                alt={user?.name}
                className="w-[70px] h-[70px] object-cover cursor-pointer"
              />
            ) : (
              <div className="w-[80px] h-[80px] bg-[#F1F2F2] rounded-full flex items-center justify-center">
                {user?.name
                  ? `${user?.name.substring(0, 1).toUpperCase()}${user?.name
                      .split(' ')
                      .map((word) => word[0])
                      .join('')
                      .substring(1, 2)
                      .toUpperCase()}`
                  : ''}
              </div>
            )}
            <div className="flex items-center mt-4 gap-1 bg-[#F1F2F2] px-6 py-3 h-[48px] rounded-[24px] w-fit">
              <img
                src="/edit-profile.svg"
                alt="User Profile"
                className="w-[22px] h-[20px] object-cover cursor-pointer"
              />
              <p className="text-[#1d2224] cursor-pointer text-ft13 font-semibold">Edit Profile</p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            {user && (
              <h2 className="text-[25px] font-semibold mb-2">{user?.name || 'W. Maximwolf'}</h2>
            )}
          </div>
          {user && user?.roleId && (
            <div className="text-[#064C64] bg-[#C5EAF7] w-fit px-4 py-1 rounded-[16px]">
              {user?.roleId?.name}
            </div>
          )}
          <div className="mt-4">
            <p className="text-gray-500">EMAIL</p>
            <p className="text-gray-700">{user.email || 'maximwolf@gmail.com'}</p>
          </div>
          <div className="mt-2">
            <p className="text-gray-500">PHONE</p>
            <p className="text-gray-700">+1 123 456 789</p>
          </div>
          <div className="mt-2">
            <p className="text-gray-500">ADDRESS</p>
            <p className="text-gray-700">123 Main St, Anytown USA</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfileComponent;
