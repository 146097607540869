import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '../utility';
import { removeUser } from '../slices/userSlice';
import WalletService from '../services/wallet';
import { TbLoader3 } from 'react-icons/tb';
import { toast } from 'react-toastify';

const WithdrawalAmount = ({
  formData,
  setFormData,
  setSelectedTab,
  setWithdrawalSuccessModalOpen,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [walletDetails, setWalletDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchWalletDetails() {
      try {
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }

        const walletDetails = await new WalletService().getUserWallet(user?._id);

        setWalletDetails(walletDetails);
      } catch (error) {
        console.error(error);
      }
    }
    fetchWalletDetails();
  }, [dispatch, user?._id]);

  const handleTransferAmount = async () => {
    if (formData.withdrawalAmount) {
      const payload = {
        userId: user._id,
        amount: formData?.withdrawalAmount,
        paymentMethod: "Bank",
        details: "Withdrawal request",
      };

      try {
        setLoading(true);
        const response = await new WalletService().withdrawMoney(payload);
        if (response) {
          setLoading(false);
          setWithdrawalSuccessModalOpen(true);
        } else {
          setLoading(false);
          toast.error(response?.message, 4000);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.message, 4000);
      }
    } else {
      alert('Please enter an amount to withdraw.');
    }
  };

  return (
    <div className="flex flex-col gap-[32px] w-[35%]">
      <div className="flex flex-col">
        <span className="h-[32px] w-[47px] bg-white rounded-[35px] flex justify-center items-center">
          3/3
        </span>
        <h3 className="text-[#2F3436] text-[25px] font-extrabold mt-[4px]">Enter Withdrawal</h3>
        <p className="text-[#1D2224] text-[16px] font-normal">
          Funds typically arrive within 3 days
        </p>
      </div>

      <div className="flex flex-col gap-[8px]">
        <input
          type="number"
          placeholder="Enter amount"
          value={formData.withdrawalAmount}
          onChange={(e) => setFormData({ ...formData, withdrawalAmount: e.target.value })}
          className="h-[48px] w-full p-[12px] rounded-[12px] bg-[#FDFDFD] placeholder:text-[#999C9C] outline-none"
        />
        <p className="text-[#1D2224] text-[16px] font-normal">
          Available for withdrawal: ${walletDetails?.balance}
        </p>
      </div>

      <div className="flex justify-center items-center gap-[8px] mt-[10px]">
        <button
          className="flex justify-center items-center h-[48px] w-[50%] rounded-[12px] bg-[#DDDEDE] hover:bg-opacity-80 text-[#1D2224]"
          onClick={() => setSelectedTab(1)}
        >
          Previous
        </button>

        <button
          className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[50%] text-white rounded-xl font-semibold"
          onClick={handleTransferAmount}
        >
          {loading ? <TbLoader3 className="animate-spin w-7 h-7 text-white" /> : "Withdraw"}
        </button>
      </div>
    </div>
  );
};

export default WithdrawalAmount;
