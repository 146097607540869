import React from 'react';
import { MdDone } from "react-icons/md";

function InformationWrapper({ text, icon, alt, completed }) {
  const backgroundColorClass = completed ? 'bg-[#DFE0E2]' : 'bg-[#E7F6FC]';

  return (
    <div
      className={`max-w-[232px] cursor-pointer flex justify-between items-center w-full rounded-2xl p-4 ${backgroundColorClass}`}
    >
      <p className="font-semibold text-base leading-6 font-jakarta text-[#1D2224]">{text}</p>
      {completed ? <MdDone className='text-[#19C84D] w-6 h-6' /> :  <img src={icon} alt={alt} />}
    </div>
  );
}

export default InformationWrapper;
