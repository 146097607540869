import React, { useState, useEffect } from 'react';
import SubscriberTableComp from './subscriberTable';
import AddAdModal from '../popups/addAdModal';
import AddDMModal from '../popups/addDMModal';
import AddSubscriberModal from '../popups/addSubscriberModal';
import ADTableComp from './AdTable';
// import DistributorApproval from "../popups/DMdetailsModal";
// import PendingAD from "../popups/pendingAD";
import DMTableComp from './DMTable';
import Utils from '../utility';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../slices/userSlice';
import WalletService from '../services/wallet';

const Dashboard = ({
  subscribers,
  setSubscribers,
  subscribersLoading,
  searchTerm,
  onAddSubscriber,
  totalDistributors,
  pagination,
  setPagination,
  setSubscriberDetailsOpen,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [adminCardsData, setAdminCardsData] = useState({
    totalSubscribers: 0,
    orders: 0,
    conversionRate: 0,
  });
  const [subscriberManagementData, setSubscriberManagementData] = useState([
    { title: 'Total Subscribers', subValue: 'All time', value: 0 },
    { title: 'Earnings', subValue: 'This week', value: '$0' },
    { title: 'New Subscribers', subValue: 'Weekly Report', value: '0' },
  ]);
  const [MDCardsData, setMDCardsData] = useState([
    { title: 'Total AD', subValue: 'All time', value: 0 },
    { title: 'Earnings', subValue: 'This week', value: '$0' },
    { title: 'Total Subscribers', subValue: 'Weekly Report', value: 0 },
  ]);

  useEffect(() => {
    switch (user?.roleId?.name) {
      case 'Authorized Distributor':
        setButtonText('Add Subscriber');
        break;
      case 'Master Distributor':
        setButtonText('Add New AD');
        break;
      case 'Admin':
        setButtonText('Add Master Distributor');
        break;
      default:
        setButtonText('');
    }
  }, [user?.roleId?.name]);

  useEffect(() => {
    async function fetchData() {
      try {
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }

        if (user?.roleId?.name === 'Authorized Distributor') {
          const response = await new WalletService().fetchDistributorSummary(user?._id);

          setSubscriberManagementData([
            {
              title: 'Total Subscribers',
              subValue: 'All time',
              value: response.totalSubscribersCount,
            },
            {
              title: 'Earnings',
              subValue: 'This week',
              value: `$${response.earnings}`,
            },
            {
              title: 'New Subscribers',
              subValue: 'Weekly Report',
              value: response.weeklySubscribersCount,
            },
          ]);
        } else if (user?.roleId?.name === 'Master Distributor') {
          const response = await new WalletService().fetchDistributorSummary(user?._id);

          setMDCardsData([
            {
              title: 'Total AD',
              subValue: 'All time',
              value: response?.activeAuthorizedDistributorsCount,
            },
            {
              title: 'Earnings',
              subValue: 'This week',
              value: `$${response?.earnings}`,
            },
            {
              title: 'Total Subscribers',
              subValue: 'Weekly Report',
              value: response?.weeklySubscribersCount,
            },
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [dispatch, user?._id, user?.roleId?.name]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (user?.roleId?.name === 'Admin') {
          // CHECKING IF TOKEN IS EXPIRE OR NOT
          if (Utils.checkTokenExpireOrNot()) {
            dispatch(removeUser({}));
            return;
          }

          const subscribersResponse = await new WalletService().getWeeklySubscribersSummary();
          const ordersResponse = await new WalletService().getWeeklyOrdersSummary();

          setAdminCardsData((prevData) => ({
            ...prevData,
            orders: ordersResponse?.totalAmount,
            totalSubscribers: subscribersResponse?.count,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [dispatch, user?.roleId?.name]);

  useEffect(() => {
    setPagination({
      currentPage: 1,
      rowsPerPage: 10,
      sortConfig: { key: null, direction: 'ascending' },
    });
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddSubscriber = (newSubscriber) => {
    const updatedSubscribers = [...subscribers, newSubscriber].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    onAddSubscriber(updatedSubscribers);
  };

  const renderAdminCards = () => (
    <div className="grid grid-cols-3 gap-6 mb-4">
      {[
        { title: 'Total Subscribers', value: adminCardsData.totalSubscribers },
        { title: 'Orders', value: `$${adminCardsData.orders}` },
        // { title: 'Conversion Rate', value: `${adminCardsData.conversionRate}%`,  }
      ].map((card, index) => (
        <div key={index} className="bg-white px-6 py-6 rounded-[24px] flex justify-between">
          <div className="flex flex-col">
            <span className="text-lg font-semibold text-[#1D2224]">{card.title}</span>
            <span className="text-sm font-normal text-[#6C6F71]">Weekly Report</span>
            <div className="text-2xl font-bold mt-6">{card.value}</div>
          </div>
          {/* <div>
            <img src={card.imgSrc} alt={card.title} className="" />
          </div> */}
        </div>
      ))}
    </div>
  );

  const renderADCards = () => (
    <div className="grid grid-cols-3 gap-6 mb-4">
      {subscriberManagementData.map((card, index) => (
        <div key={index} className="bg-white px-6 py-6 rounded-[24px] flex justify-between">
          <div className="flex justify-between w-full ">
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-[#1D2224]">{card.title}</span>
              <span className="text-sm font-normal text-[#6C6F71]">{card.subValue}</span>
            </div>
            <div className="text-[31px] font-jakarta text-[#1D2224] font-extrabold ">
              {card.value}
            </div>
          </div>
          {/* <div>
              <img src={card.imgSrc} alt={card.title} className="" />
            </div> */}
        </div>
      ))}
    </div>
  );

  const renderMDCards = () => (
    <div className="grid grid-cols-3 gap-6 mb-4">
      {/* , imgSrc: '/chart.svg' */}
      {MDCardsData.map((card, index) => (
        <div key={index} className="bg-white px-6 py-6 rounded-[24px] flex justify-between">
          <div className="flex justify-between w-full ">
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-[#1D2224]">{card.title}</span>
              <span className="text-sm font-normal text-[#6C6F71]">{card.subValue}</span>
            </div>
            <div className="text-[31px] font-jakarta text-[#1D2224] font-extrabold ">
              {card.value}
            </div>
          </div>
          {/* <div>
              <img src={card.imgSrc} alt={card.title} className="" />
            </div> */}
        </div>
      ))}
    </div>
  );

  const getTitle = () => {
    if (user) {
      switch (user?.roleId?.name) {
        case 'Admin':
          return 'Distributor Management';
        case 'Master Distributor':
          return 'AD Management';
        case 'Authorized Distributor':
          return 'Subscriber Management';
        default:
          return user?.roleId?.name;
      }
    }
    return '';
  };

  return (
    <div className="p-4 z-10 font-jakarta flex-grow min-h-screen bg-[#F1F2F2]">
      <div className="flex flex-col">
        <div className="flex mobile:flex-col items-center justify-between">
          <div className="flex p-4 flex-col">
            <h2 className="md:text-[39px] text-[22px] font-extrabold">{getTitle()}</h2>
            {/* <span className="text-[#6C6F71] font-normal">
              Manage Distributor and Aut.
            </span> */}
          </div>
          {buttonText && (
            <button
              className="bg-black hover:bg-opacity-85 text-white px-5 mobile:w-full py-2 h-12 rounded-[24px]"
              onClick={handleOpenModal}
            >
              + {buttonText}
            </button>
          )}
        </div>
        {user && user?.roleId?.name === 'Authorized Distributor' && renderADCards()}
        {user && user?.roleId?.name === 'Master Distributor' && renderMDCards()}
        {user && user?.roleId?.name === 'Admin' && renderAdminCards()}
        {user && user?.roleId?.name === 'Master Distributor' && (
          <ADTableComp
            user={user}
            subscribers={subscribers}
            searchTerm={searchTerm}
            distributors={subscribers}
            setDistributors={setSubscribers}
            subscribersLoading={subscribersLoading}
            totalDistributors={totalDistributors}
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
        {user && user?.roleId?.name === 'Authorized Distributor' && (
          <SubscriberTableComp
            subscribers={subscribers}
            setSubscribers={setSubscribers}
            subscribersLoading={subscribersLoading}
            userId={user._id}
            searchTerm={searchTerm}
            totalDistributors={totalDistributors}
            pagination={pagination}
            setPagination={setPagination}
            setSubscriberDetailsOpen={setSubscriberDetailsOpen}
          />
        )}
        {user && user?.roleId?.name === 'Admin' && (
          <DMTableComp subscribers={subscribers} searchTerm={searchTerm} />
        )}
      </div>

      {isModalOpen && user?.roleId?.name === 'Authorized Distributor' && (
        <AddSubscriberModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleAddSubscriber}
          existingSubscribers={subscribers}
        />
      )}

      {isModalOpen && user?.roleId?.name === 'Master Distributor' && (
        <AddAdModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleAddSubscriber} />
      )}

      {isModalOpen && user?.roleId?.name === 'Admin' && (
        <AddDMModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleAddSubscriber} />
      )}
    </div>
  );
};

export default Dashboard;
