import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ collapsed, toggleSidebar, selectedAdminSubmenu, setSelectedAdminSubmenu }) => {
  const user = useSelector((state) => state.user);
  const [selected, setSelected] = useState(
    selectedAdminSubmenu
      ? selectedAdminSubmenu
      : window.location.pathname === '/earning'
        ? 'Earnings'
        : window.location.pathname === '/transactions'
          ? 'Transactions'
          : window.location.pathname === '/product'
            ? 'Product Catalogue'
            : user?.roleId?.name === 'Admin'
              ? selectedAdminSubmenu
              : user?.roleId?.name === 'Master Distributor'
                ? 'AD Management'
                : 'Subscriber Management'
  );
  const [hovered, setHovered] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/dashboard') {
      setSelected(selectedAdminSubmenu);
      localStorage.setItem('selectedAdminSubmenu', selectedAdminSubmenu);
    }
  }, [selectedAdminSubmenu]);

  const roleBasedMenus = {
    'Authorized Distributor': [
      { name: 'Subscriber Management', icon: '/management.svg', isRole: true, path: '/dashboard' },
      { name: 'Product Catalogue', icon: '/category.svg', path: '/product' },
      { name: 'Transactions', icon: '/doller.svg', path: '/transactions' },
      { name: 'Earnings', icon: '/doller.svg', path: '/earning' },
      // { name: 'User Management', icon: '/badge.svg', path: '/dashboard' },
      // { name: 'Earnings', icon: '/doller.svg', path: '/earning' },
      // { name: 'Support', icon: '/support.svg', path: '/dashboard' }
    ],
    'Master Distributor': [
      { name: 'AD Management', icon: '/management.svg', isRole: true, path: '/dashboard' },
      { name: 'Product Catalogue', icon: '/category.svg', path: '/product' },
      { name: 'Transactions', icon: '/doller.svg', path: '/transactions' },
      { name: 'Earnings', icon: '/doller.svg', path: '/earning' },
      // { name: 'User Management', icon: '/badge.svg', path: '/dashboard' },
      // { name: 'Earnings', icon: '/doller.svg', path: '/earning' },
      // { name: 'Support', icon: '/support.svg', path: '/dashboard' }
    ],
    Admin: [
      { name: 'MD Management', icon: '/management.svg', isRole: true, path: '/dashboard' },
      { name: 'AD Management', icon: '/management.svg', isRole: true, path: '/dashboard' },
      { name: 'Product Catalogue', icon: '/category.svg', path: '/product' },
      { name: 'Transactions', icon: '/doller.svg', path: '/transactions' },
      { name: 'Withdrawal Requests', icon: '/doller.svg', path: '/dashboard' },
      // { name: 'User Management', icon: '/badge.svg', path: '/dashboard' }
    ],
  };

  const menuItems = roleBasedMenus[user?.roleId?.name] || [];

  // useEffect(() => {
  //   // Set the selected state to the first menu item's name when the component mounts or user role changes
  //   if (menuItems.length > 0) {
  //     setSelected(menuItems[0].name);
  //     navigate(menuItems[0].path);
  //   }
  // }, [user.roleId.name, menuItems, navigate]);

  const handleMenuClick = (item) => {
    setSelected(item.name);
    localStorage.setItem('selectedAdminSubmenu', item.name);
    if (item.name !== 'Product Catalogue') {
      setSelectedAdminSubmenu(item.name);
    }
    navigate(item.path);
  };

  const handleMouseEnter = (item) => {
    setHovered(item.name);
  };

  const handleMouseLeave = () => {
    setHovered('');
  };

  return (
    <div
      className={`sticky left-0 bg-white p-6 h-screen transition-all duration-300 ${collapsed ? 'w-[100px]' : 'w-[300px]'}`}
    >
      <div
        className={`flex items-center mb-8 mt-[30px] justify-center ${collapsed ? 'justify-center' : ''}`}
      >
        <img
          src={collapsed ? '/EZ Mobile-logo.svg' : '/tittle-logo.svg'}
          alt="EZMobile Logo"
          className="h-10.5"
        />
      </div>
      <ul className={`space-y-5 ${collapsed ? 'items-center' : ''}`}>
        {menuItems.map((item) => (
          <li
            key={item.name}
            onClick={() => handleMenuClick(item)}
            onMouseEnter={() => handleMouseEnter(item)}
            onMouseLeave={handleMouseLeave}
            className={`flex items-center font-jakarta font-normal text-[#1D2224] justify-start gap-4 h-[48px] px-4 rounded-[24px] cursor-pointer ${
              selected === item.name
                ? 'bg-[#C5EAF7] text-[#08607F] px-4 whitespace-nowrap font-jakarta font-semibold'
                : hovered === item.name
                  ? 'bg-blue-100 text-[#1D2224]'
                  : 'hover:bg-blue-100 text-[#1D2224]'
            } ${collapsed ? 'justify-center' : 'w-[250px]'}`}
          >
            <img src={item.icon} alt={`${item.name} Icon`} className="w-4.5 h-4.5" />
            {!collapsed && (
              <>
                {(user.roleId.name === 'Admin' ? item.name === selected : item.isRole) ? (
                  <span className="whitespace-nowrap">{item.name}</span>
                ) : (
                  item.name
                )}
                {item.hasNotification && (
                  <span className="ml-auto w-2.5 h-2.5 bg-red-500 rounded-full"></span>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
      <button
        className={`absolute bottom-6 ${collapsed ? 'left-10' : 'right-7'} transform -translate-x-1/2 text-white p-2 rounded-full transition-transform duration-300`}
        onClick={toggleSidebar}
      >
        <img
          src={collapsed ? '/non-collapse.svg' : '/collapse.svg'}
          alt="Collapse Icon"
          className="w-10 h-10"
        />
      </button>
    </div>
  );
};

export default Sidebar;
