import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import Cookies from '../managers/sessionManager';
import Utils from '../utility';
import { updateUser } from '../slices/userSlice';
import UserService from '../services/user';
import { jwtDecode } from 'jwt-decode';
import { TbLoader3 } from 'react-icons/tb';
import CenterWrapper from '../common/CenterWrapper';
import GlobalHeader from '../common/GlobalHeader';
import axios from 'axios';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // const handleGoogleLoginSuccess = async (credentialResponse) => {
  //   try {
  //     setLoading(true);

  //     const { data } = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
  //       headers: { Authorization: `Bearer ${credentialResponse.access_token}` },
  //     });
      
  //     // const decoded = jwtDecode(credentialResponse?.credential);
      
  //     // Store the token in localStorage or cookies
  //     localStorage.setItem('ACCESS_TOKEN', credentialResponse?.access_token);
  //     new Cookies().set('ACCESS_TOKEN', credentialResponse?.access_token);
  //     console.log("data",data);

  //     const userResponse = await new UserService().getUserByEmail(data?.email);
  //     console.log("userResponse",userResponse);
      
  //     const userId = userResponse?._id;
  //     const updateUserData = {
  //       name: data?.name,
  //       firstName: data?.given_name,
  //       lastName: data?.family_name,
  //       emailVerified: data?.email_verified,
  //       // status: "Active",
  //     };

  //     // await new UserService().updateUser(userId, updateUserData);

  //     // Update Redux state with the user data
  //     dispatch(updateUser({ ...data, ...userResponse, isLoggedIn: true }));

  //     navigate('/dashboard');

  //     // Changing the User Route depending on its Status
  //     // data?.isActive ? navigate("/dashboard") : data?.roleId?.name === "Authorized Distributor" ? navigate("/add-ad") : navigate("/add-md");
  //   } catch (error) {
  //     Utils.failureToastMessage(error?.response?.data?.responseData?.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      setLoading(true);
      const decoded = jwtDecode(credentialResponse?.credential);

      // Store the token in localStorage or cookies
      localStorage.setItem('ACCESS_TOKEN', credentialResponse?.credential);
      new Cookies().set('ACCESS_TOKEN', credentialResponse?.credential);

      const userResponse = await new UserService().getUserByEmail(decoded?.email);
      const userId = userResponse?._id;
      const updateUserData = {
        name: decoded?.name,
        firstName: decoded?.given_name,
        lastName: decoded?.family_name,
        emailVerified: decoded?.email_verified,
        // status: "Active",
      };
      
      await new UserService().updateUser(userId, updateUserData);
      
      // Update Redux state with the user data
      dispatch(updateUser({ ...decoded, ...userResponse, isLoggedIn: true }));
      console.log("userResponse?.isApprove",userResponse?.isApprove);
      
      setLoading(false);
      navigate('/')
     


      // Changing the User Route depending on its Status
      // data?.isActive ? navigate("/dashboard") : data?.roleId?.name === "Authorized Distributor" ? navigate("/add-ad") : navigate("/add-md");
    } catch (error) {
      Utils.failureToastMessage(error?.response?.data?.responseData?.message);
      setLoading(false);
    }
  };


  const handleGoogleLoginError = () => {
    console.error('Login Failed');
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError,
  });

  return (
    <div className=" w-full min-h-screen flex flex-col bg-[#FDFDFD] font-jakarta">
      <GlobalHeader />
      <CenterWrapper>
        {
          <div className="max-w-[480px] max-h-[182px] w-full h-full flex flex-col justify-center items-center gap-8">
            <h3 className="text-[39px] mt-[58px] font-extrabold font-jakarta text-center leading-[50.7px] -tracking-[0.5%]">
              Please sign in to submit the information
            </h3>

            {loading ? (
              <div className="flex items-center mr-auto">
                <TbLoader3 className="animate-spin text-black h-13 w-13" />
              </div>
            ) : (
                // <div
                //   onClick={login}
                //   className="flex items-center gap-2 py-3 pl-3 pr-11 w-[339px] h-[48px] bg-[#1D2224]  text-[#FDFDFD] rounded-[44px] hover:bg-black cursor-pointer">
                //   <img src="/google-icon.svg" alt="Google Logo" className="w-6 h-6" />
                //   <p className="text-base font-semibold leading-6 font-jakarta text-center text-[#FDFDFD] w-[251px] h-[24px]">
                //     Sign in with Google
                //   </p>
                // </div>
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            )}
          </div>
        }
      </CenterWrapper>
    </div>
  );
}

export default Login;
