import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MessageWrapper from '../common/MessageWrapper';
import SuccessMessage from '../message-pages/success-message';

function ProtectedRoutes({ children }) {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.isLoggedIn) {
      navigate('/sign-in');
    }
    // else if (!user.isInformationSubmitted) {
    //   navigate('/new-onboarding');
    // }
  }, [user, navigate]);

  // Conditional rendering based on user state
  if (user?.roleId?.name === 'Admin') return children;

  if ('tokenAccepted' in user && !user.tokenAccepted) {
    return (
      <MessageWrapper
        heading={'The invitation is not Accepted.'}
        subheading={'Please accept our invitation first'}
        isError={true}
      />
    );
  } else if ('isApprove' in user && !user.isApprove) {
    return <SuccessMessage />;
  } else return children;
}

export default ProtectedRoutes;
