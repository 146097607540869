import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TbLoader3 } from 'react-icons/tb';
import { toast } from 'react-toastify';
import UserService from '../services/user';
import { useSelector } from 'react-redux';

function AddDMModal({ onClose }) {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [distributorType, setDistributorType] = useState('Master Distributor');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN');
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://d2ehyo5nqf6wkd.cloudfront.net/admins/roles', {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        });
        setRoles(response.data.responseData);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };
    fetchRoles();
  }, [ACCESS_TOKEN]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateEmail = (value) => {
    const gmailPattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    setIsValid(gmailPattern.test(value));
  };

  const handleSubmit = async () => {
    if (isValid) {
      setLoading(true);
      try {
        let payload = {
          email: email,
          // roleId: getRoleIdByDistributorType(distributorType),
          roleId: '664305cd491bf03e983f0ade',
          invitedBy: user?._id,
        };
        const response = await new UserService().addUser(payload);
        setShowSuccess(true);
        toast.success('Added distributor successfully', {
          autoClose: 2000,
        });
      } catch (error) {
        if (
          error.responseData &&
          error.responseData.code === 11000 &&
          error.responseData.keyPattern &&
          error.responseData.keyPattern.email
        ) {
          toast.error('Email already exists', {
            autoClose: 2000,
          });
        } else {
          toast.error(`Error adding distributor: ${error.message}`, {
            autoClose: 2000,
          });
        }
        onClose();
      } finally {
        setLoading(false);
      }
    }
  };

  const getRoleIdByDistributorType = (type) => {
    const roleMap = {
      'Master Distributor': '664305cd491bf03e983f0ade',
      // "Authorized Distributor": "664305dc491bf03e983f0ae1",
    };
    return roleMap[type];
  };

  const inputClass = [
    'w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] flex items-center',
    isValid !== null && (isValid ? 'border border-green-500 pr-10' : 'border border-red-500 pr-10'),
  ]
    .filter(Boolean)
    .join(' ');

  const icon = isValid !== null && (
    <img
      src={isValid ? './check_circle.svg' : './info-error.svg'}
      alt={isValid ? 'Valid' : 'Invalid'}
      className="absolute right-3 top-1/2 transform -translate-y-1/2"
    />
  );

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const selectDistributorType = (type) => {
    setDistributorType(type);
    setDropdownOpen(false);
  };

  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[700px] h-90per max-h-[500px] rounded-[24px] shadow-lg p-7">
        <div className="flex justify-end mb-4">
          <img onClick={onClose} className="h-6 w-6 cursor-pointer" src="./close.svg" alt="Close" />
        </div>
        {showSuccess ? (
          <div className="flex flex-col w-[351px] h-[464px] font-jakarta mx-auto justify-center">
            <img src="./check_circle.svg" alt="Success" className="h-10 w-10 mb-3" />
            <h2 className="text-[#0F9E73] font-bold text-ft21 mb-2">
              {distributorType} Added Successfully!
            </h2>
            <button
              className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-full text-white py-2 px-4 rounded-xl"
              onClick={() => {
                window.location.reload();
                onClose();
              }}
            >
              Go to Distributor Management
            </button>
          </div>
        ) : (
          <div className="flex flex-col w-[351px] h-[464px] mx-auto justify-center">
            <div className="mb-4">
              <h2 className="text-[#1D2224] font-bold text-ft21">Add a new distributor</h2>
            </div>
            <div className="flex flex-col gap-2 mb-4 relative">
              <label className="block text-ft1_5 mb-1 font-normal text-[#1D2224]">
                Distributor Type
              </label>
              <div
                className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] flex items-center cursor-pointer relative"
                onClick={toggleDropdown}
              >
                {distributorType}
                <img src="./drop-arrow.svg" alt="Dropdown Icon" className="ml-auto" />
              </div>
              {dropdownOpen && (
                <div className="absolute w-full top-18.5 bg-white border border-gray-300 rounded-lg shadow-lg mt-2 z-10">
                  {['Master Distributor'].map((type) => (
                    <div
                      key={type}
                      className="px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer"
                      onClick={() => selectDistributorType(type)}
                    >
                      {type}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="mb-4 relative">
              <label className="block text-ft1_5 mb-1 font-normal text-[#1D2224]">
                Enter Gmail Account
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  className={inputClass}
                  placeholder="Enter distributor gmail account"
                />
                {icon}
              </div>
              {isValid === false && (
                <p className="text-red-500 text-sm mt-2">Provide Gmail account only</p>
              )}
              {isValid === true && (
                <p className="text-green-500 text-sm mt-2">Account is eligible.</p>
              )}
            </div>
            <div className="mt-10 flex gap-4 justify-center">
              <button className="text-[#1D2224] py-2 px-4 rounded" onClick={onClose}>
                Cancel
              </button>
              <button
                className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <TbLoader3 className="animate-spin w-5 h-5 text-white" /> : 'Add'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddDMModal;
