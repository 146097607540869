import React, { useEffect, useState } from 'react';
import { NewAddADFormData } from '../constants';
import { useSelector } from 'react-redux';
import Utils from '../utility';
import GlobalHeader from '../common/GlobalHeader';
import FormDashboard from './form-dashboard';
import Declaration from './declaration';
import FormSubmitted from '../message-pages/form-submitted';
import PersonalDetails from './personal-details';
import PaymentDetails from './payment-details';
import IdentityDetails from './identity-details';
import AddressDetails from './address-details';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import StepIndicator from '../common/StepIndicator';
import OnboardingFooter from '../common/OnboardingFooter';

const NewAddADComponent = ({ type }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const user = useSelector((state) => state.user);
  const [prefilledFormData, setPrefilledFormData] = useState({
    name: user?.name,
    gender: user?.gender,
    // dob: user?.dob,
    dob: '',
    postalCode: user?.address?.zipCode,
    // identities:  user?.identities,
    identities: [],
    // proofOfAddress: user?.proofOfAddress,
    proofOfAddress: [],
    bankName: user?.bankName,
    paymentMethods: user?.paymentMethods,
    accountNumber: user?.accountNumber,
    routingNumber: user?.routingNumber,
    accountType: user?.accountType,
    accountHolderName: user?.accountHolderName,
    signature: user?.signature,
    isTermsAccepted: user?.isTermsAccepted,
  });

  const [formData, setFormData] = useState(
    user?.isInformationSubmitted ? prefilledFormData : NewAddADFormData
  );
  const [tabValidation, setTabValidation] = useState(0);

  const handleChange = (e) => {
    console.log(e.target.value);

    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    if (user?.status === 'Rejected') {
      const rejectReason = user.rejectReason || [];
      if (rejectReason.includes('Personal Details')) {
        setSelectedTab(1);
        Utils.failureToastMessage('Please update your Personal Details:', user?.rejectComment);
      } else if (rejectReason.includes('Identity Details')) {
        setSelectedTab(2);
        Utils.failureToastMessage('Please update your Identity Details.', user?.rejectComment);
      } else if (rejectReason.includes('Address Details')) {
        setSelectedTab(3);
        Utils.failureToastMessage('Please update your Address Details.', user?.rejectComment);
      } else if (rejectReason.includes('Bank Details')) {
        setSelectedTab(4);
        Utils.failureToastMessage('Please update your Bank Details.', user?.rejectComment);
      } else if (rejectReason.includes('Signature Details')) {
        setSelectedTab(5);
        Utils.failureToastMessage('Please update your Signature Details.', user?.rejectComment);
      }
    }
  }, [user]);

  useEffect(() => {
    if (formData?.name && formData?.gender && formData?.dob && formData.postalCode) {
      setTabValidation(1);
    }
    if (formData?.identities.length > 0) {
      setTabValidation(2);
    }
    if (formData?.proofOfAddress.length > 0) {
      setTabValidation(3);
    }
    if (
      formData?.bankName &&
      formData?.accountNumber &&
      formData?.routingNumber &&
      formData?.accountType &&
      formData?.accountHolderName
    ) {
      setTabValidation(4);
    }
    if (
      // formData?.signatureFirstName &&
      // formData?.signatureLastName &&
      formData?.signature &&
      formData?.isTermsAccepted
    ) {
      setTabValidation(5);
    }
  }, [formData]);

  const nextStep = () => {
    if (tabValidation >= selectedTab) {
      if (selectedTab < 5) {
        setSelectedTab(selectedTab + 1);
      }
    } else {
      // You might want to show an error message here
      Utils.failureToastMessage('Please complete the current step before moving to the next one.');
    }
  };

  const prevStep = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    }
  };

  return (
    <div className="mx-auto w-full gap-12 min-h-screen flex flex-col items-center bg-[#FDFDFD] relative font-jakarta">
      {selectedTab <= 5 && (
        <GlobalHeader>
          {/* StepIndicator should be shown only when selectedTab is between 1 and 5 */}
          {selectedTab >= 1 && selectedTab <= 5 && (
            <StepIndicator currentStep={selectedTab} totalSteps={5} />
          )}
        </GlobalHeader>
      )}
      {selectedTab >= 1 && selectedTab <= 5 && (
        <>
          <div
            className="absolute top-153px left-[200px] w-[84px] h-[48px] flex justify-center items-center gap-1 cursor-pointer"
            onClick={prevStep}>
            <MdOutlineKeyboardBackspace className="w-4 h-4" />
            <p className="font-bold text-[#000000]">Back</p>
          </div>
          <div
            className="absolute top-153px right-[200px] w-[84px] h-[48px] flex justify-center items-center gap-1 cursor-pointer"
            onClick={nextStep}>
            <p className="font-bold text-[#DDDEDE]">Next</p>
            <MdOutlineKeyboardBackspace className="rotate-180 w-4 h-4 text-[#DDDEDE]" />
          </div>
        </>
      )}
      <div className="max-w-[480px] flex justify-center w-full">
        {selectedTab === 0 && (
          <FormDashboard
            user={user}
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 1 && (
          <PersonalDetails
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 2 && (
          <IdentityDetails
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 3 && (
          <AddressDetails
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 4 && (
          <PaymentDetails
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 5 && (
          <Declaration
            type={type}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            tabValidation={tabValidation}
          />
        )}
        {selectedTab === 6 && <FormSubmitted />}
      </div>

      {selectedTab >= 1 && selectedTab <= 5 && <OnboardingFooter />}
    </div>
  );
};

export default NewAddADComponent;
