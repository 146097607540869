import React, { useEffect, useState } from 'react';
import { SiZelle } from 'react-icons/si';

const WithdrawalBankDetails = ({ formData, setFormData, setSelectedTab }) => {
  const [withdrawalDetailsAvailable, setWithdrawalDetailsAvailable] = useState(false);
  const [addWithdrawalDetailsForm, setAddWithdrawalDetailsForm] = useState(
    !withdrawalDetailsAvailable
  );

  useEffect(() => {
    if (formData?.withdrawalMethod === 'Zelle') {
      if (formData?.zelleDetails?.email && formData?.zelleDetails?.phoneNumber) {
        setWithdrawalDetailsAvailable(true);
      }
    } else if (formData?.withdrawalMethod === 'Wire Transfer') {
      if (
        formData?.wireTransferDetails?.bankName &&
        formData?.wireTransferDetails?.swiftCode &&
        formData?.wireTransferDetails?.accountNumber &&
        formData?.wireTransferDetails?.accountHolderName
      ) {
        setWithdrawalDetailsAvailable(true);
      }
    } else {
      setWithdrawalDetailsAvailable(true);
    }
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  return (
    <div className="flex flex-col gap-[32px] w-[35%] font-jakarta">
      <div className="flex flex-col">
        <span className="h-[32px] w-[47px] bg-white rounded-[35px] flex justify-center items-center">
          2/3
        </span>
        <h3 className="text-[#2F3436] text-[25px] font-bold mt-[4px]">
          {formData?.withdrawalMethod === 'Bank'
            ? 'Bank details'
            : formData?.withdrawalMethod === 'Zelle'
              ? 'Zelle account details'
              : 'Wire Transfer details'}
        </h3>
      </div>

      {formData?.withdrawalMethod === 'Bank' && (
        <div className="flex flex-col gap-[16px]">
          <div className="flex flex-col">
            <label htmlFor="accountNumber" className="text-[#1D2224] text-[13px] uppercase">
              Account Number
            </label>
            <input
              type="text"
              id="accountNumber"
              placeholder="Enter Account Number"
              value={formData?.bankDetails?.accountNumber}
              onChange={handleChange}
              className="bg-transparent border-none outline-none placeholder:text-[#999C9C] placeholder:font-normal text-[#1D2224] text-[16px] font-bold w-60per"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="routingNumber" className="text-[#1D2224] text-[13px] uppercase">
              Routing Number
            </label>
            <input
              type="text"
              id="routingNumber"
              placeholder="Enter Routing Number"
              value={formData?.bankDetails?.routingNumber}
              onChange={handleChange}
              className="bg-transparent border-none outline-none placeholder:text-[#999C9C] placeholder:font-normal text-[#1D2224] text-[16px] font-bold w-60per"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="accountType" className="text-[#1D2224] text-[13px] uppercase">
              Account Type
            </label>
            <input
              type="text"
              id="accountType"
              placeholder="Enter Account Type"
              value={formData?.bankDetails?.accountType}
              onChange={handleChange}
              className="bg-transparent border-none outline-none placeholder:text-[#999C9C] placeholder:font-normal text-[#1D2224] text-[16px] font-bold w-60per"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="accountHolderName" className="text-[#1D2224] text-[13px] uppercase">
              Account holder name
            </label>
            <input
              type="text"
              id="accountHolderName"
              placeholder="Enter Account Holder Name"
              value={formData?.bankDetails?.accountHolderName}
              onChange={handleChange}
              className="bg-transparent border-none outline-none placeholder:text-[#999C9C] placeholder:font-normal text-[#1D2224] text-[16px] font-bold w-60per"
            />
          </div>
        </div>
      )}

      {formData?.withdrawalMethod === 'Zelle' &&
        (addWithdrawalDetailsForm ? (
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[2px]">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter Your Email"
                className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
                value={formData?.zelleDetails?.email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    zelleDetails: { ...formData.zelleDetails, email: e.target.value },
                  })
                }
              />
            </div>

            <div className="flex flex-col gap-[2px]">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                placeholder="Enter Your Phone Number"
                className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
                value={formData?.zelleDetails?.phoneNumber}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    zelleDetails: { ...formData.zelleDetails, phoneNumber: e.target.value },
                  })
                }
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-[32px]">
            <p className="text-[#E32028] text-[16px] font-normal">
              No Zelle account added. Please add your Zelle details to withdraw from there.
            </p>
            <button
              className="bg-[#1D2224] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-full text-white rounded-xl"
              onClick={() => setAddWithdrawalDetailsForm(true)}
            >
              Add <SiZelle className="relative left-[2px]" />
              elle
            </button>
          </div>
        ))}

      {formData?.withdrawalMethod === 'Wire Transfer' &&
        (addWithdrawalDetailsForm ? (
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[2px]">
              <label htmlFor="bankName">Bank Name</label>
              <input
                type="text"
                id="bankName"
                placeholder="Enter Your Bank Name"
                className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
                value={formData?.wireTransferDetails?.bankName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    wireTransferDetails: {
                      ...formData.wireTransferDetails,
                      bankName: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className="flex flex-col gap-[2px]">
              <label htmlFor="swiftCode">Swift Code</label>
              <input
                type="text"
                id="swiftCode"
                placeholder="Enter Your Swift Code"
                className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
                value={formData?.wireTransferDetails?.swiftCode}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    wireTransferDetails: {
                      ...formData.wireTransferDetails,
                      swiftCode: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className="flex flex-col gap-[2px]">
              <label htmlFor="accountNumber">Account Number</label>
              <input
                type="text"
                id="accountNumber"
                placeholder="Enter Your Account Number"
                className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
                value={formData?.wireTransferDetails?.accountNumber}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    wireTransferDetails: {
                      ...formData.wireTransferDetails,
                      accountNumber: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className="flex flex-col gap-[2px]">
              <label htmlFor="accountHolderName">Account Holder Name</label>
              <input
                type="text"
                id="accountHolderName"
                placeholder="Enter Your Account Holder Name"
                className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
                value={formData?.wireTransferDetails?.accountHolderName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    wireTransferDetails: {
                      ...formData.wireTransferDetails,
                      accountHolderName: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-[32px]">
            <p className="text-[#E32028] text-[16px] font-normal">
              No Wire Transfer account added. Please add your Wire Transfer details to withdraw from
              there.
            </p>
            <button
              className="bg-[#1D2224] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-full text-white rounded-xl"
              onClick={() => setAddWithdrawalDetailsForm(true)}
            >
              Add Wire Transfer
            </button>
          </div>
        ))}

      <div className="flex justify-center items-center gap-[8px] mt-[10px]">
        <button
          className="flex justify-center items-center h-[48px] w-[50%] rounded-[12px] bg-[#DDDEDE] hover:bg-opacity-80 text-[#1D2224]"
          onClick={() => setSelectedTab(0)}
        >
          Previous
        </button>

        <button
          className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[50%] text-white rounded-xl disabled:cursor-not-allowed"
          disabled={!withdrawalDetailsAvailable}
          onClick={() => setSelectedTab(2)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default WithdrawalBankDetails;
