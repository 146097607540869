import React, { useState, useEffect } from 'react';
import Header from '../header/header';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TransactionsList from './transactionsList';
import Sidebar from '../sidebar/sidebar';
import UserProfileSidebar from '../dashboard/userProfileModal';
import Utils from '../utility';
import { removeUser, updateUser } from '../slices/userSlice';
import WalletService from '../services/wallet';
import { saveAs } from 'file-saver';

const Transactions = ({ setSelectedAdminSubmenu }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const navigate = useNavigate();

  const [selected, setSelected] = useState('Transactions');
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebarCollapsed');
    return savedState === 'true';
  });
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [dropdownQuery, setDropdownQuery] = useState('name');
  const [searchInput, setSearchInput] = useState('');

  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalTransactions, setTotalTransactions] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }
        const skip = (currentPage - 1) * rowsPerPage;
        const limit = rowsPerPage;
        if (user?.roleId?.name === 'Admin') {
          const endpoint = `/admins/transactions?skip=${skip}&limit=${limit}`;
          const transactionsRes = await new WalletService().getTransactions(endpoint);

          setTotalTransactions(transactionsRes?.totalCount);
          setTransactions(transactionsRes?.transactions);
        } else {
          const endpoint = `/transactions/${user?._id}?skip=${skip}&limit=${limit}`;
          const transactionsRes = await new WalletService().getTransactions(endpoint);

          setTotalTransactions(transactionsRes?.totalCount);
          setTransactions(transactionsRes?.transactions);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [currentPage, dispatch, rowsPerPage, user?._id, user?.roleId?.name]);

  const handleSearch = async () => {
    try {
      setLoading(true);
      // CHECKING IF TOKEN IS EXPIRE OR NOT
      if (Utils.checkTokenExpireOrNot()) {
        dispatch(removeUser({}));
        return;
      }
      const skip = (currentPage - 1) * rowsPerPage;
      const limit = rowsPerPage;
      if (user?.roleId?.name === 'Admin') {
        const endpoint = `/admins/transactions?skip=${skip}&limit=${limit}&${dropdownQuery}=${searchInput}`;
        const transactionsRes = await new WalletService().getTransactions(endpoint);

        setTotalTransactions(transactionsRes?.totalCount);
        setTransactions(transactionsRes?.transactions);
      } else {
        const endpoint = `/transactions/${user?._id}?skip=${skip}&limit=${limit}&${dropdownQuery}=${searchInput}`;
        const transactionsRes = await new WalletService().getTransactions(endpoint);

        setTotalTransactions(transactionsRes?.totalCount);
        setTransactions(transactionsRes?.transactions);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchInput = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    localStorage.setItem('sidebarCollapsed', !collapsed);
  };

  const toggleProfileSidebar = () => {
    setProfileMenuOpen((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('user');
    dispatch(updateUser({...user, isLoggedIn: false}));
    navigate('/');
  };

  const exportAdminTransactionsToCSV = () => {
    const headers = [
      'Transaction ID',
      'Wallet ID',
      'Date & Time',
      'Description',
      'Type',
      'Amount',
      'Balance',
    ];
    const rows = transactions.map((trans) => [
      trans.transactionId === 'N/A' ? trans?._id : trans?.transactionId,
      trans?.walletId,
      `"${Utils.formatTimestamp(trans?.addedOn)}"`, // Wrap in double quotes
      `"${trans?.description}"`,
      trans?.type,
      `${trans?.type === 'Credit' ? '+' : '-'}${trans?.amount}`,
      trans?.balance,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'transactions.csv');
  };

  const exportToCSV = () => {
    const headers = ['Transaction ID', 'Date & Time', 'Description', 'Type', 'Amount', 'Balance'];
    const rows = transactions.map((trans) => [
      trans.transactionId === 'N/A' ? trans?._id : trans?.transactionId,
      `"${Utils.formatTimestamp(trans?.addedOn)}"`, // Wrap in double quotes
      `"${trans?.description}"`,
      trans?.type,
      `${trans?.type === 'Credit' ? '+' : '-'}${trans?.amount}`,
      trans?.balance,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'transactions.csv');
  };

  return (
    <div className="flex h-screen ">
      <>
        <Sidebar
          className="fixed left-0"
          collapsed={collapsed}
          toggleSidebar={toggleSidebar}
          setSelectedAdminSubmenu={setSelectedAdminSubmenu}
        />

        <div className={`flex-grow overflow-y-auto flex flex-col ${collapsed ? 'ml-2' : 'ml-3'}`}>
          <div className={`fixed ${collapsed ? 'w-[95%]' : 'w-[85%]'} top-0 z-10`}>
            <Header
              handleSearch={handleSearch}
              collapsed={collapsed}
              toggleProfileSidebar={toggleProfileSidebar}
              dropdownQuery={dropdownQuery}
              setDropdownQuery={setDropdownQuery}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              handleSearchInput={handleSearchInput}
            />
          </div>

          <div className="mt-16  ">
            <TransactionsList
              loading={loading}
              transactions={transactions}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              totalTransactions={totalTransactions}
              exportAdminTransactionsToCSV={exportAdminTransactionsToCSV}
              exportToCSV={exportToCSV}
            />
          </div>
        </div>
      </>

      <UserProfileSidebar
        handleLogout={handleLogout}
        profileMenuOpen={profileMenuOpen}
        toggleProfileSidebar={toggleProfileSidebar}
      />
    </div>
  );
};

export default Transactions;
