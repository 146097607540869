import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useSelector } from 'react-redux';

const AddressDetails = ({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
  setPhoneNumberInput,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState({
    countryDropdown: false,
    addressProofDropdown: false,
  });
  const countryDropdownRef = useRef(null);
  const countryDropdownChildRef = useRef(null);
  const addressProofDropdownRef = useRef(null);
  const addressProofDropdownChildRef = useRef(null);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    let handler = (e) => {
      if (countryDropdownChildRef.current && !countryDropdownRef.current.contains(e.target)) {
        setDropdownOpen({ ...dropdownOpen, countryDropdown: false });
      }
      if (
        addressProofDropdownChildRef.current &&
        !addressProofDropdownRef.current.contains(e.target)
      ) {
        setDropdownOpen({ ...dropdownOpen, addressProofDropdown: false });
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const handleAddressProof = (selectedAddressProofItem) => {
    setFormData({
      ...formData,
      proofOfAddress: { ...formData.proofOfAddress, type: selectedAddressProofItem },
    });
    setDropdownOpen({ ...dropdownOpen, addressProofDropdown: false });
  };

  return (
    <div className="flex flex-col gap-[32px] w-[35%] pb-[20px]">
      <div className="flex flex-col">
        <span className="h-[32px] w-[47px] bg-white rounded-[35px] flex justify-center items-center">
          2/5
        </span>
        <h3 className="text-[#2F3436] text-[25px] font-extrabold mt-[4px]">
          Application for {type === 'addMD' ? 'MD' : 'AD'}
        </h3>
        <p className="text-[#1D2224] text-[20px] font-semibold">Applicant's address details</p>
      </div>

      <div className="flex flex-col gap-[16px]">
        <div className="flex items-center gap-[8px]">
          <div className="flex flex-col gap-[2px] w-[50%]">
            <label
              htmlFor="postalCode"
              className="text-[#1D2224] text-[13px] font-normal uppercase"
            >
              Postal / Zip code
            </label>
            <input
              type="text"
              id="postalCode"
              placeholder="Postal code"
              value={formData.postalCode}
              onChange={handleChange}
              className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
            />
          </div>

          <div className="flex flex-col gap-[2px] relative w-[50%]" ref={countryDropdownRef}>
            <label htmlFor="country" className="text-[#1D2224] text-[13px] font-normal uppercase">
              Country
            </label>
            <Select
              options={countryOptions}
              value={{
                value: countryList()?.getValue(formData.country) ? [0] : '',
                label: formData.country,
              }}
              onChange={(e) => setFormData({ ...formData, country: e.label })}
              className="country-input"
            />
          </div>
        </div>

        <div className='flex flex-col gap-[2px]'>
          <label htmlFor="phoneNumber" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Phone Number
          </label>
          <input
            type="text"
            id="phoneNumber"
            placeholder="Enter Phone Number"
            value={formData?.phoneNumber}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />

          {/* <PhoneInput
            placeholder="Enter Phone Number"
            value={`${formData.phoneNumber ? '+' : ''}${formData.phoneCountryCode}${formData.phoneNumber}`}
            onChange={(e) => setPhoneNumberInput(e)}
          /> */}
        </div>

        <div className="flex flex-col gap-[2px]">
          <label htmlFor="email" className="text-[#1D2224] text-[13px] font-normal uppercase">
            Email Id
          </label>
          <input
            type="text"
            id="email"
            placeholder="Enter email ID"
            value={formData?.email ? formData?.email : user?.email}
            onChange={handleChange}
            className="h-[48px] w-full p-[12px] rounded-[12px] outline-none placeholder:text-[#999C9C]"
          />
        </div>

        <div
          className="flex flex-col gap-[2px] relative cursor-default"
          ref={addressProofDropdownRef}
        >
          <label
            htmlFor="addressProof"
            className="text-[#1D2224] text-[13px] font-normal uppercase"
          >
            Proof of Address to be Provided by Applicant
          </label>
          <div
            className="h-[48px] w-full p-[12px] bg-white rounded-[12px] flex justify-between items-center"
            onClick={() =>
              setDropdownOpen({
                ...dropdownOpen,
                addressProofDropdown: !dropdownOpen.addressProofDropdown,
              })
            }
          >
            <p
              className={`text-[#1D2224] text-[16px] capitalize ${formData.proofOfAddress.type ? '' : 'text-[#999C9C]'}`}
            >
              {formData.proofOfAddress.type
                ? formData.proofOfAddress.type
                : 'Please Select'}
            </p>
            <MdKeyboardArrowDown
              className={`h-[20px] w-[20px] transition-all ${dropdownOpen.addressProofDropdown ? 'rotate-180' : ''}`}
            />
          </div>

          {dropdownOpen.addressProofDropdown && (
            <div
              className="absolute top-[77px] h-[104px] w-full p-[4px] box-border bg-[#FDFDFD] rounded-[12px] shadow-lg z-10 overflow-y-auto"
              ref={addressProofDropdownChildRef}
            >
              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleAddressProof('Passport')}
              >
                <input
                  type="radio"
                  name="addressProof"
                  id="passport"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfAddress.name === 'Passport'}
                />
                <label htmlFor="passport" className="text-[#1D2224] font-normal">
                  Passport
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleAddressProof('Driving License')}
              >
                <input
                  type="radio"
                  name="addressProof"
                  id="drivingLicense"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfAddress.name === 'Driving License'}
                />
                <label htmlFor="drivingLicense" className="text-[#1D2224] font-normal">
                  Driving License
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleAddressProof('Ration Card')}
              >
                <input
                  type="radio"
                  name="addressProof"
                  id="rationCard"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfAddress.name === 'Ration Card'}
                />
                <label htmlFor="rationCard" className="text-[#1D2224] font-normal">
                  Ration Card
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleAddressProof('Registered lease / Sale Agreement of Residence')}
              >
                <input
                  type="radio"
                  name="addressProof"
                  id="saleAgreement"
                  className="h-[15px] w-[15px]"
                  defaultChecked={
                    formData.proofOfAddress.name ===
                    'Registered lease / Sale Agreement of Residence'
                  }
                />
                <label htmlFor="saleAgreement" className="text-[#1D2224] font-normal">
                  Registered lease / Sale Agreement of Residence
                </label>
              </div>

              <div
                className="flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] box-border hover:bg-[#F1F2F2]"
                onClick={() => handleAddressProof('Latest Gas Bill')}
              >
                <input
                  type="radio"
                  name="addressProof"
                  id="gasBill"
                  className="h-[15px] w-[15px]"
                  defaultChecked={formData.proofOfAddress.name === 'Latest Gas Bill'}
                />
                <label htmlFor="gasBill" className="text-[#1D2224] font-normal">
                  Latest Gas Bill
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center items-center gap-[8px]">
        <button
          className="flex justify-center items-center h-[48px] w-[50%] rounded-[12px] bg-[#DDDEDE] text-[#1D2224] font-semibold"
          onClick={() => setSelectedTab(0)}
        >
          Previous
        </button>
        <button
          className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[50%] text-white font-semibold rounded-xl disabled:cursor-not-allowed"
          disabled={tabValidation < 2}
          onClick={() => setSelectedTab(2)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AddressDetails;
