import React, { useEffect, useRef, useState } from 'react';
import CenterWrapper from '../common/CenterWrapper';
import { IoMdRadioButtonOn, IoMdRadioButtonOff } from 'react-icons/io';

import {
  MdOutlineFileUpload,
  MdOutlineDone,
  MdError,
  MdClose,
  MdKeyboardArrowDown,
} from 'react-icons/md';

import { FaFile } from 'react-icons/fa6';
import UserService from '../services/user';
import { AddressOptions } from '../constants';
import Utils from '../utility';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUser } from '../slices/userSlice';

function AddressDetails({
  type,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  tabValidation,
}) {

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);

  const [selectedType, setSelectedType] = useState(null);
  const [addressDropdown, setAddressDropdown] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(formData.proofOfAddress);
  const [uploadedTypes, setUploadedTypes] = useState(
    formData.proofOfAddress.map((item) => item.type)
  );
  const addressProofDropdownRef = useRef(null);
  const addressProofDropdownChildRef = useRef(null);
  const addressProofFileRef = useRef(null);

  useEffect(() => {
    const handler = (e) => {
      // Close gender dropdown if click is outside
      if (
        addressProofDropdownChildRef.current &&
        addressProofDropdownRef.current &&
        !addressProofDropdownRef.current.contains(e.target)
      ) {
        setAddressDropdown(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [addressDropdown]);

  //   const handleIdentityProof = (selectedIdentityProof) => {
  //     setFormData({
  //       ...formData,
  //       proofOfIdentity: [
  //         ...formData.proofOfIdentity,
  //         {
  //           type: selectedIdentityProof,
  //           name: '',
  //           size: '',
  //           documentLink: '',
  //         },
  //       ],
  //     });
  //     // setIdentityDropdown(false);
  //   };
  const handleAddressProof = (selectedAddressProof) => {
    setSelectedType(selectedAddressProof);
    setAddressDropdown(false);
  };

  const handleFileUpload = async (e) => {
    if (!selectedType || !e.target.files.length) return;

    if (uploadedTypes.includes(selectedType)) {
      Utils.failureToastMessage('You have already uploaded a document of this type.');
      return;
    }

    const file = e.target.files[0];
    const newUpload = {
      name: file.name,
      size: file.size,
      type: selectedType,
      status: 'uploading',
    };

    setUploadingFiles([...uploadingFiles, newUpload]);
    setUploadedTypes([...uploadedTypes, selectedType]); // Add to uploadedTypes

    const reqFormData = new FormData();
    reqFormData.append('fileName', file.name);
    reqFormData.append('files', file);

    try {
      const res = await new UserService().fileUpload(reqFormData);

      setUploadingFiles((prevFiles) =>
        prevFiles.map((item) =>
          item.name === file.name
            ? { ...item, status: 'uploaded', documentLink: res?.cdnUrl }
            : item
        )
      );

      setFormData({
        ...formData,
        proofOfAddress: [
          ...formData.proofOfAddress,
          {
            type: selectedType,
            name: file.name,
            size: file.size,
            documentLink: res?.cdnUrl,
            status: 'uploaded',
          },
        ],
      });
    } catch (error) {
      setUploadingFiles((prevFiles) =>
        prevFiles.map((item) => (item.name === file.name ? { ...item, status: 'failed' } : item))
      );
      console.error('File upload failed:', error);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = uploadingFiles.filter((_, i) => i !== index);
    setUploadingFiles(updatedFiles);

    const updatedProofs = formData.proofOfAddress.filter((_, i) => i !== index);
    setFormData({ ...formData, proofOfAddress: updatedProofs });

    // Remove from uploadedTypes if file type is removed
    const removedType = formData.proofOfAddress[index]?.type;
    if (removedType) {
      setUploadedTypes(uploadedTypes.filter((type) => type !== removedType));
    }
  };

  
  const handleSubmit = async () => {
    console.log('formdata', formData);
    const updatedFormData = {
      userId: user?._id,
      proofOfAddress: formData?.proofOfAddress,
    };
    try {
      const response = await new UserService().updateProfile(updatedFormData);
      console.log('response', response);

      const updatedUserData = { ...updatedFormData, ...response };
      if (response) {
        dispatch(updateUser(updatedUserData));
        toast.success('Details Submitted Successfully', 4000);
        setSelectedTab((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    }
  };

  return (
    <>
      <CenterWrapper>
        <div className=" w-full flex flex-col gap-8">
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-extrabold text-[39px]  leading-[50.7px] -tracking-[0.5%] text-[#1D2224]">
              Proof of address
            </h3>
            <p className="text-base leading-6 font-normal text-[#6C6F71]">
              Provide documents to proof your residence
            </p>
          </div>

          <div
            className="flex flex-col gap-[2px] relative cursor-default"
            ref={addressProofDropdownRef}>
            <label htmlFor="identityProof" className="text-[#1D2224] text-base leading-6 ">
              Type of document
            </label>
            <div
              className={` h-[48px]  w-full  px-[18px] py-4 rounded-xl 
                      ${addressDropdown ? 'border-2 border-[#1D2224]' : 'border border-[#999C9C]'}  flex justify-between  items-center  cursor-pointer`}
              onClick={() => setAddressDropdown(!addressDropdown)}>
              <p className={`text-[#1D2224] text-[16px] ${selectedType ? '' : 'text-[#999C9C]'}`}>
                {selectedType || 'Please Select'}
              </p>
              <MdKeyboardArrowDown
                className={`h-[20px] w-[20px] transition-all ${addressDropdown ? 'rotate-180' : ''}`}
              />
            </div>

            {addressDropdown && (
              <div
                className="absolute top-[80px] h-[152px] w-full box-border bg-[#FDFDFD] rounded-xl shadow-lg z-10 overflow-y-auto"
                ref={addressProofDropdownChildRef}>
                {AddressOptions.length > 0 &&
                  AddressOptions.map((type) => {
                    const isSelected = selectedType === type;
                    const isUploaded = uploadedTypes.includes(type);
                    const isDisabled = isUploaded;
                    return (
                      <div
                        key={type}
                        className={`flex items-center gap-[8px] h-[48px] py-[14px] px-[12px] hover:bg-[#F1F2F2] ${isDisabled ? 'cursor-not-allowed text-gray-500' : ''}`}
                        onClick={() => !isDisabled && handleAddressProof(type)}>
                        {isSelected || isUploaded ? (
                          <IoMdRadioButtonOn className="text-[#1D2224] w-5 h-5" />
                        ) : (
                          <IoMdRadioButtonOff className="text-[#999C9C] w-5 h-5" />
                        )}
                        <p
                          className={`text-[#1D2224] ${isSelected || isUploaded ? 'font-bold' : 'font-normal'}`}>
                          {type}
                        </p>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div
            className="h-[150px] w-full bg-[#FDFDFD] border border-solid border-[#DDDEDE] rounded-xl flex flex-col justify-center items-center cursor-pointer"
            onClick={() => addressProofFileRef.current.click()}>
            <MdOutlineFileUpload className="h-[40px] w-[40px]" />
            <input
              type="file"
              className="hidden"
              ref={addressProofFileRef}
              onChange={handleFileUpload}
            />
            <h4 className="text-[#2F3436] text-[20px] font-semibold">Browse files</h4>
            <p className="text-[#999C9C] text-[16px]">Drag and drop files here</p>
          </div>
          {/* Uploaded, Failed, and Uploading Files */}
          {uploadingFiles.map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-[#FDFDFD] rounded-xl mt-4 p-[4px] gap-4 px-4">
              <div className="flex items-center gap-4">
                {item.status === 'uploaded' ? (
                  <MdOutlineDone className="text-green-500 w-6 h-6" />
                ) : item.status === 'Failed' ? (
                  <MdError className="text-[#E32028]  w-6 h-6" />
                ) : (
                  <FaFile className="text-gray-500 w-6 h-6" />
                )}
                <div className="flex flex-col items-start">
                  <p className="text-base leading-6 text-[#1D2224]">{item.name}</p>
                  <p
                    className={`text-sm px-1 leading-5 ${item.status === 'failed' ? 'text-red-500' : 'text-[#999C9C]'}`}>
                    {item.status === 'uploading'
                      ? 'Uploading...'
                      : item.status === 'Failed'
                        ? 'Failed'
                        : ''}
                    {item.type} - {Math.ceil(item.size / 1000)} KB
                  </p>
                </div>
              </div>
              <MdClose
                className="text-gray-500 cursor-pointer  w-6 h-6"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}

          <button
            disabled={tabValidation < 3}
            onClick={handleSubmit}
            className="flex justify-center items-center rounded-3xl py-3 bg-[#39B8E4]">
            <p className="font-bold text-base leading-6">Continue</p>
          </button>
        </div>
      </CenterWrapper>
    </>
  );
}

export default AddressDetails;
