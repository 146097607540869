import React, { useState, useEffect } from 'react';
import { TbLoader3 } from 'react-icons/tb';
import UserService from '../services/user';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function AddAdModal({ onClose }) {
  const [email, setEmail] = useState('');
  const [check, setCheck] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [roleId, setRoleId] = useState('');
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_USER_MICROSERVICE}/roles`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      const authorizedDistributorRole = data.responseData.find(
        (role) => role.name === 'Authorized Distributor'
      );
      if (authorizedDistributorRole) {
        setRoleId(authorizedDistributorRole._id);
      }
      if (data.length > 0) {
        setRoleId(data[0]._id);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleEmailChange = (e) => {
    setCheck(false);
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateEmail = (value) => {
    const gmailPattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    setIsValid(gmailPattern.test(value));
  };

  const handleSubmit = async () => {
    if (isValid) {
      setCheck(false);

      try {
        setLoading(true);
        let payload = {
          email: email,
          // roleId: roleId,
          roleId: '664305dc491bf03e983f0ae1',
          invitedBy: user?._id,
        };
        const response = await new UserService().addUser(payload);
        setLoading(false);
        setShowSuccess(true);
        toast.success('Added distributor successfully', {
          autoClose: 2000,
        });
      } catch (error) {
        setLoading(false);
        console.error('Error submitting form:', error);
        if (
          error.responseData &&
          error.responseData.code === 11000 &&
          error.responseData.keyPattern &&
          error.responseData.keyPattern.email
        ) {
          toast.error('Email already exists', {
            autoClose: 2000,
          });
        } else {
          toast.error(`Error adding distributor: ${error.message}`, {
            autoClose: 2000,
          });
        }
      }
    }
  };

  const inputClass = [
    'w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] flex items-center',
    isValid !== null && (isValid ? 'border border-green-500 pr-10' : 'border border-red-500 pr-10'),
  ]
    .filter(Boolean)
    .join(' ');

  const icon = isValid !== null && (
    <img
      src={isValid ? './check_circle.svg' : './info-error.svg'}
      alt={isValid ? 'Valid' : 'Invalid'}
      className="absolute right-3 top-1/2 transform -translate-y-1/2"
    />
  );

  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[700px] h-90per max-h-[600px] rounded-[24px] shadow-lg p-7">
        <div className="flex justify-end mb-4">
          <img onClick={onClose} className="h-6 w-6 cursor-pointer" src="./close.svg" alt="Close" />
        </div>
        {showSuccess ? (
          <div className="flex flex-col w-[351px] h-[464px] font-jakarta mx-auto justify-center">
            <img src="./check_circle.svg" alt="Success" className="h-10 w-10 mb-3" />
            <h2 className="text-[#0F9E73] font-bold text-ft21 mb-2">User Successfully Added!</h2>
            <p className="text-[#1D2224] font-semibold text-ft20 mb-6">Waiting for Approval</p>
            <p className="text-[#3F4345] font-normal  text-start mb-6">
              {email} is added and pending for approval. Once Admin approve, the user will be
              active.
            </p>
            <button
              className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-full text-white py-2 px-4 rounded-xl"
              onClick={() => {
                window.location.reload();
                onClose();
              }}
            >
              Go to AD Management
            </button>
          </div>
        ) : (
          <div className="flex flex-col w-[351px] h-[464px] mx-auto justify-center">
            <div className="mb-4">
              <h2 className="text-[#1D2224] font-bold text-ft21">
                Add a new authorized distributor
              </h2>
            </div>
            <div className="mb-4 relative">
              <label className="block text-ft1_5 mb-1 font-normal text-[#1D2224]">
                Enter Gmail Account
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  className={inputClass}
                  placeholder="Enter gmail account of AD"
                />
                {icon}
              </div>
              {(isValid === false || check === true) && (
                <p className="text-red-500 text-sm mt-2">
                  {check ? 'Please input Gmail Account' : 'Provide Gmail account only'}
                </p>
              )}

              {isValid === true && (
                <p className="text-green-500 text-sm mt-2">Account is eligible.</p>
              )}
            </div>
            <div className="mt-10 flex gap-4 justify-center">
              <button className="text-[#1D2224] py-2 px-4 rounded" onClick={onClose}>
                Cancel
              </button>
              <button
                className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[171.5px] text-white py-2 px-4 rounded-xl"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <TbLoader3 className="animate-spin w-5 h-5 text-white" /> : 'Add'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddAdModal;
