import HTTPService from '../utility/httpService';
import {
  // httpConstants,
  apiEndPointConstants,
  // keyConstants
} from '../constants';
import Cookies from '../managers/sessionManager';
const {
  LOGIN,
  ROLES,
  USERS,
  USERS_EMAIL,
  PRODUCTS,
  ADMINS,
  DISTRIBUTORS,
  AUTHORIZED_DISTRIBUTORS,
  CHANGE_PASSWORD,
  AD,
  CREATE_ACCOUNT,
  CHECK_STRIPE_STATUS,
  GET_ONBOARD,
  TRANSFER_AMOUNT,
  INVITATION,
} = apiEndPointConstants;

export default class UserService {
  constructor(token) {
    this.httpService = new HTTPService(process.env.REACT_APP_USER_MICROSERVICE);
    this.config = {
      headers: {
        Authorization: `Bearer ${token !== undefined ? token : new Cookies().get('ACCESS_TOKEN')}`,
      },
    };
  }
  createAccount(payload) {
    return this.httpService.post(CREATE_ACCOUNT, payload, this.config);
  }
  checkStripeAccountStatus(accountId) {
    return this.httpService.get(`${CHECK_STRIPE_STATUS} ${accountId}`, this.config);
  }
  stripeGetOnboard(accountId) {
    return this.httpService.get(`${GET_ONBOARD} ${accountId}`, this.config);
  }
  transferAmount(payload) {
    return this.httpService.post(TRANSFER_AMOUNT, payload, this.config);
  }
  logIn(payload) {
    return this.httpService.post(LOGIN, payload, this.config);
  }

  getRolesForAdmin() {
    return this.httpService.get(ADMINS + ROLES, {}, this.config);
  }

  getRoles() {
    return this.httpService.get(ROLES, {}, this.config);
  }

  getUsers() {
    return this.httpService.get(ADMINS + USERS, {}, this.config);
  }

  getInvitedUsers(invitedBy) {
    return this.httpService.get(`${USERS}/${invitedBy}`, {}, this.config);
  }

  addUser(payload) {
    return this.httpService.post(USERS + INVITATION, payload, this.config);
  }

  getUserByEmail(email) {
    return this.httpService.get(`${USERS_EMAIL}/${email}`, {}, this.config);
  }

  updateUserByAdmin(userId, payload) {
    return this.httpService.put(`${ADMINS + USERS}/${userId}`, payload, this.config);
  }

  updateUser(userId, payload) {
    return this.httpService.put(`${USERS}/${userId}`, payload, this.config);
  }

  getProducts() {
    return this.httpService.get(PRODUCTS, {}, this.config);
  }

  addProduct(payload) {
    return this.httpService.post(PRODUCTS, payload, this.config);
  }

  updateProduct(productId, payload) {
    return this.httpService.put(`${PRODUCTS}/${productId}`, payload, this.config);
  }

  getMdPortalDistributorsData(userId, queryParams) {
    return this.httpService.get(
      `${AUTHORIZED_DISTRIBUTORS}/${userId}${queryParams}`,
      {},
      this.config
    );
  }

  getDistributorsData(queryParams) {
    return this.httpService.get(`${ADMINS + DISTRIBUTORS}${queryParams}`, {}, this.config);
  }

  updateDistirbutorStatus(distributorId, distributorType) {
    return this.httpService.put(
      `${ADMINS + USERS}/${distributorId}`,
      { status: distributorType },
      this.config
    );
  }

  updateAdminProfile(distributorId, formData) {
    const { name, email, password } = formData;
    return this.httpService.put(
      `${ADMINS + USERS}/${distributorId}`,
      { name, email, password },
      this.config
    );
  }

  updateDistirbutorProfile(distributorId, formData) {
    const { name, email } = formData;
    return this.httpService.put(`${USERS}/${distributorId}`, { name, email }, this.config);
  }

  changePassword(id, currentPassword, newPassword) {
    return this.httpService.put(
      `${ADMINS + CHANGE_PASSWORD}/${id}`,
      { currentPassword, newPassword },
      this.config
    );
  }

  updateFirstTimeProfile(payload) {
    return this.httpService.post('/add/user', payload, this.config);
  }
  updateProfile(payload) {
    return this.httpService.put('distributor', payload, this.config);
  }

  fileUpload(payload) {
    return this.httpService.post('/file-upload', payload, this.config);
  }

  getDistributorsDetails(userId) {
    return this.httpService.get(`/user/${userId}`, {}, this.config);
  }

  reviewDisitributorStatus(payload) {
    return this.httpService.post(`/review/user`, payload, this.config);
  }
}
