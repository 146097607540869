import React, { useState } from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { RejectModal } from './Modals';

const AddressDetails = ({ setSelectedTab, setTabValidation, tabValidation, formData }) => {
  const [showRejectModal, setShowRejectModal] = useState(false);

  return (
    <div className="flex flex-col gap-[32px]">
      <div className="flex flex-col">
        <span className="py-1 px-3 w-[56px] text-[16px] leading-6 bg-[#FDFDFD] text-[#6C6F71] rounded-[35px] flex justify-center items-center">
        2 / 5
        </span>
        <h3 className="text-[#2F3436] text-[25px] leadig-[35px] font-extrabold mt-[4px]">Application for {formData?.roleId?.name === "Master Distributor" ? "MD" : "AD"}</h3>
        <p className="text-[#1D2224] text-[20px] leading-7 font-semibold">Applicant's address details</p>
      </div>

      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">
            Postal / Zip code
          </span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.address?.zipCode}</p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">Country</span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.address?.country}</p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">Phone number</span>
          <p className="text-[#1D2224] text-[16px] font-semibold">
            +{formData?.phoneCountryCode + ' ' + formData?.phoneNumber}
          </p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">Email Id</span>
          <p className="text-[#1D2224] text-[16px] font-semibold">{formData?.email}</p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <span className="text-[#1D2224] text-[13px] font-normal uppercase">
            Proof of Address to be Provided by Applicant
          </span>
          <p className="text-[#1D2224] text-[16px] font-semibold">
            {formData?.proofOfAddress?.type}
          </p>
        </div>

        <div className="flex justify-between mt-5">
          <button
            className="p-3 bg-[#DDDEDE] rounded-full  text-[#1D2224] cursor-pointer"
            onClick={() => setSelectedTab(0)}
          >
            {' '}
            <MdOutlineKeyboardArrowLeft size={24} />
          </button>
          <button
            className="p-3 bg-[#DDDEDE] rounded-full text-[#1D2224] cursor-pointer"
            onClick={() => setSelectedTab(2)}
          >
            {' '}
            <MdOutlineKeyboardArrowRight size={24} />
          </button>
        </div>
        <div className="flex justify-between gap-2">
          <button
            className="bg-[#FBDEDF] text-[#E32028] rounded-[12px] px-3 py-2 w-[188px] h-[48px]"
            onClick={() => setShowRejectModal(true)}
          >
            Reject
          </button>
          <button
            className="bg-[#0EA8DE] text-[#FDFDFD] rounded-[12px] px-3 py-2 w-[188px] h-[48px]"
            onClick={() => {
              setTabValidation(2);
              setSelectedTab(2);
            }}
          >
            Next
          </button>
        </div>
        {showRejectModal && <RejectModal onClose={() => setShowRejectModal(false)} />}
      </div>
    </div>
  );
};

export default AddressDetails;
