import React, { useState, useEffect } from 'react';
import { TbLoader3 } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../slices/userSlice';
import Utils from '../utility';
import WalletService from '../services/wallet';
import UserService from '../services/user';

function DistributorDetails({ onClose, selectedDistributor, distributors, setDistributors }) {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [distributorType, setDistributorType] = useState(selectedDistributor?.status);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeADs, setActiveADs] = useState(0);
  const [activeSubscribers, setActiveSubscribers] = useState(0);
  const [commission, setCommission] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateDistirbutorLoading, setUpdateDistirbutorLoading] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const user = useSelector((state) => state.user);

  const selectDistributorType = (type) => {
    setDistributorType(type);
    setDropdownOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }

        const response =
          user?.roleId?.name === 'Admin'
            ? await new WalletService().getDistributorTransactionsList(selectedDistributor?._id)
            : await new WalletService().fetchDistributorTransactionsList(selectedDistributor?._id);

        setTransactions([...response]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistributor?._id]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }
        const response =
          user?.roleId?.name === 'Admin'
            ? await new WalletService().getDistributorSummary(selectedDistributor?._id)
            : await new WalletService().fetchDistributorSummary(selectedDistributor?._id);

        setActiveADs(response?.activeAuthorizedDistributorsCount);
        setActiveSubscribers(response?.activeSubscribersCount);
        setCommission(response?.commission);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistributor?._id]);

  const handleUpdateDistributor = async () => {
    try {
      setUpdateDistirbutorLoading(true);
      const response = await new UserService().updateDistirbutorStatus(
        selectedDistributor?._id,
        distributorType
      );
      setUpdateDistirbutorLoading(false);
      if (response) {
        const updatedDistributors = [...distributors];
        updatedDistributors.forEach((item, index) => {
          if (item._id === selectedDistributor?._id) {
            updatedDistributors[index] = { ...selectedDistributor, status: distributorType };
          }
        });
        setDistributors(updatedDistributors);
        setIsEditing(false);
        onClose();
      } else {
        const errorData = await response.json();
        console.error('Error updating distributor', errorData);
      }
    } catch (error) {
      setUpdateDistirbutorLoading(false);
      console.error('Error updating distributor', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[900px] h-95per max-h-[600px] rounded-[24px] shadow-lg p-7 relative overflow-y-auto hide-scrollbar">
        {loading ? (
          <div className="absolute inset-0 flex items-center justify-center rounded-[24px] bg-white bg-opacity-75 z-50">
            <TbLoader3 className="animate-spin h-10 w-10 text-black" />
          </div>
        ) : (
          <>
            <div className="flex justify-end mb-4">
              <img
                onClick={onClose}
                className="h-6 w-6 cursor-pointer"
                src="./close.svg"
                alt="Close"
              />
            </div>
            {!isEditing ? (
              <>
                <div className="flex items-center mb-4">
                  <span className="bg-[#C5EAF7] text-[#064C64] px-3 py-1 rounded-full">
                    {selectedDistributor?.role?.name}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-6">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-3">
                      <h1 className="text-[#1D2224] font-bold text-2xl">
                        {selectedDistributor?.name ? selectedDistributor?.name : 'N/A' }
                      </h1>
                      <span className={`px-3 py-1 rounded-full ${selectedDistributor?.status === "Active" ? 'text-[#0C7B59] bg-[#D1FBEE]' : 'text-[#6c6f71] bg-[#dddede]'}`}>
                        {selectedDistributor?.status === "information_submitted" ? "Information Submitted" : selectedDistributor?.status}
                      </span>
                    </div>
                    <p className="text-[#757575]">{selectedDistributor?.email}</p>
                    <p className="text-[#757575]">Approved on {selectedDistributor?.approvedOn}</p>
                  </div>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="flex items-center mt-4 gap-1 bg-[#F1F2F2] px-6 py-3 rounded-[24px] w-fit"
                  >
                    <img
                      src="/edit-profile.svg"
                      alt="User Profile"
                      className="w-[22px] h-[20px] object-cover cursor-pointer"
                    />
                    <p className="text-[#1d2224] text-ft13 font-semibold">Update Status</p>
                  </button>
                </div>
                <div className="flex gap-1 justify-start mb-6">
                  <div className="bg-[#F1F2F2] w-[245px] p-4 rounded-xl">
                    <p className="text-2xl font-bold">{activeADs}</p>
                    <p className="text-[#757575]">Active AD</p>
                  </div>
                  <div className="bg-[#F1F2F2] w-[245px] p-4 rounded-xl">
                    <p className="text-2xl font-bold">{activeSubscribers}</p>
                    <p className="text-[#757575]">Active Subscribers</p>
                  </div>
                  <div className="bg-[#F1F2F2] w-[245px] p-4 rounded-xl">
                    <p className="text-2xl font-bold">{commission}</p>
                    <p className="text-[#757575]">Total Commission</p>
                  </div>
                </div>
                <div className="w-full">
                  <h2 className="text-[#1D2224] font-semibold text-[31px] mb-1">Transactions</h2>
                  <div className="size-full overflow-auto" style={{ scrollbarWidth: 'thin' }}>
                    <table className="min-w-full bg-white">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-2 text-[#6C6F71] text-ft1_5 font-normal text-left"
                          >
                            Tx ID
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-2 text-[#6C6F71] text-ft1_5 font-normal text-left"
                          >
                            Date & Time
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-2 text-[#6C6F71] text-ft1_5 font-normal text-left"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-2 text-[#6C6F71] text-ft1_5 font-normal text-left"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-2 text-[#6C6F71] text-ft1_5 font-normal text-left"
                          >
                            Balance
                          </th>
                        </tr>
                      </thead>
                      <tbody className="font-jakarta font-normal overflow-y-auto">
                        {transactions.length === 0 ? (
                          <tr>
                            <td colSpan="4" className="py-2 mt-10 text-[#6C6F71] text-center">
                              No Data Found
                            </td>
                          </tr>
                        ) : (
                          transactions.map((transaction, index) => (
                            <tr key={index}>
                              <td className="whitespace-nowrap py-2">
                                {transaction?.transactionId === 'N/A'
                                  ? transaction?._id
                                  : transaction?.transactionId.length > 24
                                    ? `${transaction?.transactionId.slice(
                                        0,
                                        12
                                      )}...${transaction?.transactionId.slice(-12)}`
                                    : transaction?.transactionId}
                              </td>
                              <td className="whitespace-nowrap py-2">
                                {Utils.formatTimestamp(transaction?.addedOn)}
                              </td>
                              <td className="whitespace-nowrap py-2">
                                {transaction?.description.length > 20
                                  ? `${transaction?.description.slice(
                                      0,
                                      10
                                    )}...${transaction?.description.slice(-10)}`
                                  : transaction?.description}
                              </td>
                              <td className="whitespace-nowrap py-2">
                                <span
                                  className={`${
                                    transaction?.type === 'Credit'
                                      ? 'text-green-500'
                                      : 'text-red-500'
                                  }`}
                                >
                                  {transaction?.type === 'Credit'
                                    ? `+$${transaction?.amount}`
                                    : `-$${transaction?.amount}`}
                                </span>
                              </td>
                              <td className="whitespace-nowrap py-2">${transaction?.balance}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col w-[351px] h-[464px] font-jakarta mx-auto justify-center">
                <div className="flex items-center mb-4">
                  <span className="bg-[#C5EAF7] text-[#064C64] px-3 py-1 rounded-full">
                    {selectedDistributor?.role?.name}
                  </span>
                </div>
                <div className="flex flex-col gap-1 mb-6">
                  <div className="flex gap-3 items-center">
                    <h1 className="text-[#1D2224] font-bold text-[31px]">
                      {selectedDistributor?.name === '' ? 'N/A' : selectedDistributor?.name}
                    </h1>
                    <span className="bg-[#D1FBEE] text-[#0C7B59] h-[32px] px-3 py-1 rounded-full">
                      {selectedDistributor?.status}
                    </span>
                  </div>
                  <p className="text-[#757575]">{selectedDistributor?.email}</p>
                  <p className="text-[#757575]">Approved on {selectedDistributor?.approvedOn}</p>
                </div>
                <div>
                  <div className="text-[#1D2224]  font-semibold text-[20px]">
                    Edit distributor preference
                  </div>
                  <div className="flex flex-col pt-4 gap-4">
                    <div className="flex flex-col gap-2 mb-4 relative">
                      <label className="block text-ft1_5 mb-1 font-normal text-[#1D2224]">
                        Status
                      </label>
                      <div
                        className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] flex items-center cursor-pointer relative"
                        onClick={toggleDropdown}
                      >
                        {distributorType}
                        <img src="./drop-arrow.svg" alt="Dropdown Icon" className="ml-auto" />
                      </div>
                      {dropdownOpen && (
                        <div className="absolute w-full top-18.5 bg-white border border-gray-300 rounded-lg shadow-lg mt-2 z-10">
                          <div
                            className="px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer"
                            onClick={() => selectDistributorType('Active')}
                          >
                            Active
                          </div>
                          <div
                            className="px-4 py-2 hover:bg-[#F1F2F2] cursor-pointer"
                            onClick={() => selectDistributorType('Inactive')}
                          >
                            Inactive
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex w-full mt-4 gap-2">
                      <button
                        onClick={() => setIsEditing(false)}
                        className=" px-6 py-3 rounded-[24px] w-full"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleUpdateDistributor}
                        className="bg-[#0EA8DE] hover:bg-opacity-85 text-white px-6 py-3 rounded-[12px] w-full flex justify-center items-center"
                        disabled={updateDistirbutorLoading}
                      >
                        {updateDistirbutorLoading ? (
                          <TbLoader3 className="animate-spin w-5 h-5 text-white" />
                        ) : (
                          'Update'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DistributorDetails;
