import React from 'react';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';

const AddAdSuccessModal = ({ type, formData }) => {
  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[700px] h-90per max-h-[600px] rounded-[24px] shadow-lg p-7 flex justify-center items-center relative">
        <Link to="/dashboard">
          <MdClose className="absolute right-[20px] top-[20px] h-[24px] w-[24px] cursor-pointer" />
        </Link>

        <div className="flex flex-col gap-[8px] w-[351px]">
          <FaRegCircleCheck className="text-[#0F9E73] h-[40px] w-[40px]" />
          <h3 className="text-[#0F9E73] text-[20px] font-semibold mt-1">
            {type === 'addMD' ? 'MD' : 'AD'} Successfully Added!
          </h3>
          <h3 className="text-[#1D2224] text-[20px] font-semibold">Waiting for Approval</h3>

          <p className="text-[#3F4345] text-[16px] mt-2">
            {formData.email} is added and pending for approval. Once Admin approve, the user will be
            active.
          </p>
          <Link
            to="/dashboard"
            className="h-[48px] w-full mt-4 bg-[#0EA8DE] rounded-[12px] text-[#FDFDFD] font-semibold decoration-transparent flex justify-center items-center"
          >
            Go to {type === 'addMD' ? 'MD' : 'AD'} Management
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AddAdSuccessModal;
