import React from 'react';

const WithdrawalSuccessModal = ({ formData, handleCloseWithdrawalModal }) => {
  return (
    <div className="fixed left-0 top-0 h-full w-full bg-[#0000005f] flex justify-center items-center z-50">
      <div className="h-[350px] w-[400px] p-[24px] box-border bg-[#FDFDFD] rounded-[24px] flex flex-col justify-center items-center gap-[30px] relative">
        <img
          src="./close.svg"
          alt="Close-Icon"
          className="absolute top-[24px] right-[24px] h-[24px] w-[24px] cursor-pointer"
          onClick={handleCloseWithdrawalModal}
        />

        <div className="flex flex-col items-center gap-[4px] w-full mt-[50px]">
          <img src="./check_circle.svg" alt="Check-Icon" className="h-[40px] w-[40px]" />
          <h4 className="mt-[8px] text-[#1D2224] text-[20px] font-bold">
            Request Sent Successfully
          </h4>
          <p className="text-[#1D2224] text-[14px] font-normal">
            Your Request has been sent to Admin for Approval
          </p>
          <p className="text-[#3F4345] text-[16px] font-normal">
            ${Number(formData?.withdrawalAmount)?.toFixed(2)}
          </p>
        </div>

        <div className="flex flex-col gap-[8px] w-full">
          <button
            className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-full text-white rounded-xl"
            onClick={handleCloseWithdrawalModal}
          >
            Go to Dahboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalSuccessModal;
