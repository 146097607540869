import React from 'react';
import { MdClose } from 'react-icons/md';
import { TbLoader3 } from 'react-icons/tb';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className="bg-white p-10 rounded-lg relative lg:w-[600px]  m-5">
        <button onClick={onClose} className="absolute top-2 right-2 text-lg font-bold">
          <MdClose size={24} />
        </button>
        <div className="flex flex-col justify-center items-center text-center">{children}</div>
      </div>
    </div>
  );
};

export const CreateAccountModal = ({ isOpen, onClose, onCreate, isLoading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-4">Create Stripe Account</h2>
      <p>Create a Stripe account to withdraw money.</p>
      <button
        onClick={onCreate}
        className="mt-4 bg-[#1D2224] text-white py-2 px-4 rounded-lg flex items-center justify-center w-[160px]"
        disabled={isLoading}
      >
        {isLoading ? (
          <TbLoader3 className="animate-spin w-5 h-5 text-white" />
        ) : (
          <span>Create Account</span>
        )}
      </button>
    </Modal>
  );
};

export const OnboardModal = ({ isOpen, onClose, onOnboard, isLoading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-4">Complete Onboarding</h2>
      <p>Your Stripe account is not fully onboarded. Please complete the onboarding process.</p>
      <button
        onClick={onOnboard}
        className="mt-4 bg-[#1D2224] text-white py-2 px-4 rounded-lg flex items-center justify-center w-[210px]"
        disabled={isLoading}
      >
        {isLoading ? (
          <TbLoader3 className="animate-spin w-5 h-5 text-white" />
        ) : (
          <span>Complete Onboarding</span>
        )}
      </button>
    </Modal>
  );
};
