import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WalletService from '../service/walletService';
import { TbLoader3 } from 'react-icons/tb';

function AddMoney() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const user = useSelector((state) => state?.user);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const payload = {
        productName: 'Transfer',
        amount: parseFloat(amount),
        successUrl: 'https://myezmobile.info/dashboard',
        cancelUrl: 'https://myezmobile.info/add-money',
        userID: user?._id,
      };
      const response = await new WalletService().createCheckoutSession(payload);
      window.location.href = response;
      setIsLoading(false);
    } catch (error) {
      console.error('Error creating session:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white font-jakarta p-6 h-screen flex flex-col items-center">
      <div className="flex w-full justify-between items-center mb-8 mt-6">
        <img
          onClick={() => navigate('/dashboard')}
          src="/tittle-logo.svg"
          alt="EZMobile"
          className="h-10 cursor-pointer"
        />
      </div>
      <div className="flex mt-10per flex-col justify-center w-[480px]">
        <div className="flex items-center gap-4 mb-9 justify-start">
          <img
            onClick={handleGoBack}
            src="/arrow-back.svg"
            alt="back"
            className="h-4 w-4 cursor-pointer"
          />
          <p className="text-[#1D2224] text-[31px] font-extrabold">Add Money to Wallet</p>
        </div>
        <div className="mb-4">
          <label className="block uppercase text-ft1_5 font-normal text-[#1D2224]">Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full outline-none text-ft4 bg-[#F1F2F2] placeholder:text-[#999C9C] rounded-xl px-3 pt-[11px] pb-[13px] mt-1"
            placeholder="Enter amount in USD"
          />
        </div>
        <div className="mt-10 flex gap-4 justify-center">
          <Link
            to="/profile"
            className="text-[#1D2224] flex justify-center items-center w-[232px] py-2 px-4 rounded"
          >
            Cancel
          </Link>
          <button
            disabled={isLoading || amount.length === 0}
            style={{ opacity: amount.length === 0 ? 0.5 : 1 }}
            onClick={onSubmit}
            className="flex justify-center items-center bg-[#0EA8DE] h-[48px] w-[232px] text-white py-2 px-4 rounded-xl"
          >
            {isLoading ? <TbLoader3 className="animate-spin w-5 h-5 text-white" /> : 'Topup'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddMoney;
