import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../images/tittle-logo.svg';
import NotFoundBanner from '../images/not-found-banner.svg';

const NotFound = () => {
  return (
    <div className="min-h-screen w-full bg-[#F7F7F8] flex flex-col justify-center items-center gap-[8px] font-jakarta">
      <img src={Logo} alt="Logo" className="fixed top-[30px] left-[50px]" />

      <img src={NotFoundBanner} alt="Not-Found" className="w-full max-w-[654px]" />
      <h2 className="text-[#999C9C] text-[60px] font-extrabold leading-none">Not found</h2>
      <p className="text-[#6C6F71] text-center">The address you entered is invalid</p>
      <NavLink
        to="/"
        className="bg-[#0EA8DE] hover:bg-opacity-85 flex justify-center items-center h-[48px] w-[313px] mt-[24px] text-white py-2 px-4 rounded-[24px]"
      >
        Go to login
      </NavLink>
    </div>
  );
};

export default NotFound;
